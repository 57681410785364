import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import secureStorage from '../../../config/encrypt';
import InputField from '../../elements/InputField';
import MasterService from '../../../service/MasterService';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
class SuperAdminReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            resetMaxAttemptdata:{},
            pageloading: false,
            form: {
                days:2,
                dealer_id: undefined
            },
            columns: [],
            row_list: []
        };
    }
    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            this.afterLoginSetState(loginResponse)
        } else {
            this.setState({ redirect: true })
        }
    }
    componentDidMount = async () => {
        await this.updateReduxData();
        this.fetchLoginLogs()
    }
    afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
       
    }
    fetchLoginLogs = (event) => {
        if(event) event.preventDefault();

        this.setState({pageloading: true})
        MasterService.get('dealer/dealer/login-report', { ...this.state.form })
            .then(response => {
                this.setState({pageloading: false})
                let { data } = response;
           //     console.log("data", data)
                if (data && data.status == 200) {
                    this.setState({
                        columns: data.data.columns || [],
                        row_list: data.data.row_list || [],
                        form: data.data.form || {}
                    }, () => { });
                } else {
                    toast.error(data.message || "Invalid Request");
                }
            }).catch(error => {
                this.setState({pageloading: false})
                console.log(error);
                toast.error("Invalid Request");
            });
    }
    submitBlackListForm = async (event) => {
        // event.preventDefault();
        // let resetTempMaxAttemptdata =this.state.resetMaxAttemptdata;
        // var thisObj = this;
        // if (this.validFormData()) {
        //     const response = await MasterService.post('dealer/user-manager/reset_max_attempt', { ...this.state.resetMaxAttemptdata });
        //     if (response.data.status == 200) {
        //         resetTempMaxAttemptdata['user_name']='';
        //         toast.success(response.data.message);
        //         thisObj.setState({ resetTempMaxAttemptdata }, () => { });
        //     } else {
        //         toast.error(response.data.message);
        //     }
        // }
    }
    validFormData = () => {
        // let resetMaxAttemptdata = this.state.resetMaxAttemptdata;
        // let errors = {};
        // let formIsValid = true;
        // if (!resetMaxAttemptdata.user_name) {
        //     errors['user_name'] = "User_Name_Required";
        //     formIsValid = false;
        // }
        // this.setState({ errors: errors });
        // return formIsValid;
    }
    handleChangetext = (fieldName) => (event) => {
        this.setState({ form: {...this.state.form, [fieldName]: event.target.value} }, () => { 
        //    console.log("this.state.form", this.state.form)
        });
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/login'} />);
        }
        // const {resetMaxAttemptdata, errors } = this.state;
        return (
            <div className={(this.state.pageloading) ? "container-fluid loading" : "container-fluid"}>
                <h1>Report: User Login logs</h1>
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={this.fetchLoginLogs}>
                            <div className="row">
                                <div className="col-sm-6 col-md-2">
                                    <InputField
                                        inputProps={{
                                            id: "form_days",
                                            type: "number",
                                            placeholder: "Days",
                                            name: "form_days",
                                            autocompleate: "off",
                                            label: "Days",
                                            value: this.state.form.days
                                        }}
                                        onChange={this.handleChangetext("days")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-2">
                                    <InputField
                                        inputProps={{
                                            id: "form_dealer_id",
                                            type: "number",
                                            placeholder: "Partner ID",
                                            name: "form_dealer_id",
                                            autocompleate: "off",
                                            label: "Partner ID",
                                            value: this.state.form.dealer_id
                                        }}
                                        onChange={this.handleChangetext("dealer_id")}
                                    />
                                </div>
                                <div className="col-sm-6 col-md-4">
                                    <label></label>
                                    <div>
                                        <button type="submit" className="btn btn-primary mrg-r10">Search</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="clearfix">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        {this.state.columns && this.state.columns.length>0 && this.state.columns.map((col, k) =>
                                            <th key={k}>{col}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {(!this.state.row_list || this.state.row_list.length == 0) && <tr><td align="center" colSpan="7"><h6 className="text-center text-danger text-bold">No Record Found</h6></td></tr>}
                                    {this.state.row_list && this.state.row_list.length>0 && this.state.row_list.map((row, k2) =>
                                        <tr key={k2}>
                                            {row && row.length && row.map((row_val, k3) =>
                                                <td key={k3}>{row_val}</td>
                                            )}
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(SuperAdminReport));

