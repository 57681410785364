import React, { useState, useEffect, useMemo } from "react";
import FormFields from "../../../src/saathi-web/MyLeads/formFields/leadFormField.json";
import { executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import { UPDATE_LEAD_STATUS } from "../../queries/sangam/leads.gql";
import GeneralService from "../../service/generalService";
import { gql } from "@apollo/client";
import Formsy from "formsy-react";
import FormsyInputField from "../../view/elements/FormsyInputField";
import FormsySelect from "../../view/elements/FormsySelect";
import FormsyDatePicker from "../../view/elements/FormsyDatePicker";
import secureStorage from "../../config/encrypt";
import Q from "q";
import { Link, useParams } from "react-router-dom";

const ApprovedDetailsForm = FormFields["APPROVED"];

const ApprovalDetails = ({ leadDetail, masterdata, masterStatusData }) => {
  const params = useParams();
  const [approvedDetails, setApprovedDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [checked, setChecked] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [existingUploadedFiles, setExistingUploadedFiles] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const SANCTION_DOC_ID = 43;

  const user_information = secureStorage.getItem("loginUserInfo");
  let updatedStatusList =
    leadDetail?.lead_status_history?.map((data) => data.status_id) || [];

  useEffect(() => {
    if (leadDetail && leadDetail.lead_details) {
      if (leadDetail.sub_status_id == 3) {
        setChecked(true);
      }
      let approvedData = { ...leadDetail.lead_details };
      approvedData["approval_remark"] = "";
      approvedData["approval_date"] = approvedData["approval_date"]
        ? dateFormat(approvedData["approval_date"], "dd mmm, yyyy")
        : "";
      setApprovedDetails(approvedData);
      setExistingUploadedFiles(leadDetail.leaddocs || []);
    }
  }, [leadDetail]);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata } : {};
    return options;
  }, [masterdata]);

  const handleInputChange = (event) => {
    if (event.target) {
      const { id, value } = event.target;
      setApprovedDetails((currentValue) => ({
        ...currentValue,
        [id]: value,
      }));
    }
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setApprovedDetails((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setApprovedDetails((currentValue) => ({
        ...currentValue,
        [field_name]: date,
      }));
    }
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setApprovedDetails((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const is_sanction_verified = useMemo(() => {
    return [1, 2].includes(leadDetail.status_id) ? true : false;
  }, []);

  const uploadDocsAndSave = (lead_id) => {
    const awsRes = [],
      leadDocArr = [];

    if (uploadedFiles.length) {
      let formData = new FormData();
      formData.append("dealer_id", lead_id);
      formData.append("upload_type", "dealer_docs");
      for (let imgFile of uploadedFiles) {
        formData.append("images", imgFile);
      }
      awsRes.push(
        GeneralService.uploadFilesToS3(formData, {
          "Content-Type": "multipart/form-data",
        })
      );
    } else {
      awsRes.push(
        Promise.resolve({
          data: { status: 200, message: "Success", data: ["not_updated"] },
        })
      );
    }

    if (uploadedFiles.length) {
      Q.allSettled(awsRes).then((uploadOutput) => {
        uploadOutput.forEach((res, index) => {
          let response = res.value || [];
          if (res.state === "fulfilled") {
            if (response.data.data.length) {
              let resData = response.data.data;
              resData.forEach((row, i) => {
                const loanDetailsSaveDoc = {
                  lead_id,
                  doc_path: row.file_url,
                  parent_doc_id: 9,
                  doc_id: SANCTION_DOC_ID,
                  doc_source: "web",
                  user_id: user_information?.data?.dealer_id,
                  customer_type: "customer",
                  customer_id: leadDetail?.customer?.customer_id,
                };
                leadDocArr.push(loanDetailsSaveDoc);
              });
            }
          }
        });
        if (leadDocArr.length) {
          let variables = {
            saveDocInput: {
              lead_id,
              lead_docs: leadDocArr,
            },
            api_called_by: "saathi_web",
            partner_id: user_information?.data?.dealer_id,
          };

          let mutation = gql`
            mutation save_doc(
              $saveDocInput: SaveDocInput!
              $api_called_by: API_CALL_BY!
              $partner_id: Float!
            ) {
              save_doc(
                saveDocInput: $saveDocInput
                api_called_by: $api_called_by
                partner_id: $partner_id
              ) {
                lead_id
                doc_id
                doc_path
              }
            }
          `;

          executeGraphQLMutationFinex(mutation, variables)
            .then((result) => {
              if (result && result.data) {
                toast.success("Document uploaded successfully");
              } else {
              //  console.log(result);
                toast.error("Internal server error");
              }
            })
            .catch((error) => {
              toast.error(error.message);
            });
        } else {
          toast.error("Error in image upload!");
        }
      });
    }
  };

  const handleSubmit = () => {
    const lead_id = leadDetail?.id;
    const {
      approved_amount,
      approval_number,
      approval_remark,
      approved_emi,
      approval_date,
      approved_roi,
    } = approvedDetails;
    if (+approved_amount < +approved_emi)
      return toast.warn("EMI amount should be less than sanction amount");
    setIsSubmitDisabled(true);
    uploadDocsAndSave(lead_id);

    if ((approved_roi >= 0 && approved_roi <= 99) || approved_roi === null) {
      let reqBody = {
        approved_amount,
        approval_number,
        approval_remark,
        approved_emi,
        approved_roi,
        approval_date: dateFormat(approval_date, "yyyy-mm-dd"),
      };

      let mutation = UPDATE_LEAD_STATUS,
        variables = {
          api_called_by: "saathi_web",
          partner_id: user_information?.data.dealer_id,
          UpdateLeadStatus: {
            update_type: "status",
            save_type:
              [3].filter((status_id) => updatedStatusList.includes(status_id))
                .length >= 1
                ? "save"
                : "save",
            status_id: [3],
            // after_verified: is_sanction_verified ? 1 : 0,
            sub_status_id: checked ? 3 : 4,
            lead_id: lead_id,
            user_id: user_information?.data?.dealer_id,
            approval_form: reqBody,
          },
        };

      executeGraphQLMutationFinex(mutation, variables)
        .then((resp) => {
          let response = resp?.data?.UpdateLeadStatus || null;
          if (response && !response.success) {
            throw new Error(response.error || "Something went wrong");
          }
          toast.success("Success");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      toast.error("Sanction roi between 0 and 99.");
      setIsSubmitDisabled(false);
      return false;
    }
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles((current) => [...current, ...files]);
  };

  const handleOpenNewTab = (event) => {
    const { src } = event.target;
    if (src) {
      window.open(src, "_blank");
    } else {
      const pdfUrl = event.target.getAttribute("data-docurl");
      window.open(pdfUrl, "_blank");
    }
  };

  // @index - consider doc_id if existing OR index if newly added doc.
  const handleRemoveDocument = async (docType, index) => {
    try {
      const isConfirmed = window.confirm(
        "Confirm, do you want to remove this document"
      );
      if (isConfirmed) {
        if (docType === "new") {
          const uploadedDocsClone = [...uploadedFiles];
          uploadedDocsClone.splice(index, 1);
          setUploadedFiles(uploadedDocsClone);
        } else {
          const lead_id = leadDetail?.id;
          const mutation = gql`
            mutation update_doc($updateDocInput: UpdateDocInput!) {
              update_doc(updateDocInput: $updateDocInput) {
                id
                lead_id
                doc_id
              }
            }
          `;
          const variables = {
            updateDocInput: {
              id: index,
              lead_id,
              status: "0",
              parent_doc_id: 0,
              doc_id: 0,
              doc_source: "web",
              user_id: user_information?.data?.dealer_id,
              tab_name: "first_tab",
            },
          };

          await executeGraphQLMutationFinex(mutation, variables);
          const exUploaded = [...existingUploadedFiles];
          const updatedDocs =
            exUploaded.filter((doc) => doc?.id !== index) || [];

          setExistingUploadedFiles(updatedDocs);
          toast.success("Success");
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div>
        <div>
          <h5>Sanctioned Details</h5>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">
              {ApprovedDetailsForm.map((field) =>
                ["text", "pattern-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={approvedDetails && approvedDetails[field.name]}
                      placeholder=" "
                      label={field.label}
                      onChange={
                        field.type === "pattern-format"
                          ? (data) =>
                              handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={field.validations}
                      validationError={
                        approvedDetails[field.name] ? field.validationError : ""
                      }
                      readOnly={is_sanction_verified ? true : field.readOnly}
                      required={field.required}
                    />
                  </fieldset>
                ) : ["number-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={approvedDetails && approvedDetails[field.name]}
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={","}
                      decimalSeparator={"."}
                      label={field.label}
                      onChange={
                        field.type === "number-format"
                          ? (data) =>
                              handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={field.validations}
                      validationError={
                        approvedDetails[field.name] ? field.validationError : ""
                      }
                      readOnly={
                        is_sanction_verified
                          ? field.name == "approved_amount"
                            ? false
                            : true
                          : false
                      }
                      required={field.required}
                    />
                  </fieldset>
                ) : field.type === "date-picker" ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyDatePicker
                      name={field.name}
                      selectedDate={
                        approvedDetails &&
                        approvedDetails[field.name] &&
                        new Date(approvedDetails[field.name])
                      }
                      placeholder={field.label}
                      // minDate={new Date(approvedDetails?.login_date)}
                      maxDate={new Date()}
                      value={approvedDetails && approvedDetails[field.name]}
                      onDateSelect={(date) =>
                        handleDateChange(date, field.name)
                      }
                      dateFormat="dd MMM, yyyy"
                      validations={field.validations}
                      validationError={
                        approvedDetails[field.name] ? field.validationError : ""
                      }
                      disabled={field.readOnly}
                      required={field.required}
                      hasError={
                        approvedDetails.hasOwnProperty(field.name) &&
                        !approvedDetails[field.name]
                          ? true
                          : false
                      }
                    />
                  </fieldset>
                ) : field.type === "dropdown" ? (
                  <fieldset class="single-select col-md-6">
                    <FormsySelect
                      name={field.name}
                      id={field.id}
                      inputProps={{
                        options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                        placeholder: field.label,
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value:
                          ALL_OPTIONS &&
                          ALL_OPTIONS[field.optionsKey] &&
                          ALL_OPTIONS[field.optionsKey].filter(
                            ({ value }) => value == approvedDetails[field.name]
                          ),
                        isDisabled: is_sanction_verified
                          ? true
                          : field.readOnly,
                      }}
                      required={field.required}
                      value={approvedDetails && approvedDetails[field.name]}
                      onChange={(data) => handleSelectChange(data, field.name)}
                    />
                  </fieldset>
                ) : field.type === "textarea" ? (
                  <fieldset class="col-md-12">
                    <div class="material">
                      <label>{field.label}</label>
                      <textarea
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={approvedDetails && approvedDetails[field.name]}
                        placeholder=" "
                        onChange={handleInputChange}
                        class="form-input"
                      ></textarea>
                    </div>
                  </fieldset>
                ) : null
              )}
            </div>
            <br></br>
            <br></br>
            {/* <div style={{ display: "flex" }}>
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  name="disbursed_with_otc"
                  id="disbursed_with_otc"
                  value="Yes"
                  // disabled={IS_LOST}
                />
                <span className="slider"></span>
              </label>
              <label className="toggle-label">Sanction with OTC</label>
            </div> */}
            {/* <br></br> */}

            {/* <br></br> */}
            <div style={{ marginBottom: 20 }}>
              <h5>Approval Supporting Docs</h5>
              <ul>
                <li>
                  <div className="col-sm-2">
                    <div className="displayflex">
                      <div className="updatefileupload">
                        <input
                          type="file"
                          id={`fileInput_1`}
                          onChange={handleFileChange}
                          accept="image/*,.pdf"
                          multiple
                        />

                        <label htmlFor={`fileInput_1`} className="file-label">
                          <span>
                            <img
                              src={require(`../../../src/saathi-web/images/uploadimg.svg`)}
                              alt="not"
                              width={20}
                              height={20}
                            />
                          </span>
                          <div id="fileName" className="statusdoc">
                            Upload Doc
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <br></br>
              <div className="displayflex">
                {/* Already uploaded docs */}
                {existingUploadedFiles?.length > 0 &&
                  existingUploadedFiles.map((doc, index) => {
                    if (doc.doc_id === SANCTION_DOC_ID) {
                      return (
                        <div key={index} className="showimage">
                          {doc.ext === "pdf" || doc.ext === "PDF" ? (
                            <i
                              data-docurl={doc?.doc_path}
                              className="ic-pdf-file icons-normal"
                              onClick={handleOpenNewTab}
                              style={{ fontSize: "30px" }}
                            ></i>
                          ) : (
                            <img
                              src={doc.doc_path}
                              width={70}
                              height={40}
                              alt={`Document ${doc.doc_id}`}
                              onClick={handleOpenNewTab}
                            />
                          )}
                          <i
                            className="close-icon"
                            onClick={() =>
                              handleRemoveDocument("existing", doc.id)
                            }
                          >
                            &times;
                          </i>
                        </div>
                      );
                    }
                    return null;
                  })}

                {/* Newly added ones */}
                {uploadedFiles?.map((file, index) => {
                  const docUrl = URL.createObjectURL(file);
                  const fileType = file.type;
                  const isPDF = fileType === "application/pdf";
                  return (
                    <div key={`${index}_uploadedDoc`} className="showimage">
                      {isPDF ? (
                        <i
                          data-docurl={docUrl}
                          className="ic-pdf-file icons-normal"
                          onClick={handleOpenNewTab}
                          style={{ fontSize: "30px" }}
                        ></i>
                      ) : (
                        <img
                          src={docUrl}
                          width={70}
                          height={40}
                          onClick={handleOpenNewTab}
                        />
                      )}
                      <i
                        className="close-icon"
                        onClick={() => handleRemoveDocument("new", index)}
                      >
                        &times;
                      </i>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="updatestatusflex">
              {(leadDetail?.status_info?.statuslang?.status_name ===
                "Sanctioned" ||
                leadDetail?.status_info?.statuslang?.status_name ===
                  "Disbursed") && (
                <Link to={`/saathi-details-page/${params.lead_id}`}>
                  <button
                    type="button"
                    className="markloatbrn"
                    style={{ marginTop: "40px", marginRight: 10 }}
                  >
                    Go to Lead
                  </button>
                </Link>
              )}
              <button
                className="submitbtnnewleadstatus"
                disabled={isSubmitDisabled}
              >
                Submit
              </button>
            </div>
          </Formsy>
        </div>
      </div>
    </>
  );
};

export default ApprovalDetails;
