import React, { useState, useEffect } from 'react';
import InputField from '../../elements/InputField';
import MasterService from './../../../service/MasterService';
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { API_KEY_PARTNER, MOBILE_VALIDATION } from './../../../config/constant';
import RadioBox from './../../elements/Radiobox'
import OtpValidation from './otp-validate';
import DealerService from '../../../service/DealerService';
import BankDetails from './BankDetails'
import secureStorage from './../../../config/encrypt';
import CompanyDetails from './CompanyDetails';


function JoinUs({ t,props }) {
    const [formData, setFormData] = useState({
        name: "",
        first_name:"",
        // last_name: "",
        email: "",
        mobile:"",
        applicant_type_id: 1,
        profession_id :0,
        referral_code :"",
        pincode :"",
        user_type :0,
        birth_date:""
     
    });
    const [errors, setErrors] = useState({
        otp: '',
        captchaErr: '',
        captchaErrorMessage: '',
    });
    const [Professionlist,setProfessionList] = useState([])
    const [errordata,seterrordata] = useState("")
    const [fields, setFields] = useState({
        otp: '',
        captchaVal: '',
    });
    const [reloadCaptcha, setReloadCaptcha] = useState(false);
    const [showhide, setShowHide] = useState({
        activeform:true,
        otphide:false,
        thankyou:false,
        mainheading:false,
        bankinfo:false,
        applicant_type_id:1
    })

    

    useEffect(() => {
        getProfessionList();
       const dataheaders =secureStorage.getItem('loginUserInfo');
      // console.log(dataheaders.data.data.token)
       
    }, []);

    const setThankYouPage = (data)=>{
        setShowHide(data)
    }

    const setBackInfo = (data) =>{
        setShowHide(data)
    }

    const handleInput = (event) => {
        let fieldRequired = event.target.getAttribute('validationreq');
        let newFormData = { ...formData };
        let newErrors = { ...errors };

        if (fieldRequired === 'yes') {
            if (event.target.value === '') {
                newErrors[event.target.name] = `Please enter ${event.target.name}`;
            } else {
                delete newErrors[event.target.name];
            }
        }
        newFormData[event.target.name] = event.target.value;

        if (event.target.name === 'applicant_type_id') {
            newFormData['applicant_type_id'] = event.target.id; 
        }

        setFormData(newFormData);
        if (newFormData[event.target.name] || newFormData[event.target.name] !== '') {
            newErrors[event.target.name] = "";
          
        }
        if (event.target.name === 'pincode') {
            const pin = event.target.value;
            let validatePincode = /^[0-9]{6}$/;
            if (validatePincode.test(pin.toString())) {
                newErrors['pincode'] = "";
            } 
            else {
                newErrors['pincode'] = t('joinus.valid_pin');
            }
        }
      
        if(event.target.name==="mobile"){
            const mobile = event.target.value
            let validateMobNum= /^[6-9]\d{9}$/gi;
                if ((validateMobNum.test(mobile.toString())) && mobile.length==10) {
                    newErrors['mobile'] = "";
                }
                else {
                    newErrors['mobile'] = t('joinus.valid_mobile');
                }
        }

        // if (event.target.name === 'birth_date') {
        //     const selectedBirthDate = new Date(event.target.value);
        //     const eighteenYearsAgo = new Date();
        //     eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
        //     if (selectedBirthDate > eighteenYearsAgo) {
        //         newErrors['birth_date'] = "You must be at least 18 years old.";
        //     }
        // }


        setErrors(newErrors);
    }

    const handelCheckbox = (value) => {
        let newFormData = { ...formData };
        newFormData['applicant_type_id'] = value;
        setFormData(newFormData);
       }

    useEffect(() => {
        getProfessionList();
    }, []);
    
    const getProfessionList = async ()=> {
        await MasterService.getMasters(['professions_list'],
        { 'headers': { 'Authorization': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJtb2JpbGUiOiI5NTQwODA0NjQ2Iiwia2luZCI6IjYwMDAwIiwiaWF0IjoxNjk1NzM5ODExLCJleHAiOjE2OTgzMzE4MTF9.Q3Bz7klUL2FhljEpApf2accUrEROOsVP-7dGTtOKOUA" } })
        .then((response) => {
            if (response.status == 200 && response.data.status == 200) {
                setProfessionList(response.data.data.professions_list)
            } else if (response.status == 401 && response.data.status == 401) {
                setProfessionList({})
            }
        }).catch((error) => {
        });
        
    }
    

    const submitFormData = async (event) => {
        const formdataNew = {...formData};
        formdataNew.name = formData.first_name;
        const data = {}
        data.mobileCountryCode = "IN"
        data.mobile = formdataNew.mobile;
        data.api_called_by ="check_valid_mobile"
        event.preventDefault();
        if (validateForm()) {
            localStorage.clear("formData")
            localStorage.setItem("formData",JSON.stringify(formData));  

         await MasterService.post('account/user/send_otp', data)
            .then(function (response) {
                if(response.status == 200 && response.data.status == 200){
                    let responseData = response.data;
                     setShowHide({
                        activeform:false,
                        otphide:true,
                        thankyou:false,
                        mainheading:false,
                        bankinfo:false
                     })
                  
                    // setActiveform(0)
                }else{
                    window.commonErrorToast(response.data.message);
                }
            }).catch(function (error) {
                window.commonErrorToast(error.message);
            });

        }
    }

    const validateForm = () => {
        let validForm = true;
        let newErrors = { ...errors };
        let newFormData = { ...formData };
        let mobileRule = MOBILE_VALIDATION;
        let validatePincode = /^[0-9]{6}$/;
        const dealership_email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const eighteenYearsAgo = new Date();
        eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
        

        if (!newFormData['first_name'] || newFormData['first_name'] === '') {
            newErrors['first_name'] = t('joinus.enter_name');
            validForm = false;
        }
        // if (!newFormData['last_name'] || newFormData['last_name'] === '') {
        //     newErrors['last_name'] = t('joinus.last_name');
        //     validForm = false;
        // }

        if (!newFormData['mobile'] || newFormData['mobile'] === '') {
            newErrors['mobile'] = t('joinus.enter_mobile');
            validForm = false;
        } else if (!mobileRule.test(newFormData['mobile'])) {
            newErrors['mobile'] = t('joinus.valid_mobile');
            validForm = false;
        }
        
        if (!newFormData['email'] || newFormData['email'] === '') {
            newErrors['email'] = t('Please enter email');
            validForm = (false);
        } else if (!dealership_email_regex.test(newFormData['email'])) {
            newErrors['email'] = t('joinus.valid_email');
            validForm = false;
        }

        if (!newFormData['birth_date'] || newFormData['birth_date'] === '') {
            newErrors['birth_date'] = t('joinus.doberror');
            validForm = false;
        }else {
            const birthDate = new Date(newFormData['birth_date']);
            if (birthDate > eighteenYearsAgo) {
                newErrors['birth_date'] = t('You must be at least 18 years old.');
                validForm = false;
            }
        }
        if ((!newFormData['company_name'] || newFormData['company_name'] === '') && formData.applicant_type_id==2) {
            newErrors['company_name'] = t('joinus.companyName');
            validForm = false;
        }
        // if (!newFormData['dealership_profession'] || newFormData['dealership_profession'] === '') {
        //     newErrors['dealership_profession'] = t('joinus.profession_error');
        //     validForm = false;
        // }
        if (!newFormData['pincode'] || newFormData['pincode'] === '') {
            newErrors['pincode'] = t('joinus.pinerror')
            validForm = false;
           }else if (!validatePincode.test(newFormData['pincode'])){
                newErrors['pincode'] = t('Please Enter Valid Pin Code')
                validForm = false;
            }
           
        
        // if (!newFormData['referral_code'] || newFormData['referral_code'] === ''){
        //     newErrors['referral_code'] = t('joinus.referral_code_error');
        //     validForm = false;
        // }

        setErrors(newErrors);
        return validForm;
    }

    const handleChangeProfession = (sname, oValue) => {
        const newFormData = {...formData}
        newFormData[sname] = (oValue.id) != null ? oValue.id : 0;
        setFormData(
            newFormData 
        );
    }

    const validateOtp = async (event)=>{
        var parentObj = this;
        let error = {...errors};
        
        event.preventDefault(); 
        if(validOtpForm()){
            let postData = Object.assign(fields);
            postData.mobile = formData.mobile;
            postData.api_called_by = "check_valid_mobile";
          await MasterService.post('account/user/validate_otp', postData)
            .then(function (response) {
                if(response.status == 200 && response.data.status == 200){
                    let responseData = response.data;
                   // console.log(responseData)
                    submitForm()
                    // setShowHide({
                    //     otphide:false,
                    //     thankyou:false,
                    //     mainheading:false,
                    //     bankinfo:true
                    //  })
                    //  console.log("showHide:", showhide.bankinfo);
                }else{
                    seterrordata(response.data.message)
                    window.commonErrorToast(response.data.message);
                    if(response.data.message === 'Invalid Captcha' || response.data.message === 'Wrong Captcha'){
                        error.captchaErr = response.data.message;
                        error.captchaErrorMessage = response.data.message;
                    }else{
                        error.otp = response.data.message;
                    }
                    //parentObj.setState({errors: errors, reloadCaptcha: !(parentObj.state.reloadCaptcha), fields: {...parentObj.state.fields, captchaVal : ''} })
                    setErrors(errors)
                    setReloadCaptcha(parentObj.reloadCaptcha)
                    setFields(...parentObj.fields)  
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    }

    const submitForm = async () => {
        const formDataNew = {...formData,'source':"partner Backend"}
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': API_KEY_PARTNER,
            'Accept-Language': 'en'
        }
        formDataNew.is_mobile_verified = 1;
        formDataNew.user_type = 1;
        formDataNew.name = formData.first_name;
           await MasterService.post('account/user/joinus',formDataNew , headers)
            .then((response) => {
                if (response.status === 200 && response.data.status === 200) {
                    secureStorage.setItem('loginUserInfo', response.data);
                    setFormData({});
                    setErrors({});
                    const showhidedata = {...showhide}
                    showhidedata.otphide = false;
                    showhidedata.thankyou = false;
                    showhidedata.mainheading = false;
                    showhidedata.bankinfo = true;
                    showhidedata.applicant_type_id =formData.applicant_type_id;
                    setShowHide(showhidedata)
                  //   props.updateStateIntoRedux('LOGIN_USER', response.data);
                 //   console.log("secureStorage.getItem",secureStorage.getItem('loginUserInfo'));
                   //toast.success(response.data.message);
                } else {
                    setErrors(errors)
                    seterrordata(response.data.message)
                    toast.error(response.data.message);
                }
            });
        
    }

    const validOtpForm = () => {
        let field = {...fields};
        let errors = {};
        let formIsValid = true;
        if (!field.otp) {
            errors.otp = t('joinus.enter_otp');
            formIsValid = false;
        }
        if (errors.captchaErr || (!field.captchaVal)) {
            if (!field.captchaVal) {
                errors.captchaErr = 'Captcha is required';
                errors.captchaErrorMessage = 'Captcha is required';
            } else if (errors.captchaErr) {
                errors.captchaErr = 'Please enter valid captcha';
                errors.captchaErrorMessage = 'Please enter valid captcha';
                // this.setState({ reloadCaptcha: !(reloadCaptcha) });
                setReloadCaptcha(!(reloadCaptcha))
            }
            formIsValid = false;
        }
        setErrors(errors)
        return formIsValid;
    }

    
    const handleChangeEvent = (event) => {
        let updatedFields = { ...fields };
        const updatedErrors = { ...errors };
        if (event && event.captchaData && event.captchaData.status === 200) {
            updatedFields["captchaVal"] = event.captchaVal;
            updatedFields['captchaId'] = event.captchaData.data.value;
            if (event.captchaVal === "") {
                updatedErrors['captchaErr'] = 'Please enter valid captcha';
            }
        } else {
          //  alert(event.target.name + " " +event.target.value)
            updatedFields[event.target.name] = event.target.value;
            if (event.target.value !== '') {
                delete updatedErrors[event.target.name];
            } else {
                updatedErrors[event.target.name] = 'Please enter ' + event.target.name;
            }
        }
        setFields(updatedFields);
        setErrors(updatedErrors);
    };

    const calculateMaxDate = () =>{
        const today = new Date();
        const maxDate = new Date(today.getFullYear() - 18 , today.getMonth(),today.getDate());
        return maxDate.toISOString().split('T')[0];
    }


    return (

        <div className="item active">
            <form name="rightfrm" id="rightfrm" method="post" action="">
                <div className="row os-animation animated fadeInUp">
                   <div className="col-md-12" id="modified_design" >
                         {showhide.mainheading ? <div>
                            <h1 className="login-h1">{t('joinus.join_us')}</h1>
                            <p className="login-p">{t('joinus.join_title')}</p>
                            </div>:""}
                       {showhide.activeform ? <div className="signUp-container text-left">
                            <div style={{fontSize:16,marginBottom:10}}>Select Organigation Type</div>
                            
                            <div style={{display:"flex"}}>
                            <div style={{marginRight:20}}>
                        
                            <RadioBox 
                                type="radio" 
                                name="applicant_type_id" 
                                id="applicant_type_id" 
                                value="1" 
                                label= { t('joinus.Individual') }
                                checked={(formData.applicant_type_id == 1 || (!formData.applicant_type_id)) ? true : false}  
                                onChange={()=>handelCheckbox(1)}
                            />
                            
                        </div>
                        <div style={{marginRight:20}}>
                        
                       
                        <RadioBox 
                            type="radio" 
                            name="applicant_type_id" 
                            id="applicant_type_id" 
                            value="2" 
                            label= { t('joinus.Company') }
                            checked={(formData.applicant_type_id == 2 || (!formData.applicant_type_id)) ? true : false} 
                             onChange={()=>handelCheckbox(2)}
                        />
                    </div>

                            </div>
                            <div className="row">
                                {/* <div className="col-md-4 col-R-padding">
                                    <div className="form-group">
                                        <label>{t('joinus.purpose')}</label>
                                        <Select
                                            id="purpose"
                                            value={formData.purpose}
                                            onChange={(fieldObj) => handleChangeSelect('purpose', fieldObj)}
                                            options={purpose}
                                            name="purpose"
                                            placeholder={t('joinus.purpose')}
                                            value={purpose.filter(({ key }) => key === formData.purpose)}
                                            getOptionLabel={({ value }) => value}
                                            getOptionValue={({ key }) => key}
                                        />
                                    </div>
                                </div> */}
                                {formData.applicant_type_id=="2"?<div className="col-md-6" >
                                    <InputField
                                        inputProps={{
                                            id: "company_name",
                                            type: "text",
                                            name: "company_name",
                                            label: t('joinus.company_name'),
                                            value: formData.company_name || '',
                                            placeholder:"Please Enter company_name",
                                            dataerror: errors.company_name || '',
                                            validationreq: "true",
                                            label_before_input: "true"
                                        }}
                                        onChange={handleInput}
                                    />
                                </div>:""}
                                <div className="col-md-6">
                                    <InputField
                                        inputProps={{
                                            id: "first_name",
                                            type: "text",
                                            name: "first_name",
                                            label: t('Name'),
                                            value: formData.first_name || '',
                                            placeholder:"Please Enter Name",
                                            dataerror: errors.first_name || '',
                                            validationreq: "true",
                                            label_before_input: "true"
                                        }}
                                        onChange={handleInput}
                                    />
                                </div> 
                                {/* <div className="col-md-4">
                                    <InputField
                                        inputProps={{
                                            id: "last_name",
                                            type: "text",
                                            name: "last_name",
                                            label: t('Last Name'),
                                            value: formData.last_name || '',
                                            placeholder:"Please Enter last Name",
                                            dataerror: errors.last_name || '',
                                            label_before_input: "true"
                                        }}
                                        onChange={handleInput}
                                    />
                                </div>                                */}
                                {/* <div className="col-md-4">
                                    <InputField
                                        inputProps={{
                                            id: "dealership_name",
                                   type: "text",
                                            name: "dealership_name",
                                            label: t('joinus.dealership_name'),
                                            placeholder:"Please Enter Dealer Name",
                                            value: formData.dealership_name || '',
                                            dataerror: errors.dealership_name || ''
                                        }}
                                        onChange={handleInput}
                                    />
                                </div> */}
                                <div className="col-md-6">
                                    <InputField
                                        inputProps={{
                                            id: "mobile",
                                            type: "tel",
                                            name: "mobile",
                                            label: t('joinus.contact_no'),
                                            value: formData.mobile || '',
                                            maxLength: 10,
                                            placeholder:"Please Enter Mobile No",
                                            dataerror: errors.mobile || '',
                                            validationreq: "true",
                                            label_before_input: "true"
                                        }}
                                        onChange={handleInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputField
                                        inputProps={{
                                            id: "email",
                                            type: "email",
                                            name: "email",
                                            label: t('joinus.email'),
                                            placeholder:"Please Enter Email",
                                            value: formData.email || '',
                                            dataerror: errors.email || ''
                                        }}
                                        onChange={handleInput}
                                    />
                                </div>
                                {/* <div className="col-md-4">
                                    <InputField
                                        inputProps={{
                                            id: "pan_no",
                                            type: "pan_no",
                                            name: "pan_no",
                                            label: t("joinus.pan_no"),
                                            placeholder:"Please Enter Pan Number",
                                            value: formData.pan_no || '',
                                            dataerror: errors.pan_no || ''
                                        }}
                                        onChange={handleInput}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        inputProps={{
                                            id: "address",
                                            type: "address",
                                            name: "address",
                                             label: t('joinus.address'),
                                            placeholder:"Please Enter Address",
                                            value: formData.address || '',
                                            dataerror: errors.address || ''
                                        }}
                                        onChange={handleInput}
                                    />
                                </div> */}
                                <div className="col-md-6">
                                    <InputField
                                        inputProps={{
                                            id: "birth_date",
                                            type: "date",
                                            name: "birth_date",
                                            label: t('joinus.dob'),
                                            placeholder:"Please Enter Date Of Birth",
                                            max:calculateMaxDate(),
                                            value: formData.birth_date || '',
                                            dataerror: errors.birth_date || ''
                                        }}
                                        onChange={handleInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputField
                                        inputProps={{
                                            id: "pincode",
                                            type: "tel",
                                            name: "pincode",
                                            maxLength: 6,
                                            label: t('joinus.pin_code'),
                                            placeholder:"Please Enter Pin Code",
                                            value: formData.pincode || '',
                                            dataerror: errors.pincode || '',
                                            validationreq: "true",
                                            label_before_input: "true"
                                        }}
                                        onChange={handleInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>{t('joinus.profession')}</label>
                                    <div className="form-group">
                                <Select
                                id="profession_id"
                                options={Professionlist}
                                name="profession_id"
                                onChange={(e)=>handleChangeProfession('profession_id',e)}
                                placeholder={t('select')}
                                value={Professionlist && Professionlist.filter(({ id }) => id == formData.profession_id  )}
                                getOptionLabel={({ profession_name }) => profession_name}
                                getOptionValue={({ id }) => id}
                                validationreq= "true"
                                label_before_input= "true"
                                    />
                                       
                                    </div>
                                </div>
                                {/* <div className="col-md-4 col-L-padding">
                                    <div className="form-group">
                                        <label>{t('joinus.city')}</label>
                                        <Select
                                            id="city"
                                            value={formData.city}
                                            onChange={(fieldObj) => handleChangeSelect('city', fieldObj)}
                                            options={cityList}
                                            name="city"
                                            placeholder={t('joinus.city')}
                                            value={cityList && cityList.filter(({ name }) => name === formData.city)}
                                            getOptionLabel={({ name }) => name}
                                            getOptionValue={({ id }) => id}
                                        />
                                    </div>
                                </div> */}
                                {/* <div className="col-xs-12">
                                    <div className="form-group">
                                        <label>{t('joinus.comment')}</label>
                                        <textarea
                                            onChange={handleInput}
                                            rows="3"
                                            className="form-control input-lg sign-txtbox"
                                            id="comment_feedback"
                                            placeholder={t('joinus.comment_placeholder')}
                                            name="comment_feedback"
                                            value={formData.comment_feedback || ''}
                                        ></textarea>
                                    </div>
                                </div> */}
                               
                            </div>
                           
                                {/* <div style={{fontSize:16,marginBottom:10}}>Referral Code</div> */}
                                <div className='row'>
                                
                                <div className="col-md-6">
                                    <InputField
                                        inputProps={{
                                            id: "referral_code",
                                            type: "text",
                                            name: "referral_code",
                                            label: t('joinus.referral_code'),
                                            placeholder:'Please Enter Referral',
                                            value: formData.referral_code || '',
                                            dataerror: errors.referral_code || ''
                                        }}
                                        onChange={handleInput}
                                    />
                                </div>
                                
                                {/* <div className="col-md-4">
                                    <InputField
                                        inputProps={{
                                            id: "gst",
                                            type: "gst",
                                            name: "gst",
                                            label: t('joinus.gstin'),
                                            placeholder:'Enter GSTIN',
                                            value: formData.gst || '',
                                            dataerror: errors.gst || ''
                                        }}
                                        onChange={handleInput}
                                    />
                                </div> */}

                              
                                <div className="col-xs-12" style={{display:"flex",justifyContent:"center"}}>
                                    <div className="form-group">
                                        <input style={{width:"400px",marginTop:20}} type="button" value={t('joinus.submit')} className="btn btn-primary btn-lg btn-block" onClick={(e)=>submitFormData(e)} />
                                    </div>
                                </div>
                                </div>
                            {/* <div className="row">
                                <div className="col-xs-4">
                                    <div className="joinUs-heading">
                                        <div className="joinUsIcon">
                                            <img src={require('./../assets/images/icon-1.svg')} alt="" />
                                        </div>
                                        No. 1
                                    </div>
                                    <p className="login-p smtxt joinUs-heading heading-section">{t('joinus.car_site')}</p>
                                </div>
                                <div className="col-xs-4 pad-L0">
                                    <div className="joinUs-heading">
                                        <div className="joinUsIcon">
                                            <img src={require('./../assets/images/icon-2.svg')} alt="" />
                                        </div>{t('joinus.car_sold')}

                                    </div>
                                    <p className="login-p smtxt joinUs-heading heading-section">{t('joinus.every_minute')}</p>
                                </div>
                                <div className="col-md-4 pad-L0">
                                    <div className="joinUs-heading">
                                        <div className="joinUsIcon">
                                            <img src={require('./../assets/images/icon-3.svg')} alt="" />
                                        </div>
                                        {t('joinus.manage_lead')}
                                    </div>
                                    <p className="login-p mrg-L50 smtxt joinUs-heading heading-section"> {t('joinus.advance_lms')}</p>
                                </div>
                            </div> */}
                        </div>:""} 
                       
                        {showhide.otphide?
                        <OtpValidation 
                        mainclass={'joinus'} 
                        backbtn={'backbtn'}
                        errors={errors} 
                        setBackInfo={setBackInfo}
                        onClick={validateOtp} 
                        onChange={handleChangeEvent}
                        reloadCaptcha={reloadCaptcha}
                        validationerror={errordata}
                        
                        />:""}

                     {showhide.thankyou ? 
                     <div style={{padding:50,background:"white",alignItems:"center",display:"flex",flexDirection:"column",borderRadius:20}}>
                     <img src={require('./../assets/images/thumbs.png')} alt='thumbs'  width={100} height={100}/>
                     <h6 style={{marginTop:20}}>Thank you, Our team will contact you shortly.</h6> 
                      </div>:""}

                      {showhide.bankinfo && showhide.applicant_type_id == "1" && (
                        <BankDetails setThankYouPage={setThankYouPage}></BankDetails>
                        )}

                        {showhide.bankinfo && showhide.applicant_type_id == "2" && (
                        <CompanyDetails setThankYouPage={setThankYouPage}></CompanyDetails>
                        )}

                    </div>
                                
                    </div>
            </form>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user_loggedin: state.userLoggedIn
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    };
};

export default withTranslation('common')(JoinUs);
