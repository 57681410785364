import React from 'react'
import InvoiceCard from './NotesComponent/InvoiceCard'
import NotesCard from './NotesComponent/NotesCard'

const MyEarningNotesDetail = ({bankList,notesData,togglePopup}) => {

  const getNotesType = (type)=>{
    if(typeof(type)=="string" && type.toLowerCase()==="credit"){
      return "Credit"
    }else if(typeof(type)=="string" && type.toLowerCase()==="debit"){
      return "Debit"
    }
    return ""
  }

  return (
    <div>
        <InvoiceCard notesData={notesData}/>
        { 
          notesData?.notes && notesData.notes.map(item=>{
            return <NotesCard type={getNotesType(item.invoice_type)} togglePopup={togglePopup} data={item} bankList={bankList}/>
          }) 
        }

    </div>
  )
}

export default MyEarningNotesDetail