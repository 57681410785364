import React, { useEffect, useState, useMemo } from "react";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import FormFields from "../../../src/saathi-web/MyLeads/formFields/customerDetailsFormFields.json";
import Formsy from "formsy-react";
import dateFormat from "dateformat";
import FormsyInputField from "../../view/elements/FormsyInputField";
import FormsyDatePicker from "../../view/elements/FormsyDatePicker";
import FormsySelect from "../../view/elements/FormsySelect";
import GeneralService from "../../service/generalService";
import CoApplicantDetails from "./CoApplicantDetails";
import { toast } from "react-toastify";
import {
  GET_DETAILS_BY_PANCARD,
  SAVE_CUST_DETAILS,
} from "../../queries/sangam/customer.gql";
import {
  executeGraphQLMutationFinex,
  executeGraphQLQueryFinex,
} from "../../common/executeGraphQLQuery";
import { useHistory, useParams } from "react-router-dom";

const MaskData = require("maskdata");
const FORM_FIELDS = FormFields["BASIC_DETAILS"];
const MAX_CO_APPLICANT = 4;

const maskOptions = {
  maskWith: "*",
  unmaskedStartDigits: 0,
  unmaskedEndDigits: 4,
  maskSpace: false,
};

const DOB_MAX_DATE = new Date().setFullYear(new Date().getFullYear() - 18);
const DOB_MIN_DATE = new Date().setFullYear(new Date().getFullYear() - 76);
const GENDER_OPTIONS = [
  { id: "1", label: "Male", value: "male" },
  { id: "2", label: "Female", value: "female" },
];

const CustomerDetails = ({
  leadDetail,
  masterdata,
  masterStatusData,
  currentStep,
  handleStepClick,
}) => {
  const params = useParams();
  const history = useHistory();
  const [basicDetails, setBasicDetails] = useState({});
  const [coApplicantDetails, setCoApplicantDetails] = useState([]);
  const [activeTab, setActiveTab] = useState("primary");
  const [isFetchAadharVisible, setIsFetchAadharVisible] = useState(false);
  const [isFormTouch, setTouch] = useState({});
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));

  let name = loginUserInfo?.name ? loginUserInfo.name : "";
  let user_id =
    loginUserInfo && loginUserInfo.user_data
      ? loginUserInfo.user_data.user_id
      : 1;

  const setStateAndCityList = async () => {
    try {
      const response = await GeneralService.stateCityList();
      if (response?.data?.status === 200) {
        const result = { ...response.data.data };
        if (result.city && result.city.length) {
          result.city = result.city.map((data) => ({
            ...data,
            value: data.id,
            label: data.name,
          }));
        }

        if (result.state && result.state.length) {
          result.state = result.state.map((data) => ({
            ...data,
            value: data.id,
            label: data.name,
          }));
        }

        setCityList(result.city);
        setStateList(result.state);
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Failed to fecth state and city list");
    }
  };

  useEffect(() => {
    if (leadDetail) {
      const exCoApplicant = leadDetail?.co_applicant || [];
      const customerData = { ...leadDetail.customer };
      const mappedCoApplicant = exCoApplicant.map((data) => {
        let newAppObject = {};
        newAppObject.id = data.id || 0;
        newAppObject.ca_annual_income = data.ca_annual_income || "";
        if (data.ca_profession)
          newAppObject.ca_profession = data.ca_profession || "";
        if (data.ca_salary_credit_mode)
          newAppObject.ca_salary_credit_mode = data.ca_salary_credit_mode || "";
        newAppObject.ca_company_name = data.ca_company_name || "";
        newAppObject.ca_company_type = data.ca_company_type || "";
        newAppObject.ca_loan_amount = data.ca_loan_amount || "";
        newAppObject.ca_tenure = +data.ca_tenure || 0;

        newAppObject.ca_first_name = data.ca_first_name || "";
        newAppObject.ca_last_name = data.ca_last_name || "";
        newAppObject.ca_gender = data.ca_gender || "";
        newAppObject.relationship_with_customer =
          data.relationship_with_customer || "";
        newAppObject.ca_mobile = data.ca_mobile || "";
        newAppObject.masked_ca_mobile = MaskData.maskCard(
          data.ca_mobile,
          maskOptions
        );
        newAppObject.ca_email = data.ca_email || "";
        newAppObject.ca_marital_status = data.ca_marital_status || "";
        newAppObject.ca_dob = data.ca_dob || "";
        newAppObject.ca_aadhar_no = data.ca_aadhar_no || "";
        let aadhar_no =
          data && data.ca_aadhar_no
            ? data?.ca_aadhar_no?.match(/.{1,4}/g).join(" ")
            : "";
        newAppObject.masked_ca_aadhar_no = MaskData.maskCard(
          aadhar_no,
          maskOptions
        );
        newAppObject.aadhar_touch = 1;
        newAppObject.ca_pancard_no = data.ca_pancard_no || "";
        newAppObject.ca_annual_income = data.ca_annual_income || "";
        newAppObject.ca_mother_name = data.ca_mother_name || "";
        newAppObject.ca_qualification = data.ca_qualification || "";

        newAppObject.ca_cra_address1 = data.ca_cra_address1 || "";
        newAppObject.ca_cra_address2 = data.ca_cra_address2 || "";
        newAppObject.ca_cra_state = data.ca_cra_state || "";
        newAppObject.ca_cra_city = data.ca_cra_city || "";
        newAppObject.ca_cra_pincode = data.ca_cra_pincode || "";
        newAppObject.ca_type = data.ca_type || "";
        newAppObject.same_as_cus_addr = data.same_as_cus_addr || "0";
        // newAppObject.same_as_cus_addr = data.same_as_cus_addr || '0';

        newAppObject.isFetchAadharVisible = false;

        if (
          newAppObject.ca_pancard_no !== null &&
          newAppObject.ca_pancard_no !== "" &&
          (newAppObject.ca_aadhar_no === null ||
            newAppObject.ca_aadhar_no === "")
        ) {
          newAppObject.isFetchAadharVisible = true;
        }

        return newAppObject;
      });

      customerData["dob"] = dateFormat(customerData["dob"], "dd mmm, yyyy");
      let aadhar_no =
        customerData && customerData.aadhar_no
          ? customerData?.aadhar_no?.match(/.{1,4}/g).join(" ")
          : "";
      customerData.masked_aadhar_no = MaskData.maskCard(aadhar_no, maskOptions);
      customerData.masked_mobile = MaskData.maskCard(
        customerData.mobile,
        maskOptions
      );
      customerData.aadhar_touch = 1;
      if (
        customerData.pancard_no !== null &&
        customerData.pancard_no !== "" &&
        (customerData.aadhar_no === null || customerData.aadhar_no === "")
      ) {
        setIsFetchAadharVisible(true);
      }

      setBasicDetails(customerData);
      setCoApplicantDetails(mappedCoApplicant);
      setStateAndCityList();
    }
  }, [leadDetail]);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata } : {};
    options["qualification"] = options["qualification"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    options["marital_status"] = options["marital_status"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    options["gender"] = GENDER_OPTIONS;
    options["state_list"] = stateList;
    options["city"] = cityList;
    options["marital_status"] = options?.marital_status?.map((ob) => {
      return { ...ob, value: ob.id };
    });
    return options;
  }, [masterdata]);

  const setCityByStateChange = (state) => {
    let options = ALL_OPTIONS ? { ...ALL_OPTIONS } : {};
    if (options?.city && options?.city?.length && state) {
      let record = options.city
        .filter((ob) => ob.state_id == state)
        .map((data) => {
          data.value = data.id;
          data.label = data.label;
          return data;
        });

      ALL_OPTIONS["city_list"] = record;
    }
  };

  useEffect(() => {
    setCityByStateChange(basicDetails?.cra_state);
  }, [basicDetails?.cra_state]);

  let BasicDetailsForm = useMemo(() => {
    return FORM_FIELDS.map((field) => {
      if (field.name === "dob") {
        field["maxDate"] = DOB_MAX_DATE;
        field["minDate"] = DOB_MIN_DATE;
      }
      return field;
    });
  }, []);

  BasicDetailsForm = useMemo(() => {
    if (basicDetails) {
      // To Show nationality input text box in case of non phillipines
      if (basicDetails.nationality !== "other") {
        return FORM_FIELDS.filter((v) => v.id !== "other_nationality");
      }
    }
    return FORM_FIELDS;
  }, [basicDetails]);

  const handleAddCoApplicant = () => {
    const existingCoApp = [...coApplicantDetails];
    const newCoApplicants = {
      ca_first_name: "",
      ca_last_name: "",
      ca_gender: "",
      relationship_with_customer: "",
      ca_mobile: "",
      ca_email: "",
      ca_dob: "",
      ca_aadhar_no: "",
      ca_pancard_no: "",
      ca_annual_income: "",
      ca_mother_name: "",
      ca_qualification: "",
      ca_cra_address1: "",
      ca_cra_address2: "",
      ca_cra_state: "",
      ca_cra_city: "",
      ca_cra_pincode: "",
      ca_type: "",
      same_as_cus_addr: "0",
      aadhar_touch: 1,
      masked_ca_mobile: "",
    };
    existingCoApp.push(newCoApplicants);
    setCoApplicantDetails(existingCoApp);
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    let ob = {};
    setTouch({
      ...isFormTouch,
      [id]: true,
    });
    let inputValue = value;
    if (id === "pancard_no") {
      inputValue = value.toUpperCase();
      const isValidPan = /^[A-Z]{3}[CHFATBLJGP][A-Z]\d{4}[A-Z]$/.test(
        value.toUpperCase()
      );
      if (
        isValidPan &&
        (basicDetails["aadhar_no"] === "" ||
          basicDetails["aadhar_no"] === null ||
          basicDetails["aadhar_no"] === undefined)
      ) {
        setIsFetchAadharVisible(true);
      } else {
        setIsFetchAadharVisible(false);
      }
    } else if (id == "cra_pincode" && value.length == 6) {
      setCityStateByPin(value);
    } else if (id == "aadhar_no") {
      if (basicDetails?.aadhar_no?.length == 12 && value?.length == 14) {
        inputValue = basicDetails.aadhar_no;
      } else {
        inputValue = value.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ");
        inputValue = /^[0-9\sX]+$/.test(inputValue) ? inputValue : "XXXX XXXX";
      }
      ob.aadhar_touch = 0;
    }
    ob[id] = inputValue.trim();
    setBasicDetails((currentValue) => ({
      ...currentValue,
      ...ob,
    }));
  };

  const setCityStateByPin = async (pincode) => {
    try {
      let response = await GeneralService.stateCityByPincode(pincode);
      if (response?.data?.data?.length) {
        let item = response.data.data[0];
        setCityByStateChange(item?.state_id);
        setBasicDetails({
          ...basicDetails,
          cra_pincode: pincode,
          cra_state: item?.state_id,
          cra_city: item?.city_id,
        });
      }
    } catch (error) {
      console.error("Error :", error);
    }
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setBasicDetails((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setBasicDetails((currentValue) => ({
        ...currentValue,
        [field_name]: date,
      }));
    }
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      if (field_name.includes("cra_state")) {
        setCityByStateChange(data.value);
      }
      setBasicDetails((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleSetActiveTab = (tabName) => {
    setActiveTab(tabName);
  };

  const handleFetchAadhar = async () => {
    try {
      const aadharField = document.getElementById("aadhar_no");
      setIsFetchAadharVisible(false);
      const pan_card = basicDetails["pancard_no"];

      executeGraphQLQueryFinex(GET_DETAILS_BY_PANCARD(pan_card))
        .then((result) => {
          if (result.data) {
            const jsonResp = JSON.parse(result.data?.get_details_by_pancard);
            if (jsonResp.status === 200) {
              if (jsonResp.data?.response?.maskedAadhaar) {
                setBasicDetails((current) => ({
                  ...current,
                  aadhar_no: jsonResp.data?.response?.maskedAadhaar,
                  masked_aadhar_no: jsonResp.data?.response?.maskedAadhaar,
                }));
                aadharField.readOnly = true;
              } else {
                setBasicDetails((current) => ({
                  ...current,
                  aadhar_no: `XXXXXXXX`,
                  masked_aadhar_no: `XXXXXXXX`,
                }));
                toast.error(jsonResp.data?.response?.message);
                aadharField.readOnly = false;
              }
            } else {
              aadharField.readOnly = false;
              setBasicDetails((current) => ({
                ...current,
                aadhar_no: `XXXXXXXX`,
                masked_aadhar_no: `XXXXXXXX`,
              }));
              toast.error("Internal server error");
            }
          } else {
            console.log(result);
            aadharField.readOnly = false;
            setBasicDetails((current) => ({
              ...current,
              aadhar_no: "",
              masked_aadhar_no: "",
            }));
            toast.error("Internal server error");
          }
        })
        .catch((error) => {
          console.error("ERROR : ", error.message);
        });
    } catch (error) {
      console.log(`ERROR : ${error.message}`);
      toast.error(error.message);
    }
  };

  const handleSubmit = (isExitPage = false) => {
    setIsSubmitDisabled(true);
    let lead_id = leadDetail?.id || "";
    let reqBody = {
      lead_id: lead_id,
      first_name: basicDetails.first_name || "",
      last_name: basicDetails.last_name || "",
      mobile: basicDetails.mobile,
      email: basicDetails.email ? basicDetails.email : null,
      ...(basicDetails.marital_status && {
        marital_status: basicDetails.marital_status,
      }),
      ...(basicDetails.gender && { gender: basicDetails.gender }),
      // dob: basicDetails.dob && new Date(basicDetails.dob) < new Date() 
      // ? dateFormat(basicDetails.dob, "yyyy-mm-dd") 
      // : "",
  
      aadhar_no: basicDetails.aadhar_no?.trim().replace(/\s/g, "") || "",
      ...(basicDetails.cra_pincode && {
        cra_pincode: basicDetails.cra_pincode?.toString(),
      }),
      pancard_no: basicDetails.pancard_no,
      cra_address1: basicDetails.cra_address1 || "",
      cra_address2: basicDetails.cra_address2 || "",
      ...(basicDetails.cra_city && { cra_city: basicDetails.cra_city }),
      ...(basicDetails.cra_state && { cra_state: basicDetails.cra_state }),
      mother_name: basicDetails.mother_name || "",
      qualification: basicDetails.qualification || "",
    };

    let mutation = SAVE_CUST_DETAILS,
      variables = {
        api_called_by: "saathi_web",
        partner_id: loginUserInfo?.dealer_id,
        LeadAdditionalInput: {
          lead_id: lead_id,
          customer: reqBody,
        },
      };
    executeGraphQLMutationFinex(mutation, variables)
      .then((resp) => {
        let response = resp?.data?.save_customer_basic_details || null;
        if (response && !response.success) {
          setIsSubmitDisabled(false);
          throw new Error(response.error || "Something went wrong");
        }
        setTouch({});
        toast.success("Success");
        setIsSubmitDisabled(false);
        if (isExitPage)
          return history.push(`/saathi-details-page/${params.lead_id}`);
        handleStepClick(3);
      })
      .catch((err) => {
        setIsSubmitDisabled(false);
        toast.error(err.message);
      });
  };

  const handleSetCoApplicantDetials = (co_applicant) => {
    setCoApplicantDetails(co_applicant);
  };

  return (
    <div>
      {activeTab === "primary" && (
        <>
          <div className="customerflex">
            <h4 className="customerheading">Customer Details</h4>
            {coApplicantDetails.length < MAX_CO_APPLICANT && (
              <div className="coapplicantadd" onClick={handleAddCoApplicant}>
                <span>+</span> Add a Co-Applicant
              </div>
            )}
          </div>

          <div className="tab-list">
            <div className="commontabs">
              <div>
                <div className="partnername">Primary</div>
                <div
  onClick={() => setActiveTab("primary")}
  className={activeTab === "primary" ? "commontabsItem active" : "commontabsItem" }
>
  <div className="newtabContent">
    {leadDetail?.customer?.first_name || "No Name"}
  </div>
</div>


              </div>

              {coApplicantDetails.map((coApplicant, index) => (
                <div>
                  <div className="coapplicantname">{`Co-Applicant - ${
                    index + 1
                  }`}</div>
                  <div
                    className={
                      activeTab === `coApplicant_${index}`
                        ? "commontabsItem active"
                        : "commontabsItem"
                    }
                    onClick={() => setActiveTab(`coApplicant_${index}`)}
                    eventkey={`coApplicant_${index}`}
                    active={activeTab === `coApplicant_${index}`}
                  >
                    <div className="newtabContent">
                      {" "}
                      {coApplicant?.ca_first_name}{" "}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {activeTab === "primary" ? (
        <Formsy
          className="lead-form-filed"
          autoComplete="off"
          onValid={() => null}
          onInvalid={() => null}
          onValidSubmit={() => handleSubmit(false)}
        >
          <div className="row" style={{ marginBottom: 40 }}>
            {BasicDetailsForm.map((field, index) =>
              ["text", "pattern-format", "number"].includes(field.type) ? (
                <fieldset
                  className="form-filed col-md-4 "
                  key={index}
                  style={{ position: "relative" }}
                >
                  <FormsyInputField
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    value={
                      leadDetail?.fulfillment_type === "self" &&
                      (field.name === "mobile" ||
                        (field.name === "aadhar_no" &&
                          basicDetails.aadhar_touch))
                        ? basicDetails[`masked_${field.name}`] || ""
                        : (basicDetails && basicDetails[field.name]) || ""
                    }
                    placeholder=" "
                    maxLength={field.maxLength}
                    label={field.label}
                    onChange={
                      field.type === "pattern-format"
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                    }
                    format={field.ApplyNumberformat ? field.format : ""}
                    validations={
                      isFormTouch[field.name] ? field.validations : null
                    }
                    validationError={
                      basicDetails[field.name] || basicDetails[field.name] == ""
                        ? isFormTouch[field.name]
                          ? field.validationError
                          : ""
                        : ""
                    }
                    readOnly={field.readOnly}
                    required={field.required}
                  />
                  {field.name === "pancard_no" && isFetchAadharVisible && (
                    <a
                      href="#"
                      style={{ position: "absolute", color: "#6E55B8" }}
                    >
                      <span
                        type="submit"
                        className="fetchAadharbtn"
                        onClick={handleFetchAadhar}
                        style={{ fontSize: "12px" }}
                      >
                        Fetch Aadhar
                      </span>
                    </a>
                  )}
                </fieldset>
              ) : field.type === "date-picker" ? (
                <fieldset className="form-filed col-md-4 " key={index}>
                  <FormsyDatePicker
                    name="dob"
                    selectedDate={
                      basicDetails &&
                      basicDetails[field.name] &&
                      new Date(basicDetails[field.name])
                    }
                    placeholder={field.label}
                    maxDate={field.maxDate}
                    minDate={field.minDate}
                    value={basicDetails && basicDetails[field.name]}
                    onDateSelect={(date) => handleDateChange(date, field.name)}
                    dateFormat="dd MMM, yyyy"
                    validations={field.validations}
                    validationError={
                      basicDetails[field.name] ? field.validationError : ""
                    }
                    required={field.required}
                    hasError={
                      basicDetails.hasOwnProperty(field.name) &&
                      !basicDetails[field.name]
                        ? true
                        : false
                    }
                  />
                </fieldset>
              ) : field.type === "dropdown" ? (
                <fieldset className="single-select col-md-4" key={index}>
                  <FormsySelect
                    name={field.name}
                    id={field.id}
                    inputProps={{
                      options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                      placeholder: field.label,
                      className: "react-select",
                      classNamePrefix: "react-select",
                      value:
                        ALL_OPTIONS &&
                        ALL_OPTIONS[field.optionsKey] &&
                        ALL_OPTIONS[field.optionsKey].filter(
                          ({ value }) => value == basicDetails[field.name]
                        ),
                      isDisabled: field.readOnly,
                    }}
                    required={field.required}
                    value={basicDetails && basicDetails[field.name]}
                    onChange={(data) => handleSelectChange(data, field.name)}
                  />
                </fieldset>
              ) : null
            )}
          </div>
          <div className="row">
            <div className="saveandexit">
              <button
                type="button"
                className="markloatbrn"
                onClick={() => handleSubmit(true)}
                style={{ marginTop: "50px" }}
              >
                Save & Exit
              </button>
              <button
                type="submit"
                className="editnextbtn1"
                style={{ marginTop: 50 }}
                disabled={isSubmitDisabled}
              >
                Next
              </button>
            </div>
          </div>
        </Formsy>
      ) : (
        <>
          <CoApplicantDetails
            leadDetail={leadDetail}
            masterdata={masterdata}
            activeCoApplicant={activeTab}
            setActiveTabFromChild={handleSetActiveTab}
            setCoApplicantDetails={handleSetCoApplicantDetials}
            coApplicantDetails={coApplicantDetails}
          />
        </>
      )}
    </div>
  );
};

export default CustomerDetails;
