import React, { useEffect, useState, useRef } from "react";
import MasterService from "../../../service/MasterService";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import DateFormate from "dateformat";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import {
  PAYOUT__LIST_WITH_FILTERS,
  PAYOUT_LEADS_COUNTS,
  PARTNER_DISBURSAL_LIST,
  CREATE_INVOICE,
  INVOICE_LISTING,
  INVOICE_LISTING_DATA,
} from "../../../queries/payout.gql";
import secureStorage from ".././../../config/encrypt";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  SCHEME_LIST,
  FINEX_MASTER_LIST,
  FINEX_BANK_LIST,
} from "../../../queries/scheme.gql";
import Pagination from "../../pagination/Pagination";
const moment = require("moment");

const PayoutListing = (props) => {
  const filter_params = props.filter_params;
  const DataFIlter = props.DataFIlter;

  const filterParams = {};
  filterParams[filter_params.filter_params] = isNaN(+filter_params.app_id)
    ? filter_params.app_id
    : +filter_params.app_id;

 // console.log(filter_params, "----------------------------");

  let Status = props.state.status;
  const partner_id = props.filterVariables.partner_id;
  const loan_id = props.filterVariables.app_id;
  const filterOptions = props.filterOptions;
  let counter = props.counter;

  let buttonClick = props.buttonClick;

  const [filterData, setFilterData] = useState([]);
  const [page_no, setPageNo] = useState(1);
  const [partnerListing, setPartnerData] = useState([]);
  const [invoiceListData, setInvoiceListData] = useState([]);
  const [invoicedata, setInvoicedata] = useState([]);
  const [payoutListing, setPayoutListing] = useState([]);
  const [paginationdata, setPaginations] = useState({});
  const [pageloading, setpageloading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showEye,setShowEye] = useState(null)
  const [selected_partner_id, setSelectedPartnerID] = useState(0);
  const [payoutListingShow, setPayoutListingShow] = useState(null);
  const [payoutRow, setPayoutRow] = useState(null);
  const [schemeList, setschemeList] = useState([]);
  const [payoutApprovalIds, setPayoutIdsApproval] = useState([]);
  const [showSave, setShowSave] = useState(false);
  const [leads_counts, setLeadsCounts] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [productType, setProductType] = useState([]);
  const [financers, setFinancers] = useState([]);
  const [fulfillmenttypes, setFulfillmentTypes] = useState([]);
  const [payout_comments, setPayoutComments] = useState("");
  const [selected_amount, setSelectedAmount] = useState(0);
  const [user_list, setUserList] = useState([]);
  const [financersBanks, setFinancersPersonal] = useState([]);
  const [visible, SetVisible] = useState(true);
  const [originalData, setOriginalData] = useState([]);

  const updateReduxData = async () => {
    if (secureStorage.getItem("loginUserInfo")) {
      var loginResponse = secureStorage.getItem("loginUserInfo");
      props.updateStateIntoRedux("LOGIN_USER", loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return <Redirect to={"/login"} />;
    }
  };

  const afterLoginSetState = (loginResponse) => {
    setpageloading(false);
    let role =
      (loginResponse &&
        loginResponse.data &&
        loginResponse.data.user_data &&
        loginResponse.data.user_data.role) ||
      null;
    if (role != "dealercentral") {
      return <Redirect to={"/"} />;
    }
  };

  const handleRowClick = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
    setPayoutComments("");
  };

  const handlePayoutListing = (index, partnerid) => {
    setShowEye(selectedRow === index ? null : index);
    if (payoutApprovalIds && payoutApprovalIds.length > 0) {
      if (
        window.confirm(
          "Already added payouts for invoice, Do you want to delete old invoice data"
        )
      ) {
        setPayoutIdsApproval([]);
        setSelectedAmount(0);
      } else {
        return false;
      }
    }
    setPayoutListingShow(payoutListingShow === index ? null : index);

    setPayoutComments("");
    setSelectedPartnerID(partnerid);
    getPayoutListWithFilters("", partnerid, 1);
    getInvoiceData("", partnerid, 1);
  };

  const handlePayoutRowClick = (index) => {
    setPayoutRow(payoutRow === index ? null : index);
    setPayoutComments("");
  };






  
  const getInvoiceList = async (status, partner_id, pageNumber) => {
    if (
      Status === "invoice_created" ||
      Status === "payment_pending" ||
      Status === "paid"
    ) {
      setpageloading(true);

      const payout_filters = {
        partner_id: partner_id,
        is_paging: "no",
        status: Status,
        source: "web",
      };

      if (filter_params.filter_params === "lead_id") {
        payout_filters["lead_id"] = filterParams.lead_id;
      }
      if (filter_params.filter_params === "partner_code") {
        payout_filters["partner_ids"] = typeof filterParams.partner_code ==='number' ? [filterParams.partner_code] : filterParams.partner_code;
      }
      if (filter_params.filter_params === "Customer_Name") {
        payout_filters["customer_name"] = filterParams.Customer_Name;
      }
      if (filter_params.filter_params === "Scheme_ID") {
        payout_filters["scheme_id"] = filterParams.Scheme_ID;
      }

      if (filter_params.disbursal_date_from !== "") {
        payout_filters["disbursal_date_from"] =
          filter_params.disbursal_date_from;
      }
      if (filter_params.disbursal_date_to !== "") {
        payout_filters["disbursal_date_to"] = filter_params.disbursal_date_to;
      }
      if (filter_params.product_type !== "") {
        payout_filters["product_type"] = filter_params.product_type;
      }
      // if (filter_params.Select_Scheme !== "") {
      //   payout_filters["scheme_id"] = filter_params.scheme_id;
      // }

      if (DataFIlter.financier_id && DataFIlter.financier_id.length > 0) {
        payout_filters["financersids"] = DataFIlter.financier_id;
      }
      if (DataFIlter.partnerName && DataFIlter.partnerName.length > 0) {
        payout_filters["partner_ids"] = DataFIlter.partnerName;
      }

      if (status && status != "") payout_filters.paid_status = Status;

      const variables = {
        filter_params: payout_filters,
        page_no: pageNumber ? pageNumber : 1,
      };
      try {
        const result = await executeGraphQLMutation(
          INVOICE_LISTING,
          variables,
          pageNumber
        );
        if (result.data.invoice_list.length == 0) {
          setPayoutListing([]);
          setPaginations({});
          setpageloading(false);

          setInvoiceListData([]); //t
        } else {
          const invoices = result.data.invoice_list[0]?.invoices;
          setPayoutListing(invoices);
          setPaginations(result.data.invoice_list[0].pagination);
          setInvoiceListData(invoices);
        }
        if (result) {
          if (result.data.invoice_list && result.data.invoice_list.length > 0) {
            const disbursals = result.data.invoice_list[0].invoice;

            setPayoutListing(disbursals);
            setpageloading(false);
          }
        } else {
          setInvoiceListData([]);
          setPayoutListing([]);
          setPaginations({});
          setpageloading(false);
        }
      } catch (error) {
        toast.error("Failed");
        console.error("error", error);
      }
    }
  };














  const getInvoiceData = async (status, partner_id, pageNumber) => {
    if (
      Status === "invoice_created" ||
      Status === "payment_pending" ||
      Status === "paid"
    ) {
      setpageloading(true);

      try {
        const result = await executeGraphQLMutation(
          INVOICE_LISTING_DATA(partner_id)
        );
        if (result.data.invoice_payouts.length == 0) {
          setpageloading(false);
        } else {
          const invoices = result.data.invoice_payouts;

          setInvoicedata(invoices);
          setOriginalData(invoices);
        }
      } catch (error) {
        toast.error("Failed");
        console.error("error", error);
      }
    }
  };






















  const getPayoutListWithFilters = async (status, partner_id, pageNumber) => {
    if (Status === "pending") {
      setpageloading(true);
      const payout_filters = {
        partner_id: partner_id,
        is_paging: "no",
        paid_status: "pending",
        source: "web",
      };

      

      const variables = {
        filter_params: payout_filters,
        page_no: pageNumber ? pageNumber : 1,
      };
      try {
        const result = await executeGraphQLMutation(
          PAYOUT__LIST_WITH_FILTERS,
          variables,
          pageNumber
        );
        if (result.data.getDisbursalLeads.length == 0) {
          setPayoutListing([]);
          setPaginations({});
          setpageloading(false);
        }
        if (result) {
          if (
            result.data.getDisbursalLeads &&
            result.data.getDisbursalLeads.length > 0
          ) {
            const disbursals = result.data.getDisbursalLeads[0].disbursals;
            setPayoutListing(disbursals);
            setpageloading(false);
          }
        } else {
          setPartnerData([]);
          setPayoutListing([]);
          setPaginations({});
          setpageloading(false);
        }
      } catch (error) {
        toast.error("Failed");
        console.error(error);
      }
    }
  };
















  const getPartnersDisbursalList = async (
    
    status = Status,
    current_page = 1
  ) => {
    setpageloading(true);
    const payout_filters = { ...filterData };
    if (filter_params.filter_params === "lead_id") {
      payout_filters["lead_id"] = filterParams.lead_id;
    }
    if (filter_params.filter_params === "partner_code") {
      payout_filters["partner_ids"] = typeof filterParams.partner_code ==='number' ? [filterParams.partner_code] : filterParams.partner_code;
    }
    if (filter_params.filter_params === "Customer_Name") {
      payout_filters["customer_name"] = filterParams.Customer_Name;
    }
    if (filter_params.filter_params === "Scheme_ID") {
      payout_filters["scheme_id"] = filterParams.Scheme_ID;
    }

    if (filter_params.disbursal_date_from !== "") {
      payout_filters["disbursal_date_from"] =
        filter_params.disbursal_date_from;
    }
    if (filter_params.disbursal_date_to !== "") {
      payout_filters["disbursal_date_to"] = filter_params.disbursal_date_to;
    }
    if (filter_params.product_type !== "") {
      payout_filters["product_type"] = filter_params.product_type;
    }
    if (filter_params.Select_Scheme !== "") {
      payout_filters["scheme_id"] = filter_params.scheme_id;
    }

    if (DataFIlter.financier_id && DataFIlter.financier_id.length > 0) {
      payout_filters["financersids"] = DataFIlter.financier_id;
    }
    if (DataFIlter.partnerName && DataFIlter.partnerName.length > 0) {
      payout_filters["partner_ids"] = DataFIlter.partnerName;
    }

    // if (status && status != "")
    //     payout_filters.paid_status = "pending"

    payout_filters.status = !Status ? Status : Status;

    const pageNumber = current_page ? current_page : page_no;
    if (
      payout_filters.product_type_ids &&
      payout_filters.product_type_ids.length > 0
    ) {
      payout_filters.product_type = payout_filters.product_type_ids[0];
    }
    if (status && status != "") {
      payout_filters.status = status;
    }
    if (payout_filters.financier_id && payout_filters.financier_id.length > 0) {
      payout_filters.financersids = payout_filters.financier_id;
    }
    delete payout_filters.schemefinancers;
    delete payout_filters.product_type_ids;
    delete payout_filters.financier_id;
    const variables = {
      filter_params: payout_filters,
      page_no: pageNumber,
    };

    try {
      const result = await executeGraphQLMutation(
        PARTNER_DISBURSAL_LIST,
        variables,
        pageNumber
      );

      if (result.data.partner_disbursals.length == 0) {
        setPartnerData([]);
        setPayoutListing([]);
        setPaginations({});
        setpageloading(false);
      }
      if (result) {
        if (
          result.data.partner_disbursals &&
          result.data.partner_disbursals.length > 0
        ) {
          const disbursals = result.data.partner_disbursals[0].disbursals;
          setPartnerData(disbursals);
          setPaginations(result.data.partner_disbursals[0].pagination);
          setpageloading(false);
        }
      } else {
        setPartnerData([]);
        setPaginations({});
        setpageloading(false);
      }
    } catch (error) {
      toast.error("Failed");
    }
  };












  useEffect(() => {
    getDealerList();
    getSchemeList();
    updateReduxData();
    // getPayoutListWithFilters();
    getInvoiceData();
    getFinexMasterData();
    getFinexPersonalBankData();
  }, []);
  useEffect(() => {
    getInvoiceList();
    

  }, [counter]);
  useEffect(() => {
    getInvoiceList();
    getPartnersDisbursalList();
  }, [buttonClick]);

  useEffect(() => {
   // console.log({ filterOptions });
    if (Object.keys(filterOptions)) {
      const filteredData = originalData.filter(
        (obj) => obj.id == filterOptions.partner_id
      );
      setOriginalData(invoicedata);
      setInvoicedata(filteredData);
    }
  }, [filterOptions]);

  const getSchemeList = async () => {
    const partnerArray = [];
    const schemeData = await executeGraphQLQuery(SCHEME_LIST);
    const data = schemeData.data.getAllSchemsList;
    data &&
      data.map((list) => {
        const partnerData = {
          label: list.id,
          value: list.scheme_name,
        };
        partnerArray.push(partnerData);
      });
    setschemeList(partnerArray);
  };

  const getleadsCounts = async () => {
    const leadscounts = await executeGraphQLQuery(PAYOUT_LEADS_COUNTS);
    const data = leadscounts.data.leads_count;

    let total = 0;
    data &&
      data.map((list) => {
        total = total + parseInt(list.counts);
      });
    if (total > 0) {
      setTotalLeads(total);
    }
    setLeadsCounts(data);
  };
  const handleApprovalCheckBox = (e, payout_id, selectedRow, amount, partner_id) => {
       
    // if(partner_id!=selected_partner_id){
    //    if(window.confirm("You have already added another partner payouts, Do you want to remove it")){
    //     setPayoutIdsApproval([])
    //     setSelectedAmount(0)
    //     let showsaveValue = payoutApprovalIds.length > 0 ? true : false
    //     setShowSave(showsaveValue)
    //    }
    // }
    let calculated_amount = selected_amount;
    if (e.target.checked == true) {
        payoutApprovalIds.push(payout_id)
        calculated_amount = parseFloat(selected_amount) + parseFloat(amount)
    } else {
      const index = payoutApprovalIds.indexOf(payout_id);
      if (index !== -1) {
          payoutApprovalIds.splice(index, 1);
      }
      calculated_amount = parseFloat(selected_amount) - parseFloat(amount);
  }

    setSelectedAmount(calculated_amount)
    setPayoutIdsApproval(payoutApprovalIds)
    let showsaveValue = payoutApprovalIds.length > 0 ? true : false
    setShowSave(showsaveValue)
};



  const createInvoice = async (partner_id) => {
    const variables = {
      createinvoiceargs: {
        payout_ids: payoutApprovalIds,
        partner_id: partner_id,
      },
    };
    try {
      const result = await executeGraphQLMutation(CREATE_INVOICE, variables);
      if (result && result.data && result.data) {
        toast.success(result?.data?.create_invoice || "Success");
        getPayoutListWithFilters();
        setShowSave(false);
        setPayoutIdsApproval([]);
        setSelectedAmount(0);
        setPayoutComments("");
        getleadsCounts();
      }
    } catch (error) {
      toast.error("Failed");
      console.error(error);
    }
  };

  const getFinexMasterData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.masterdata) {
      const bankData = [];
      const producttypedata = [];
      const fulfillmenttypeData = [];
      const data = masterData.data.masterdata;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      const product_type =
        data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
        });
        setFinancers(bankData);
      }
      if (product_type && product_type.length > 0) {
        product_type.map((ptype) => {
          producttypedata.push({ id: ptype.id, name: ptype.label });
        });
        setProductType(producttypedata);
      }
      const fulfillmenttype =
        data.fulfillment_type && data.fulfillment_type.length > 0
          ? data.fulfillment_type
          : [];
      if (fulfillmenttype && fulfillmenttype.length > 0) {
        fulfillmenttype.map((ptype) => {
          fulfillmenttypeData.push({ id: ptype.id, name: ptype.label });
        });
        setFulfillmentTypes(fulfillmenttypeData);
      }
    }
  };

  const getFinexPersonalBankData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_BANK_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.bank_pa) {
      const bankData = [];
      const data = masterData.data.bank_pa;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      const product_type =
        data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
        });
        setFinancersPersonal(bankData);
      }
    }
  };

  const getDealerList = () => {
    MasterService.get("account/user/user_list")
      .then(function (response) {
        if (response.data.status == 200) {
          if (response.data.data.length) {
            setUserList(response.data.data);
          } else {
            setUserList([]);
          }
        }
      })
      .catch(function (response) {});
  };

  let role;
  if (secureStorage.getItem("loginUserInfo")) {
    let loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
      role = loginResponse.data.user_data.role;
    }
  }

  const onPaginate = (pageNumber) => {
    setPageNo(pageNumber);
    getPayoutListWithFilters("", pageNumber);
  };



  return (
    <>
      {Status === "invoice_created" ||
      Status === "payment_pending" ||
      Status === "paid" ? (
        <div className="clearfix">
          <div className="table-responsive" width={100}>
            <table className="table table-bordered fontsize12px">
              <thead>
                <tr>
                  <th>Payout Id</th>
                  <th>Partner Details</th>
                  <th>Payout Amt</th>
                  <th>Disb Date</th>

                  {Status === "invoice_created" ||
                  Status === "payment_pending" ? (
                    <th>Bank Details</th>
                  ) : Status === "paid" ? (
                    <th>Payment Details</th>
                  ) : null}

                  <th>Leads</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {invoiceListData &&
                  invoiceListData.map((partner, index) => (
                    <React.Fragment key={index}>
                    
                      <tr>
                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            {" "}
                            {partner.id}
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            Partner Code : <span> AMK{partner.partner_id}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            Partner Name : <span>{partner.partner_name}</span>
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            Total Amount:{partner.payout_amount}
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            PAT Amount : {partner.pat}
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            TDS Aount : {partner.tds}
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            Acknowledgment Date:&nbsp;&nbsp;&nbsp; {partner.created_at ? DateFormate(new Date(partner.created_at), "dS mmm yyyy") : null}
                          </div>
                          {partner.approved_at ? 
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            Approved Date:&nbsp;&nbsp;&nbsp;{partner.approved_at ? DateFormate(new Date(partner.approved_at), "dS mmm yyyy") : null}
                          </div> : null}

                          {partner.paid_date ? 
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            Paid Date:&nbsp;&nbsp;&nbsp;{partner.paid_date ? DateFormate(new Date(partner.paid_date), "dS mmm yyyy") : null}
                          </div> : null}
                        </td>
                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                           A/C:  &nbsp;&nbsp;&nbsp;<span style={{ opacity: 0.6 }} >{partner?.bankdetails?.bank_account_no}</span> 
                          </div>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            ISFC:&nbsp;&nbsp;&nbsp;<span style={{ opacity: 0.6 }} >{partner?.bankdetails?.bank_ifsc_code}</span>
                          </div>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            beneficiary Name:&nbsp;&nbsp;&nbsp;
                            <span style={{ opacity: 0.6 }} >{partner?.bankdetails?.beneficiary_name}</span>
                          </div>
                        </td>

                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            {partner.total_lead}
                          </div>
                          <img
                            src={require("./../../ruleEngine/assets/images/downarrow.svg")}
                            alt=""
                            onClick={() =>
                              handlePayoutListing(index, partner.id)
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </td>

                        <td>
                          <div
                            className="btn-group btn-group-small mrg-r10"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={() =>
                                handlePayoutListing(index, partner.id)
                              }
                              title="View History"
                            >
                   {selectedRow === index ?  <i className="ic-remove_red_eyevisibility icons-normal"></i> :  <i className="ic-remove_red_eyevisibility icons-normal"></i>}            
                            </button>
                            <a target="_blank" href={partner.paid_status == 'invoice_created' ? partner.pending_invoice_url: partner.approved_invoice_url}>
                            <img
                              src={require("./../../ruleEngine/assets/images/pdf.svg")}
                              alt=""
                              style={{ cursor: "pointer" }}
                              
                            /> </a>
                          </div>
                        </td>
                      </tr>

                      {selected_partner_id &&
                      selected_partner_id == partner.id &&
                       showEye === index ?  (
                        <tr
                          style={{
                            backgroundColor: "rgb(245,245,245)",
                            paddingRight: "40px",
                            paddingLeft: "40px",
                          }}
                        >
                          <td colSpan={7}>
                            <div className="table-responsive" width={100}>
                              <table className="table table-bordered fontsize12px">
                                <thead>
                                  <tr>
                                    <th className="payouttable">
                                      {"Lead Details"}
                                    </th>
                                    <th className="payouttable">
                                      {"Scheme Details"}
                                    </th>
                                    <th className="payouttable">
                                      {"Disb. Amount"}
                                    </th>
                                    <th className="payouttable">{"Bank"}</th>
                                    <th className="payouttable">
                                      {"Dist. Date"}
                                    </th>
                                    <th className="payouttable">{"Payout%"}</th>
                                    <th className="payouttable">
                                      {"Payout Details"}
                                    </th>
                                    <th className="payouttable">
                                      {"PAT"}
                                    </th>
                                    <th className="payouttable">{""}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {invoicedata &&
                                    invoicedata.map((partnerlead, index) => (
                                      <React.Fragment key={index}>
                                       
                                        <tr
                                          onClick={() => handleRowClick(index)}
                                        >
                                          <td>
                                          <div>
                                           ID : <span>{partnerlead?.payouts?.partnerleads?.lead_id}</span>
                                          </div>
                                          <div>
                                           Tranche : <span>{partnerlead?.payouts?.partnerleads?.tranche_id}</span>
                                          </div>
                                          <div>
                                           App: <span>{partnerlead?.payouts?.partnerleads?.app_id}</span>
                                          </div>
                                         </td>

                                          <td>
                                            <div
                                              style={{
                                                fontSize: 13,
                                                fontWeight: 600,
                                              }}
                                            >
                                              Partner Code:
                                              {partnerlead?.payouts?.scheme?.id}
                                            </div>
                                            <div
                                              style={{
                                                fontSize: 13,
                                                fontWeight: 600,
                                              }}
                                            >
                                              Partner Name:
                                              {
                                                partnerlead?.payouts?.scheme
                                                  ?.scheme_name
                                              }
                                            </div>
                                          </td>
                                          <td>{partnerlead?.payouts?.partnerleads?.disbursal_amount}</td>
                                          <td>
                                            {financers &&
                                              financers.length > 0 &&
                                              partnerlead.id &&
                                              financers.filter(
                                                (bank) =>
                                                  bank.id == partnerlead.payouts.partnerleads.financer_id
                                              )[0]?.name}
                                          </td>
                                          <td>
                                          {DateFormate(new Date(partnerlead.payouts.partnerleads.disbursal_date), "dS mmm yyyy")}
                                           
                                          </td>
                                          <td>
                                            <div> Payout: {partnerlead?.payouts?.payout_per}%</div>
                                            <div> Paid: {partnerlead?.payouts?.paid_per}%</div>
                                          
                                          </td>
                                          <td>
                                            <div
                                              style={{
                                                fontSize: 13,
                                                fontWeight: 600,
                                              }}
                                            >
                                              Payout:
                                              {partnerlead?.payout_amount}
                                            </div>
                                            <div
                                              style={{
                                                fontSize: 13,
                                                fontWeight: 600,
                                              }}
                                            >
                                              PAT amount:{partnerlead?.pat}
                                            </div>
                                            <div
                                              style={{
                                                fontSize: 13,
                                                fontWeight: 600,
                                              }}
                                            >
                                              TDS amount:{partnerlead?.tds}
                                            </div>
                                          </td>
                                          <td>
                                            {partnerlead?.pat ??
                                              0 -
                                                (partnerlead?.pat ?? 0) -
                                                (partnerlead?.tds ?? 0)}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    ))}
                                </tbody>
                                {payoutApprovalIds &&
                                payoutApprovalIds.length > 0 ? (
                                  <tr>
                                    <td colSpan={8}>Total</td>
                                    <td colSpan={2}>{selected_amount}</td>
                                    <td colSpan={2}>
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() =>
                                          createInvoice(partner.partner_id)
                                        }
                                        title="View History"
                                      >
                                        Create Invoice
                                      </button>
                                    </td>
                                  </tr>
                                ) : null}
                              </table>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  ))}
                {partnerListing.length === 0 && (
                  <tr>
                    <td colSpan={9}>No payout found!</td>
                  </tr>
                )}

                <tr>
                  <td colSpan={3}>
                    {" "}
                    <Pagination
                      pageCount={
                        paginationdata && paginationdata.totalpage
                          ? paginationdata.totalpage
                          : 0
                      }
                      activePage={filterData.page_no ? filterData.page_no : 1}
                      onPaginate={onPaginate}
                    />
                  </td>
                  <td>
                    Total:{" "}
                    {paginationdata && paginationdata.totalrecords
                      ? paginationdata.totalrecords
                      : 0}{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : Status === "pending" ? (
        <div className="clearfix">
          <div className="table-responsive" width={100}>
            <table className="table table-bordered fontsize12px">
              <thead>
                <tr>
                  <th>Partner Details</th>

                  <th>Disb.Details</th>
                  <th>Payable Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {partnerListing &&
                  partnerListing.map((partner, index) => (
                    <React.Fragment key={index}>
                      {/* {console.log( "......................." , partnerListing)} */}
                      <tr>
                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            Partner Code : <span> AMK{partner.partner_id}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            Partner Name : <span>{partner.partner_name}</span>
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            Total Amt :{" "}
                            <span>
                              {new Intl.NumberFormat("en-IN").format(
                                partner.total_disbursal_amount
                              )}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            <span>
                              {new Intl.NumberFormat("en-IN").format(
                                partner.total_payout_amount
                              )}
                            </span>
                            <div style={{ opacity: 0.6 }}>
                            PAT AMOUNT : <span>{partner.total_pat}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            PAT AMOUNT : <span>{partner.total_tds}</span>
                          </div>
                          </div>
                        </td>
                        <td>
                          <div
                            className="btn-group btn-group-small mrg-r10"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={() =>
                                handlePayoutListing(index, partner.partner_id)
                              }
                              title="View History"
                            >
                              <i className="ic-remove_red_eyevisibility icons-normal"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                      {selected_partner_id &&
                      selected_partner_id == partner.partner_id &&
                      visible ? (
                        <tr
                          style={{
                            backgroundColor: "rgb(245,245,245)",
                            paddingRight: "40px",
                            paddingLeft: "40px",
                          }}
                        >
                          <td colSpan={4}>
                            <div className="table-responsive" width={100}>
                              <table className="table table-bordered fontsize12px">
                                <thead>
                                  <tr>
                                    <th className="payouttable">{"Lead ID"}</th>
                                    <th className="payouttable">{"Loan ID"}</th>
                                    <th className="payouttable">
                                      {"Customer Name"}
                                    </th>
                                    <th className="payouttable">
                                      {"Fulfillment Type"}
                                    </th>
                                    <th className="payouttable">
                                      {"Product Type"}
                                    </th>
                                    <th className="payouttable">{"Bank"}</th>
                                    <th className="payouttable">
                                      {"Disbursed"}
                                    </th>
                                    <th className="payouttable">{"Payout"}</th>
                                    <th className="payouttable">{"TDS"}</th>
                                    <th className="payouttable">{"PAT"}</th>
                                    <th className="payouttable">{"Paid"}</th>
                                    <th className="payouttable">{"Pending"}</th>
                                    <th className="payouttable">
                                      {"Disbursed Date"}
                                    </th>
                                    <th className="payouttable">{""}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {payoutListing &&
                                    payoutListing.map((partnerlead, index) => (
                                      <React.Fragment key={index}>
                                        <tr
                                          onClick={() => handleRowClick(index)}
                                        >
                                          <td>{partnerlead.lead_id}</td>
                                          <td>{partnerlead.app_id}</td>
                                          <td>{partnerlead.customer_name}</td>
                                          <td>
                                            {partnerlead.fulfillment_type}
                                          </td>
                                          <td>
                                            {productType &&
                                              productType.length > 0 &&
                                              productType.filter(
                                                (type) =>
                                                  type.id ==
                                                  partnerlead.product_type
                                              )[0]?.name}

                                          </td>
                                          <td>
                                            {financers &&
                                              financers.length > 0 &&
                                              partnerlead.financer_id &&
                                              financers.filter(
                                                (bank) =>
                                                  bank.id ==
                                                  partnerlead.financer_id
                                              )[0]?.name}
                                          </td>
                                          <td>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              partnerlead.disbursal_amount
                                            )}
                                          </td>
                                          <td>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(partnerlead.payout_total)}
                                          </td>
                                          <td>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(partnerlead.tds_total)}
                                          </td>
                                          <td>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(partnerlead.pat_total)}
                                          </td>
                                          <td>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(partnerlead.payout_paid)}
                                          </td>
                                          <td>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              partnerlead.payout_pending
                                            )}
                                          </td>
                                          <td>
                                          {DateFormate(new Date(partnerlead.disbursal_date), "dS mmm yyyy")}
                                           
                                          </td>
                                          <td
                                            style={{
                                              fontSize: 20,
                                              cursor: "pointer",
                                            }}
                                          >
                                            {selectedRow === index ? "-" : "+"}
                                          </td>
                                        </tr>
                                        {selectedRow === index ? (
                                          <tr>
                                            <td colSpan={16}>
                                              <table className="table table-bordered">
                                                <thead>
                                                  <tr>
                                                    <th
                                                      onClick={() =>
                                                        handlePayoutRowClick(
                                                          index
                                                        )
                                                      }
                                                      className="payouttable"
                                                      style={{
                                                        fontSize: 20,
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {"ID"}{" "}
                                                      {payoutRow === index
                                                        ? "-"
                                                        : "+"}
                                                    </th>
                                                    <th className="payouttable">
                                                      {"Payout Type"}
                                                    </th>
                                                    <th className="payouttable">
                                                      {"Date"}
                                                    </th>
                                                    <th className="payouttable">
                                                      {"Scheme"}
                                                    </th>
                                                    <th className="payouttable">
                                                      {"Payout Per"}
                                                    </th>
                                                    <th className="payouttable">
                                                      {"Per."}
                                                    </th>
                                                    <th className="payouttable">
                                                      {"Payout Amount"}
                                                    </th>
                                                    <th className="payouttable">
                                                      {"TDS"}
                                                    </th>
                                                    <th className="payouttable">
                                                      {"TDS %"}
                                                    </th>
                                                    <th className="payouttable">
                                                      {"PAT"}
                                                    </th>
                                                    <th className="payouttable">
                                                      Paid Status
                                                    </th>
                                                    <th className="payouttable">
                                                      Created By
                                                    </th>
                                                    <th className="payouttable">
                                                      Updated By
                                                    </th>
                                                    <th className="payouttable">
                                                      Approval
                                                    </th>
                                                  </tr>
                                                </thead>

                                                <tbody>
                                                  {partnerlead.payouts &&
                                                    partnerlead.payouts.length >
                                                      0 &&
                                                    partnerlead.payouts.map(
                                                      (payout, index) => (
                                                        <React.Fragment
                                                          key={payout.id}
                                                        >
                                                          <tr key={payout.id}>
                                                            <td>{payout.id}</td>
                                                            <td>
                                                              {
                                                                payout.payout_type
                                                              }
                                                            </td>

                                                            <td>
                                                            {DateFormate(new Date(payout.created_at), "dS mmm yyyy")}
                                                             
                                                            </td>
                                                            <td>
                                                              {payout.scheme &&
                                                              payout.scheme
                                                                .scheme_name
                                                                ? payout.scheme
                                                                    .scheme_name
                                                                : ""}
                                                            </td>
                                                            <td>
                                                              {
                                                                payout.payout_per
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                payout.paid_per ? payout.paid_per : 100
                                                              } %
                                                            </td>
                                                            <td>
                                                              {
                                                                payout.payout_amount
                                                              }
                                                            </td>
                                                            <td>
                                                              {payout.tds}
                                                            </td>
                                                            <td>
                                                              {payout.tds_per}%
                                                            </td>
                                                            <td>
                                                              {payout.pat}
                                                            </td>
                                                            <td>
                                                              {payout.paid_status.replace(
                                                                "_",
                                                                " "
                                                              )}
                                                            </td>
                                                            <td>
                                                              {payout.created_by &&
                                                              user_list.length >
                                                                0
                                                                ? user_list.filter(
                                                                    (user) =>
                                                                      user.user_id ==
                                                                      payout.created_by
                                                                  )[0]?.name
                                                                : "system"}
                                                            </td>
                                                            <td>
                                                              {payout.updated_by &&
                                                              user_list.length >
                                                                0
                                                                ? user_list.filter(
                                                                    (user) =>
                                                                      user.user_id ==
                                                                      payout.updated_by
                                                                  )[0]?.name
                                                                : ""}
                                                            </td>
                                                            <td>
                                                                                                {(payout.paid_status == "pending") ?
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="paid_status"
                                                                                                        label="Veify"
                                                                                                        id={'paid_status'}
                                                                                                        key={'paid_status'}
                                                                                                        value={payout.id}
                                                                                                        checked={payoutApprovalIds && payoutApprovalIds.length>0 && payoutApprovalIds.includes(payout.id) ? true:false}
                                                                                                        onClick={(e) => handleApprovalCheckBox(e, payout.id, selectedRow, payout.pat, partner.partner_id)}

                                                                                                    /> : "-"}
                                                                                            </td>

                                                          </tr>
                                                        </React.Fragment>
                                                      )
                                                    )}
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        ) : (
                                          ""
                                        )}
                                      </React.Fragment>
                                    ))}
                                </tbody>
                                {payoutApprovalIds &&
                                payoutApprovalIds.length > 0 ? (
                                  <tr>
                                    <td colSpan={8}>Total</td>
                                    <td colSpan={2}>{selected_amount}</td>
                                    <td colSpan={2}>
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() =>
                                          createInvoice(partner.partner_id)
                                        }
                                        title="View History"
                                      >
                                        Create Invoice
                                      </button>
                                    </td>
                                  </tr>
                                ) : null}
                              </table>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  ))}
                {partnerListing.length === 0 && (
                  <tr>
                    <td colSpan={9}>No payout found!</td>
                   
                  </tr>
                )}
                <tr>
                  <td colSpan={3}>
                    {" "}
                    <Pagination
                      pageCount={
                        paginationdata && paginationdata.totalpage
                          ? paginationdata.totalpage
                          : 0
                      }
                      activePage={filterData.page_no ? filterData.page_no : 1}
                      onPaginate={onPaginate}
                    />
                  </td>
                  <td>
                    Total:{" "}
                    {paginationdata && paginationdata.totalrecords
                      ? paginationdata.totalrecords
                      : 0}{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(PayoutListing)
);
