import React, { useEffect, useState } from "react";
import Select from 'react-select';
import MultiSelect from "../../../commonselect/MultiSelect.js";
import MasterService from "../../../service/MasterService";
import {  Redirect } from "react-router-dom/cjs/react-router-dom.min";
import InputField from './../../elements/Input';
import { executeGraphQLMutation, executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import { toast } from 'react-toastify';
import { PAYOUT_LEADS_COUNTS, PARTNER_DISBURSAL_LIST } from "../../../queries/payout.gql";
import SearchContent from "./SearchContent.js";
import secureStorage from '.././../../config/encrypt';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { SCHEME_LIST, FINEX_MASTER_LIST, FINEX_BANK_LIST } from "../../../queries/scheme.gql";


const moment = require("moment")



const SearchFilter = (props) => {

    const [buttonClick , setButtonClick] = useState(0);
    const [filterData, setFilterData] = useState({});
    const [page_no, setPageNo] = useState(1);
    const [partnerListing, setPartnerData] = useState([]);
    const [payoutListing, setPayoutListing] = useState([]);
    const [paginationdata, setPaginations] = useState({});
    const [pageloading, setpageloading] = useState(false)
    const [schemeList, setschemeList] = useState([]);
    const [leads_counts, setLeadsCounts] = useState([]);
    const [totalLeads, setTotalLeads] = useState(0);
    const [productType, setProductType] = useState([]);
    const [financers, setFinancers] = useState([]);
    const [fulfillmenttypes, setFulfillmentTypes] = useState([])
    const [user_list, setUserList] = useState([])
    const [financersBanks, setFinancersPersonal] = useState([]);
    const [filterOptions, setFilterOptions] = useState({});
    const [passingData , setPassingData] = useState({});
    const [passingArray , setPassingArray] = useState([]);
    const [paymentData, setPaymentData] = useState({
        bank_id: 0,
        rtgs_no: "",
        trans_date: ""
    })
    const [filter_params, setFilterParams] = useState({});




    const filterOption = [
      
        { value: "lead_id", label: "Lead ID" },
        { value: "partner_code", label: "Partner Code" },
        { value: "Customer_Name", label: "Customer Name" },
        { value: "Scheme_ID", label: "Scheme ID" },
    ]





    const updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            afterLoginSetState(loginResponse)
        } else {
            return (<Redirect to={"/login"} />);
        }
    }
 




    const afterLoginSetState = (loginResponse) => {
        setpageloading(false)
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        if (role != "dealercentral") {
            return (<Redirect to={"/"} />);
        }
    }
   

    
    const handleInputarray = (e) => {
        const {id, value} = e.target;
        setFilterParams(current => ({
            ...current,
            [id]: value
        }));
    };




    const handleSelectarry = (id, data) => {
      // console.log ("datadatadatadatadatadatadatadatadatadatadata" , data)
        setFilterParams(current => ({
            ...current,
            [id]:   data.value || data.id || data.value


        }));
        
    }

    const submitFilterForm = (event) => {
        event.preventDefault();
        
    };

    const resetFilterForm = (event) => {
        window.location.reload(false);
    };




    const getPartnersDisbursalList = async (status = "pending", current_page = 1) => {
        setpageloading(true)
        const payout_filters = { ...filterData };
        payout_filters.status = !payout_filters.status ? "pending" : payout_filters.status;
        const pageNumber = current_page ? current_page : page_no;
        if (payout_filters.product_type_ids && payout_filters.product_type_ids.length > 0) {
            payout_filters.product_type = payout_filters.product_type_ids[0]
        }
        if (status && status != "") {
            payout_filters.status = status
        }
        if (payout_filters.financier_id && payout_filters.financier_id.length > 0) {
            payout_filters.financersids = payout_filters.financier_id
        }
        delete payout_filters.schemefinancers
        delete payout_filters.product_type_ids
        delete payout_filters.financier_id
        const variables = {
            "filter_params": payout_filters,
            "page_no": pageNumber
        }
   
        try {
            const result = await executeGraphQLMutation(PARTNER_DISBURSAL_LIST, variables, pageNumber);
            if (result.data.partner_disbursals.length == 0) {
                setPartnerData([]);
                setPayoutListing([]);
                setPaginations({});
                setpageloading(false)

            }
            if (result) {
                if (result.data.partner_disbursals && result.data.partner_disbursals.length > 0) {
                    const disbursals = result.data.partner_disbursals[0].disbursals;
                    setPartnerData(disbursals);
                    setPaginations(result.data.partner_disbursals[0].pagination);
                    setpageloading(false)
                }
            } else {
                setPartnerData([]);
                setPaginations({});
                setpageloading(false);
            }
        } catch (error) {
            toast.error("Failed");
            console.error("error dxf df dsf sd fs fds", error);
        }
    }
    useEffect(() => {

        getDealerList()
        getSchemeList()
        updateReduxData()
        // getleadsCounts()
        getFinexMasterData()
        getFinexPersonalBankData()
        getPartnersDisbursalList()
    }, []);


    const getSchemeList = async () => {
        const partnerArray = []
        const schemeData = await executeGraphQLQuery(SCHEME_LIST);
        
        const data = schemeData.data.getAllSchemsList
        data && data.map((list) => {
            const partnerData = {
                value: list.scheme_name,
                label: list.id,
               
            }
            partnerArray.push(partnerData)
        })
        setschemeList(partnerArray);
        
    }


  

    // const getleadsCounts = async () => {
    //     const leadscounts = await executeGraphQLQuery(PAYOUT_LEADS_COUNTS);
    //     const data = leadscounts.data.leads_count
    //     let total = 0
    //     data && data.map((list) => {
    //         total = total + parseInt(list.counts);
    //     })
    //     if (total > 0) {
    //         setTotalLeads(total)
    //     }
    //     setLeadsCounts(data);


    // }

    

    const getFinexMasterData = async () => {
        const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(err => console.log(err));
      
        if (masterData && masterData.data && masterData.data.masterdata) {
            const bankData = []
            const producttypedata = []
            const fulfillmenttypeData = []
            const data = masterData.data.masterdata;
            const bank_list = data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
            const product_type = data.loan_type && data.loan_type.length > 0 ? data.loan_type : []
            if (bank_list && bank_list.length > 0) {
                bank_list.map(banks => {
                    bankData.push({ id: banks.banklang.bank_id, name: banks.banklang.bank_name })
                })
                setFinancers(bankData)
            }
            if (product_type && product_type.length > 0) {
                product_type.map(ptype => {
                    producttypedata.push({ id: ptype.id, name: ptype.label })
                })
                setProductType(producttypedata)
            }
            const fulfillmenttype = data.fulfillment_type && data.fulfillment_type.length > 0 ? data.fulfillment_type : []
            if (fulfillmenttype && fulfillmenttype.length > 0) {
                fulfillmenttype.map(ptype => {
                    fulfillmenttypeData.push({ id: ptype.id, name: ptype.label })
                })
                setFulfillmentTypes(fulfillmenttypeData)
            }
            
        }

    }



    const getFinexPersonalBankData = async () => {
        const masterData = await executeGraphQLQuery(FINEX_BANK_LIST()).catch(err => console.log(err));
        if (masterData && masterData.data && masterData.data.bank_pa) {
            const bankData = []
            const data = masterData.data.bank_pa;
            const bank_list = data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
            const product_type = data.loan_type && data.loan_type.length > 0 ? data.loan_type : []
            if (bank_list && bank_list.length > 0) {
                bank_list.map(banks => {
                    bankData.push({ id: banks.banklang.bank_id, name: banks.banklang.bank_name })
                })
                setFinancersPersonal(bankData)
            }

        }

    }



    const filter_params_arry = ['lead_id', 'partner_code', 'Scheme_ID'];

    const validProps = () => {
      //  console.log('ALL are valids', filter_params);
        setPassingData(filter_params);
        setPassingArray(filterData);
        setButtonClick(prevCounter => prevCounter + 1);
    }

    const handleProps = () => {
   

        if (filter_params_arry.includes(filter_params.filter_params)) {
            isNaN(+filter_params.app_id) ? toast.error(`${filter_params.filter_params} should be a number`) : validProps();
        } else if (filter_params.filter_params == "Customer_Name") {
            !(isNaN(+filter_params.app_id)) ? toast.error(`${filter_params.filter_params} should be a string`) : validProps();
        }
        else {
            validProps();
        }
        
    }

    const getDealerList = () => {
        MasterService.get('account/user/user_list')
            .then(function (response) {
                if (response.data.status == 200) {
                   
                    if (response.data.data.length) {
                     //   console.log (response.data.data)
                        setUserList(response.data.data)
                    } else {
                        setUserList([])
                    }
                }
            })
            .catch(function (response) {

            });
        
    }




    let role;
    if (secureStorage.getItem('loginUserInfo')) {
        let loginResponse = secureStorage.getItem('loginUserInfo');
        if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
            role = loginResponse.data.user_data.role;
        }
    }

  


    const handleChange = async (n, v, isMulti, opt, optl) => {
     //   console.log({n, v, opt});
        switch (n) {
            case 'financier_id':
                let financier_ids = v.map(obj => { return obj.id });
                setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
              
                break;
            case 'partnerName':
                let partner_ids = v.map(obj => { return obj.user_id });
                setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
                
                break;
        }
        
    };
return (

<>    
    <div className="search-wrap">
        <form onSubmit={submitFilterForm}>
            <div className="row">



                <div className="col-sm-2 col-md-2">
                
                 <Select
                 id = "filter_params"
                 options={filterOption}
                 name = "filter_params"
                 onChange = {data => handleSelectarry("filter_params", data)}
                 values = {filterOption.value}
                />
                </div>




                <div className="col-sm-2 col-md-2">
                    <InputField
                        type="text"
                        name="app_id"
                        id="app_id"
                        placeholder='app id'
                        value = {filter_params["app_id"]}
                        is_label_required={true}
                        onChange={handleInputarray}
                    />    
                </div>
               



                <div className="col-sm-1 col-md-1">
                    <MultiSelect
                        options={user_list}
                        placeholder={"Partner Name"}
                        name="partnerName"
                        optionLabel="name"
                        optionValue="user_id"
                        id="partnerName"
                        values = {filterData.partnerName}
                        isMulti
                        onChange={handleChange}
                     
                    />
                </div>




                <div className="col-sm-1">  
                    <MultiSelect
                        options={financers}
                        placeholder={"Banks"}
                        name={'financier_id'}
                        optionLabel="name"
                        optionValue="id"
                        values={filterData.financier_id}
                        onChange={handleChange}
                        isMulti
                    />
                </div>




                <div className="col-sm-1">
                    <Select
                        placeholder = {"Product Type"}
                        id="product_type"
                        onChange = {data => handleSelectarry("product_type", data)}
                        options={productType}
                        label={"Select Product Type"}
                        name="product_type"
                        value={productType.value}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                    />
                </div>




                <div className="col-sm-1 col-md-1">
                    <Select
                    
                        id="Select_Scheme"
                        placeholder = {"Scheme"}
                        onChange = {data => handleSelectarry("Select_Scheme", data)}
                        options={schemeList}
                        name="partnerscheme"
                        optionLabel="name"
                        label = "select_Scheme"
                        value = {schemeList.label}
                        getOptionLabel={({ value }) => value}
                        getOptionValue={({ label }) => label}
                    />
                </div>



                <div className="col-sm-1 col-md-1" >
               
                    <InputField
                        type="date"
                        name="disbursal_date_from"
                        id="disbursal_date_from"
                        placeholder='date'
                        is_label_required={true}
                        onChange={handleInputarray}
                        value = {filter_params['disbursal_date_from']}
                    />
                </div>





                <div className="col-sm-1 col-md-1">
                     <InputField
                        type="date"
                        name="disbursal_date_to"
                        id="disbursal_date_to"
                        placeholder='date'
                        is_label_required={true}
                        onChange={handleInputarray}
                        value={filter_params['disbursal_date_to']}
                        min={'2024-04-12'}label
                    />
                </div>




                <div className="col-sm-2 col-md-2" style={{display:"flex" , justifyContent : "right"}}>
                    <button type="submit" className="btn btn-primary mrg-r10"   onClick = {handleProps}>{('Search')}</button>
                    <button type="reset" className="btn btn-reset"
                        onClick={resetFilterForm}
                    >{('Reset')}</button>
                </div>





            </div>
        </form>
       
    </div>
     <SearchContent state = {filterData} buttonClick = {buttonClick} filterOptions={filterOptions}   filter_params = {passingData} filterData = {passingArray} />
     </>
    

)


}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('dealer')(connect(mapStateToProps, mapDispatchToProps)(SearchFilter));