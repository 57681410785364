import React from "react";

const LoanStatusByStatus = () => {
  return (
    <>
      <div className="rightbottomcontainer" style={{ marginBottom: 20 }}>
        {/* Logged Details */}
        {/* <div>
          <div className="folloupheading">Logged In Details</div>
          <div className="flexrightdiv">
            <div className="loanstatuscolor">Logged In ID</div>
            <div className="loanstatuscolor">#3284</div>
          </div>
          <div>
            <ul className="loanstatuslist">
              <li className="flexrightdiv">
                <div className="otherinfocolor">
                  <span>•</span>Amount
                </div>
                <div className="loanstatuscolor">₹ 55,00,000</div>
              </li>
            </ul>
          </div>
        </div> */}

        {/* Sanctioned Details */}
        {/* <div>
          <div className="folloupheading">Sanctioned Details</div>
          <div className="flexrightdiv">
            <div className="loanstatuscolor">Logged In ID</div>
            <div className="loanstatuscolor">#3284</div>
          </div>
          <div>
            <ul className="loanstatuslist">
              <li className="flexrightdiv">
                <div className="otherinfocolor">
                  <span>•</span>Amount
                </div>
                <div className="loanstatuscolor">₹ 55,00,000</div>
              </li>
            </ul>
          </div>

          <div className="flexrightdiv">
            <div className="loanstatuscolor">Sanction ID</div>
            <div className="loanstatuscolor">#3284</div>
          </div>
          <div>
            <ul className="loanstatuslist">
              <li className="flexrightdiv">
                <div className="otherinfocolor">
                  <span>•</span>Amount
                </div>
                <div className="loanstatuscolor">₹ 55,00,000</div>
              </li>
              <li className="flexrightdiv">
                <div className="otherinfocolor">
                  <span>•</span>EMI
                </div>
                <div className="loanstatuscolor">₹ 55,00,000</div>
              </li>
            </ul>
          </div>
        </div> */}

        {/* Disbursed Details */}
        <div>
          <div className="folloupheading">Disbursed Details</div>

         
          <ul>
            <li>
              <div className="disbursecommonnew">
              <div className="commonimgflex"><span><img src="https://ambak.storage.googleapis.com/partner/3061/1733562776847.png" alt="icon" width={16} height={16}/></span>Transaction</div>
              <div className="loanstatuscolor">08 Nov, 2023</div>
              </div>
            </li>
            <li className="disbursecommonnew">
            <div className="commonimgflex"><span><img src="https://ambak.storage.googleapis.com/partner/3061/1733562923039.png" alt="icon" width={16} height={16}/></span> PDD</div>
            <div className="disbursepending">Pending</div>
            </li>
            <li className="disbursecommonnew">
            <div className="commonimgflex"><span><img src="https://ambak.storage.googleapis.com/partner/3061/1733563093839.png" alt="icon" width={16} height={16}/></span>Bank MIS</div>
            <div className="disbursepending">Pending</div>
            </li>
            <li className="disbursecommonnew">
            <div className="commonimgflex"><span><img src="https://ambak.storage.googleapis.com/partner/3061/1733563093839.png" alt="icon" width={16} height={16}/></span>Collection</div>
            <div className="disbursepending">Pending</div>
            </li>
            <li className="disbursecommonnew">
            <div className="commonimgflex"><span><img src="https://ambak.storage.googleapis.com/partner/3061/1733563093839.png" alt="icon" width={16} height={16}/></span>Payout</div>
            <div className="disbursepending">Pending</div>
            </li>
          </ul>
          <div >
           
          </div>
         
          <div className="flexrightdiv">
            <div className="loanstatuscolor">Disbursed ID</div>
            <div className="loanstatuscolor">#3284</div>
          </div>
          <div className="flexrightdiv">
            <div>Disbursed Amount</div>
            <div className="loanstatuscolor">₹ 55,00,000</div>
          </div>

          <div className="flexrightdiv">
            <div className="loanstatuscolor">Logged In ID</div>
            <div className="loanstatuscolor">#3284</div>
          </div>
          <div>
            <ul className="loanstatuslist">
              <li className="flexrightdiv">
                <div className="otherinfocolor">
                  <span>•</span>Amount
                </div>
                <div className="loanstatuscolor">₹ 55,00,000</div>
              </li>
            </ul>
          </div>

          <div className="flexrightdiv">
            <div className="loanstatuscolor">Sanction ID</div>
            <div className="loanstatuscolor">#3284</div>
          </div>
          <div>
            <ul className="loanstatuslist">
              <li className="flexrightdiv">
                <div className="otherinfocolor">
                  <span>•</span>Amount
                </div>
                <div className="loanstatuscolor">₹ 55,00,000</div>
              </li>
              <li className="flexrightdiv">
                <div className="otherinfocolor">
                  <span>•</span>EMI
                </div>
                <div className="loanstatuscolor">₹ 55,00,000</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanStatusByStatus;
