import React, { useEffect, useState, useRef } from "react";
import MultiSelect from "../../commonselect/MultiSelect.js";
import { Link, NavLink, Redirect } from "react-router-dom/cjs/react-router-dom.min";
import InputField from "../elements/InputField";
import DateFormate from 'dateformat';
import { executeGraphQLMutation, executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { DELETE_PAYOUT_POINT, GET_PAYOUT_POINTS_WITH_FILTERS} from "../../queries/payout.gql"
import { PAYOUT_POINT_DETAILS } from "../../queries/scheme.gql";
//import ReactPaginate from 'react-paginate';
import secureStorage from './../../config/encrypt';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Pagination from "../pagination/Pagination";
import { FINEX_MASTER_LIST } from "../../queries/scheme.gql";
import { toast } from 'react-toastify';
import Select from 'react-select';




const BookingPoint = (props) => {

    const [filterData, setFilterData] = useState({ page_no: 1, financier_id: [], start_date:null, end_date:null, payout_point:null });
    const [page_no, setPageNo] = useState(1);
    const [payout_points_list, setPayoutPointsList] = useState([]);
    const [paginationdata, setPaginations] = useState({});
    const [financers, setFinancers] = useState([]);
    const [bookingpoints, setBookingPoints] = useState([]);
    
    const [payout_points, setPayoutpoints] = useState([])


const getPayoutDataList =  async() => {
     const result = await executeGraphQLQuery(PAYOUT_POINT_DETAILS());
     const PayoutDataList = []
     result.data.points.map(point => {
        PayoutDataList.push({ id: point.id, name: point.booking_point });
    });
  //  console.log("...................................." ,PayoutDataList)
    setPayoutpoints(PayoutDataList)
   // console.log("....................................+." ,  bookingpoints )
}



    useEffect(() => {
        updateReduxData()
        getFinexMasterData()
        getPayoutDataList()
        getPayoutPoints(1)

    }, []);




    

    const updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            afterLoginSetState(loginResponse)
        } else {
            return (<Redirect to={"/login"} />);
        }
    }
    




    const afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        if (role != "dealercentral") {
            return (<Redirect to={"/"} />);
        }
    }






    const getPayoutPoints = async (page_no = 1) => {
        try {
            const responseData = await executeGraphQLQuery(GET_PAYOUT_POINTS_WITH_FILTERS(page_no, filterData.financier_id, filterData.payout_point, filterData.start_date, filterData.end_date))
            if (responseData?.data.payout_points_list) {
                setPayoutPointsList(responseData?.data?.payout_points_list[0]?.data)
                setPaginations(responseData?.data?.payout_points_list[0]?.pagination)
            }else{
                setPayoutPointsList([])
                setPaginations([])
            }

        } catch (error) {

        }
    }





    const deletePayoutPoint = async(id)=>{
        try {
            if(window.confirm("Do you want to delete this payout poing?")){
                const responseData = await executeGraphQLQuery(DELETE_PAYOUT_POINT(id))
                if (responseData?.data.delete_payoutpoint && responseData?.data.delete_payoutpoint!="") {
                    toast.success(responseData?.data?.delete_payoutpoint || "Success");
                }else{
                    toast.success("Something went wrong!");
                }
                getPayoutPoints()
            }
        } catch (error) {
            toast.success("Something went wrong!");
        }
    }







    const getFinexMasterData = async () => {
        const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(err => console.log(err));
        if (masterData && masterData.data && masterData.data.masterdata) {
            const bankData = []
            const bookingPointsData = []
            const data = masterData.data.masterdata;
            const bank_list = data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
            if (bank_list && bank_list.length > 0) {
                bank_list.map(banks => {
                    bankData.push({ id: banks.banklang.bank_id, name: banks.banklang.bank_name })
                })
                setFinancers(bankData)
                payout_points.map(point => {
                    bookingPointsData.push({ id: point.lead_status, name: point.point_name });
                });
                setBookingPoints(bookingPointsData);
            }

        }

    }







    const onPaginate = (pageNumber) => {
        //   setFilterData({ ...filterData, page_no: pageNumber });
        setPageNo(pageNumber)
        getPayoutPoints(pageNumber)
    };






    const handleChange = async (n, v, isMulti, opt, optl) => {
        switch (n) {
            case 'financier_id':
                let financier_ids = [];
                financier_ids = v.map(obj => { return obj.id });
                setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
              //  console.log("filterData", filterData);
                break;
        }
    }





    const handleSelectBox = (name, selectedOptions) => {
        if (name == 'booking_point') {
            const booking_point = selectedOptions.id;
            setFilterData({ ...filterData, payout_point: booking_point });
        }
       
    };
  





    const handleInputarray = (e) => {
        const filterDatanew = { ...filterData }
        const name = e.target.name;
        const val = e.target.value;
        filterDatanew[name] = val;
    //    console.log(filterDatanew)
        setFilterData(filterDatanew);
    };
    const submitFilterForm = (event) => {
        event.preventDefault();
    };

    const resetFilterForm = (event) => {
        window.location.reload(false);
    };

  








    return (
        <>
            <div className='container-fluid'>
              
                <h1>Payout Point</h1>
                <div className='search-wrap'>
                    <form onSubmit={submitFilterForm}>
                        <div className='row'>
                            <div className='col sm-2 col-md-2'>
                                <InputField
                                    inputProps={{
                                        id: "start_date",
                                        type: "date",
                                        name: "start_date",
                                        autocompleate: "off",
                                        label: ('Start Date'),

                                    }}
                                    onChange={handleInputarray}
                                />
                            </div>
                            <div className='col-sm-2 col-md-2'>
                                <InputField
                                    inputProps={{
                                        id: "end_date",
                                        type: "date",
                                        name: "end_date",
                                        autocompleate: "off",
                                        label: ('End Date'),

                                    }}
                                    onChange={handleInputarray}
                                />
                            </div>
                            <div className='col-sm-2 col-md-2'>
                                <label>Select Bank</label>
                                <MultiSelect
                                    options={financers}
                                    placeholder={"Banks"}
                                    name={'financier_id'}
                                    label="Bank"
                                    optionLabel="name"
                                    optionValue="id"
                                    values={filterData.financier_id}
                                    onChange={handleChange}
                                    isMulti
                                />
                            </div>
                            <div className='col-sm-2 col-md-2'>
                                <label>Select Booking Point</label>
                                <Select
                                id="product_type"
                                onChange={(e) => handleSelectBox('booking_point', e)}
                                options={payout_points}
                                label={"Booking Point"}
                                name="booking_point"
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                            />
                            </div>
                            <div className="col-sm-2 col-md-4 text-right" >
                                <div style={{marginTop: "25px"}}>
                                <button type="submit" className="btn btn-primary mrg-r10" onClick={() => getPayoutPoints(1)}>{('Search')}</button>
                                <button type="reset" className="btn btn-reset"
                                    onClick={resetFilterForm}
                                >{('Reset')}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="text-right">
                            <NavLink to="/payout/addpayoutpoint" className="btn btn-success">
                                {('Add Payout points')}
                            </NavLink>
                        </div>

                <div className='clearfix'>
                    <div className="card">
                        <div className='table-responsive '>
                            <table className='table table-bordered' >
                                <thead>
                                    <tr>
                                        <th>{('ID')}</th>
                                        <th>{('Bank')}</th>
                                        <th>{('% Payout')}</th>
                                        <th>{('Booking Point')}</th>
                                        <th>{('Start Date')}</th>
                                        <th>{('End Date')}</th>
                                        <th>{('Status')}</th>
                                        <th>{('Action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {JSON.stringify(payout_points_list)} */}
                                    {payout_points_list && payout_points_list.length > 0 && payout_points_list.map((point) => (


                                        <tr key={point.id}>
                                            <td>{point.id}</td>
                                            <td>{financers && financers.length > 0 && point.financier_id && financers.filter(bank => bank.id == point.financier_id)[0]?.name}</td>
                                            <td>{point.payout_per}</td>
                                            <td>{payout_points.filter((points) => points.id == point.point_id)[0]?.name}</td>
                                            <td>{DateFormate(new Date(point.start_date), "dS mmm yyyy")}</td>
                                            <td>{DateFormate(new Date(point.end_date), "dS mmm yyyy")}</td>
                                            <td>{point.status==1? "Active":"In-active"}</td>
                                            <td>
                                            <div className="btn-group btn-group-small mrg-r10">
                                            <Link to={`/payout/edit/${point.id}`} className="btn btn-default"><i className="ic-createmode_editedit icons-normal"></i></Link>
                                            </div>
                                            <div className="btn-group btn-group-small mrg-r10">
                                                <button className="btn btn-default" onClick={()=>deletePayoutPoint(point.id)}>Remove</button>
                                                </div>
                                                </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan={6}> <Pagination pageCount={paginationdata && paginationdata.totalpage ? paginationdata.totalpage : 0} activePage={filterData.page_no ? filterData.page_no : 1} onPaginate={onPaginate} /></td>
                                        <td>Total: {paginationdata && paginationdata.totalrecords ? paginationdata.totalrecords : 0} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}




const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('dealer')(connect(mapStateToProps, mapDispatchToProps)(BookingPoint));





