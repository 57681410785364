import { gql } from "@apollo/client";

export const PAYOUT__LIST_WITH_FILTERS = gql`
  query getDisbursalLeads(
    $filter_params: LeadsPayoutFiltersArgs!
    $page_no: Float!
  ) {
    getDisbursalLeads(filter_params: $filter_params, page_no: $page_no) {
      disbursals {
        id
        lead_id
        app_id

        customer_name
        disbursal_date
        disbursal_amount
        partner_id
        paid_status
        payout_paid
        payout_pending
        payout_total
        pat_total
        tds_total
        product_type
        financer_id
        fulfillment_type
        payouts {
          id
          scheme_id
          payout_type
          payout_per
          payout_amount
          tds
          tds_per
          pat
          paid_status
          paid_per
          created_at
          created_by
          updated_by
          comment
          scheme {
            scheme_name
          }
        }
      }
      pagination {
        totalpage
        totalrecords
        nextpage
        prevpage
      }
      total_count
    }
  }
`;

export const ADD_PAYOUT = gql`
  mutation savePayoutData($addPayoutArgs: AddPayoutArgs!) {
    savePayoutData(addPayoutArgs: $addPayoutArgs) {
      id
      app_id
    }
  }
`;

export const SEND_FOR_PAYMENT_APPROVAL = gql`
  mutation savePayoutsForPaymentApproval($paymentArgs: AddPaymentArgs!) {
    savePayoutsForPaymentApproval(addPaymentArgs: $paymentArgs)
  }
`;

export const SEND_FOR_PAYMENT_PENDING = gql`
  mutation payment_pending($paymentArgs: AddPaymentArgs!) {
    payment_pending(addPaymentArgs: $paymentArgs)
  }
`;

export const SEND_FOR_PAYMENT_REJECTED = gql`
  mutation payment_rejected($paymentArgs: AddPaymentArgs!) {
    payment_rejected(addPaymentArgs: $paymentArgs)
  }
`;

export const MARKED_AS_PAID = gql`
  mutation payment_paid($paymentArgs: PaymentPaidArgs!) {
    payment_paid(addPaymentArgs: $paymentArgs)
  }
`;

export const PAYOUT_LEADS_COUNTS = gql`
  query {
    leads_count {
      status
      counts
    }
  }
`;

export const PAYOUT_CALCULATION_LAST_MONTH = gql`
  query {
    calculate_base_payout_last_month
  }
`;

export const PAYOUT_CALCULATION_CURRENT_MONTH = gql`
  query {
    calculate_base_payout_current_month
  }
`;

export const PAYOUT_LEADS_EXPORT = gql`
  query payouts_export($filter_params: LeadsPayoutFiltersArgs!) {
    payouts_export(filter_params: $filter_params)
  }
`;
export const FILE_URL_UPLOAD = gql`
  mutation upload_payment($uploadPaymentArgs: UploadPaymentArgs!) {
    upload_payment(uploadPaymentArgs: $uploadPaymentArgs)
  }
`;

export const GET_PAYOUT_POINTS_WITH_FILTERS = (
  page_no,
  financer_id,
  lead_status,
  start_date,
  end_date
) => gql`
   {
    payout_points_list(page_no:${page_no}, point_id:${lead_status}, start_date:${start_date}, end_date:${end_date} ){
        data{
        id
        financier_id
        payout_per
        point_id
        start_date
        end_date
        status
      }
      pagination {
        totalpage
        totalrecords
        nextpage
        prevpage
        page_no
      }
    }
}
`;

export const SAVE_PAYOUT_POINT = gql`
  mutation save_payou($addPayoutpointsArgs: AddPayoutPointsArgs!) {
    save_payout_points(addPayoutpointsArgs: $addPayoutpointsArgs) {
      status
      msg
      data {
        id
        financier_id
        start_date
        end_date
        payout_per
        status
      }
    }
  }
`;

export const DELETE_PAYOUT_POINT = (id) => gql`
{
  delete_payoutpoint(id:${id})
}`;

export const DETAILS_PAYOUT_POINT = (id) => gql`
{
  payoutpoint_details(id:${id}){
       id
       financier_id
       payout_per
       point_id
       
       start_date
       end_date
       status
  }
}
 `;
export const PARTNER_DISBURSAL_LIST = gql`
  query partner_disbursals($filter_params: LeadsPayoutFiltersArgs!) {
    partner_disbursals(filter_params: $filter_params, page_no: 1) {
      disbursals {
        partner_id
        partner_name
        partner_company
        partner_city
        total_disbursal_amount
        total_payout_amount
        total_pat
        total_tds
        total_payout_per
        pending_payout
        paid_payout
      }
      pagination {
        totalpage
        totalrecords
        nextpage
        prevpage
      }
      total_count
    }
  }
`;
export const INVOICE_LISTING_DATA = (invoice_id) => gql`
{
  invoice_payouts(invoice_id: ${invoice_id}, page_no: 1) {
    id
    invoice_id
    payout_amount
    tds
    tds_per
    created_by
    created_at
    products
    bank_name
    bank_logo
    payout_type
    pat
    sgst_amount
    cgst_amount
    igst_amount
    payouts {
      id
      range_id
      financer_group_id
      point_id
      approved_at
      approved_by
      payout_per
      financer_group_id
      range_id
      pat
      payout_amount
      payout_type
      gst_amount
      payout_per
      tds_per
      tds
      comment
      partner_id
      scheme {
        scheme_name
        id
        __typename
      }
      partnerleads {
        partner_id
        lead_id
        tranche_id
        disbursal_date
        disbursal_amount
        customer_name
        product_type
        financer_id
        __typename
      }
      __typename
    }
    __typename
  }
}
`;

export const NOTES_LISTING_DATA = (invoice_id) => gql`
query { 
    fetch_notes_by_invoice(invoice_id: ${invoice_id}) { 
    status
    msg
    data{
        invoice_no
        id
        payout_amount
        invoice_type
        created_at
        approved_invoice_url
        pending_invoice_url
        comment
        address
        invoice_address
        invoice_date
        invoice_approval
        partner_address
        leads{
            customer_name
            disbursal_amount
            disbursal_date
            financer_id
            lead_id
            product_type
        }
    }
}
}
`;

export const SEND_INVOICE_OTP = gql`
query SendInvoice(
  $partner_id: Float!
  $invoice_id: Float!
  $mobile: Float!
  $hash: String!
) {
  send_invoice_otp(partner_id: $partner_id, invoice_id: $invoice_id, mobile: $mobile, hash: $hash ){
    status
    msg
  }
}
`;


export const CREATE_INVOICE = gql`
  mutation create_invoice($createinvoiceargs: CreateInvoiceArgs!) {
    create_invoice(createinvoiceargs: $createinvoiceargs)
  }
`;

export const INVOICE_LISTING = () => gql`
  query invoice_list($filter_params: LeadsPayoutFiltersArgs!) {
    invoice_list(filter_params: $filter_params, page_no: 1) {
      invoices {
        id
        invoice_no
        partner_id
        partner_name
        payout_amount
        paid_status
        tds
        tds_per
        pat
        partner_status
        partner_city
        total_lead
        created_by
        created_at
        approved_by
        approved_at
        pending_invoice_url
        approved_invoice_url
        paid_date
        bankdetails {
          beneficiary_name
          bank_account_no
          upi_id
          bank_id
          bank_ifsc_code
        }
      }

      pagination {
        totalpage
        totalrecords
        nextpage
        prevpage
      }
      total_count
    }
  }
`;

export const EXPORT_CSV_PENDING_PAYMENT = gql`
  {
    export_payment_pending_invoice
  }
`;

export const GET_PAID_INVOICE_DATA = gql`
  query partner_eligible_leads($filter_params: LeadsPayoutFiltersArgs!) {
    partner_eligible_leads(filter_params: $filter_params, page_no: 1) {
      leads {
        
        lead_id
        customer_name
        products
        total_disbursal_amount
        total_projected_amount
        total_projected_booster_amount
        bank_name
        total_tds
        financer_id
        bank_logo
        payouts {
          payout_id
          partner_id
          tranche_id
          lead_id
          lead_disburse_id
          payout_type
          scheme_id
          financer_group_id
          range_id
          customer_name
          disbursal_amount
          disbursal_date
          payout_per
          payout_amount
          paid_payout
          inprocess_payout
          products
          confirm_payout
          partner_id
        }
      }
      pagination {
        totalpage
        totalrecords
        nextpage
        prevpage
      }
      projection_text
    }
  }
`;

export const GET_PENDING_INVOICE_DATA = gql`
query invoice_list($filter_params:LeadsPayoutFiltersArgs!){
  invoice_list(filter_params:$filter_params,page_no:1){
   invoices {
        id
        invoice_no
        partner_name
        payout_amount
        paid_status
        created_at
        paid_date
        tds
        tds_per
        pat
        partner_status
        partner_city
        is_updated_address
        partner_address
       pending_invoice_url
      
       approved_invoice_url
       no_of_credit
       no_of_debit
       payout_per
        bankdetails{
           beneficiary_name
           bank_account_no
           upi_id
           bank_id
           bank_ifsc_code
        }
   }
 
   
   pagination {
     totalpage
     totalrecords
     nextpage
     prevpage
   }
   total_count
 }
 }
`

export const GET_PAYOUT_LEAD_DATA = (lead_id) => gql`
{
   invoice_payouts(invoice_id:${lead_id},page_no:1){
   id
   invoice_id
   payout_amount
   tds
   tds_per
   created_by
   created_at
   products
   bank_name
   payouts{
     approved_at
     approved_by
     payout_per
     financer_group_id
     range_id
     scheme{
       scheme_name
       id
     }
     partnerleads{
       lead_id
       disbursal_date
       disbursal_amount
       customer_name
       product_type
       financer_id
     }
   }
 }
`
export const GET_PAYOUT_POINTS = gql`
  query payout_points_list($filter_params: PayoutPointsFilterArgs!) {
    payout_points_list(filter_params: $filter_params) {
      data {
        id
        financier_id
        payout_per
        start_date
        end_date
        status
        product_type
        payoutpointgroup {
          points{
              booking_point
          }          
        }
        __typename
      }
      pagination {
        totalpage
        totalrecords
        nextpage
        prevpage
        page_no
        __typename
      }
      __typename
    }
  }
`;


export const GET_GROUP_PAYOUT_DETAILS = (lead_id, tranche_id, range_id, payout_id, partner_id) => gql`
  { group_payout_details(lead_id:${lead_id}, tranche_id:${tranche_id},range_id:${range_id}, payout_id:${payout_id}, partner_id:${partner_id}){
  
  points{
      point
      paid_per
      payout_amount
      payout_per
      status
  }
 boosters{
  title
  payout_per
  range_id
  financer_group_id
 }
 nextboosters{
  title
  payout_desc
 }
}
}
`;

export const Payout_Calculator = (amount, fullfillment_type, partner_id, product_type) => gql`
query{ 
   payout_calculator(
    amount: ${amount},
     fullfillment_type:"${fullfillment_type}", 
     partner_id: ${partner_id},
      product_type: "${product_type}"
      ){
     financer_group_id
      scheme_name
      scheme_for
      start_date
      end_date
      is_default
      scheme_type
      scheme_for
      fulfillment_type
      banks
      payout_amount
      pay_per    
      banks_name 
  }
}
`
export const BankLogo = gql`
query{
  masterdata(api_called_by:partner){
      bank_list{
          id
        banklang{
          lang_id
          bank_id
          bank_name
        }
              logo
  
        }
    }
    }
`
export const SCHEME_LIST = (partner_id) => gql`
{  partner_schemes(partner_id:${partner_id}){      
  id      
  scheme_name      
  scheme_for      
  start_date      
  end_date      
  is_default      
  scheme_type      
  scheme_for      
  fulfillment_type      
  is_default      
  hl_payout_points_url      
  lap_payout_points_url   
  no_of_cases   
  scheme_tnc      
    banners{        
      banner_url
    } 
    schemefinacergroup{
            id        
            products        
            loan_type        
            is_on_payin        
            schemerange{           
            id           
            financer_group_id           
            start_range           
            end_range           
            payout           
            is_deleted                  
            }         
            schemepartnerrange{            
            id            
            financer_group_id            
            partner_id            
            range_id            
            payout            
            is_deleted        
            }        
            schemebanks{            
            id            
            financer_id            
            is_deleted        
            }        
            sub_partner_ranges{
                        sub_partner_id            sub_partner_name            user_type            is_payout_applicable            
                        schemeranges{                
                        id                financer_group_id                start_range                end_range                
                        payout                
                        is_deleted             
                        }        
                        }     
                        }  
                        }
                        }
`
export const SCHEME_BANK_LIST = gql`
{
   dsa_list(bank_name:""){
      bank_name
      dsa_code
      region
      product_type
  }
}
`




