import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FINEX_BACKEND_API_KEY, API_URL } from "../../config/constant";

const ApproveInvoiceOtp = ( { invoice_id, closeModal } ) => { 
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [otpApproval, setOtpApproval] = useState(false);
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const inputs = useRef([]);
  
  const validateOtp = async () => { // console.log("===========>>>>", `${API_URL.GETWAY_API}payout/api/v1/graphpql`)
    const enteredOtp = otp.join('');
    const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));    
    const partnetId = loginUserInfo.user_data.dealer_id;
    try {
      const response = await fetch(`${API_URL.GETWAY_API}payout/api/v1/graphpql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'apiKey': FINEX_BACKEND_API_KEY,
        },
        body: JSON.stringify({
          query: `{
            verify_invoice(
              partner_id: ${partnetId}, 
              invoice_id: ${invoice_id}, 
              otp: ${enteredOtp},
            ) {
              status
              msg
            }
          }`,
        }),
      });
  
      const result = await response.json(); console.log(result);
  
      if (response.ok && result.data?.verify_invoice?.status) {
        alert("OTP validated successfully!");
        return true;
      } else {
        alert(result.data?.verify_invoice?.msg || "Invalid OTP");
        return false;
      }
    } catch (error) {
      console.error("Error validating OTP:", error);
      alert("An error occurred while validating the OTP.");
      return false;
    }
  };
  
  const handleOtpApproval = async () => {
    const enteredOtp = otp.join('');
    if (enteredOtp.length === 6) {
      const isValidOtp = await validateOtp();
      if (isValidOtp) {
        // Proceed to update the payment status
        try {
          const query = `
            mutation update_invoice_status($UpdateInvoiceStatus: UpdateInvoiceStatus!) {
              update_invoice_status(UpdateInvoiceStatus: $UpdateInvoiceStatus) {
                status
                msg
              }
            }
          `;
  
          const variables = {
            UpdateInvoiceStatus: {
              id: Number(invoice_id),
              paid_status: "payment_pending",
            },
          };
  
          const response = await fetch(`${API_URL.GETWAY_API}payout/api/v1/graphpql`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              "apiKey": FINEX_BACKEND_API_KEY,
            },
            body: JSON.stringify({ query, variables }),
          });
  
          const result = await response.json();
          console.log(result);
          if (result.data?.update_invoice_status?.status) {
            alert("Payment status updated successfully!");
            setOtpApproval(true);
  
            // Now, update the invoice_approval column
            const approvalQuery = `
              mutation updateInvoiceApproval($updateInvoiceApprovalInput: UpdateInvoiceApprovalInput!) {
                updateInvoiceApproval(updateInvoiceApprovalInput: $updateInvoiceApprovalInput)
              }
            `;
  
            const approvalVariables = {
              updateInvoiceApprovalInput: {
                id: Number(invoice_id),
                invoice_approval: 1, // Assuming 1 means approved
              },
            };
  
            const approvalResponse = await fetch(`${API_URL.GETWAY_API}payout/api/v1/graphpql`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'apiKey': FINEX_BACKEND_API_KEY,
              },
              body: JSON.stringify({
                query: approvalQuery,
                variables: approvalVariables,
              }),
            });
  
            const approvalResult = await approvalResponse.json();
            console.log(approvalResult);
  
            if (approvalResponse.ok && approvalResult.data?.updateInvoiceApproval) {
              alert("Invoice approval status updated successfully!");
            } else {
              alert("Failed to update invoice approval status.");
            }
          } else {
            alert(`Failed to update payment status: ${result.data?.update_invoice_status?.msg || "Unknown error"}`);
          }
        } catch (error) {
          console.error("Error updating payment status:", error);
          alert("An error occurred while updating the payment status.");
        }
      }
    } else {
      alert("Please enter a valid OTP.");
    }
  };
  

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (value.match(/[0-9]/)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      if (index < otp.length - 1) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace') {
      const updatedOtp = [...otp];
  
      if (otp[index] === '' && index > 0) {
        inputs.current[index - 1].focus();
        updatedOtp[index - 1] = ''; // Clear the previous input value
      } else {
        updatedOtp[index] = ''; // Clear the current input value
      }
  
      setOtp(updatedOtp);
    }
  };

  const startResendTimer = () => {
    setTimer(60);
    setCanResend(false);

    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          setCanResend(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleResendOtp = () => {
    if (canResend) {
      alert("OTP resent!");
      startResendTimer();
      
      // Reset OTP fields to blank (all 6 OTP inputs)
      setOtp(Array(6).fill(''));
      
      // Optionally, focus the first OTP field
      inputs.current[0].focus();
    }
  };

  const handleBackClick = () => {
    closeModal();
};

  useEffect(() => {
    startResendTimer();
  }, []);

  let backTo = `/my-saathi-earnings`;

  return (
    <>
      {!otpApproval ? (
        <>
          <div className="approvecard">            
              <div>
                <img
                  src={require('../../../src/saathi-web/images/left arrow.svg')}
                  alt="back"
                  onClick={handleBackClick}
                  style={{ cursor: "pointer" }}
                />
              </div>
            <div>
              Credit/Debit Note - <span>0223</span>
            </div>
          </div>

          <div className="otpcenter">
            <img
              src="https://ambak.storage.googleapis.com/partner/4968/1731913416361.png"
              alt="otp"
              width={100}
              height={100}
            />
            <h4 style={{ marginTop: 10 }}>Share Verification Code</h4>
            <p>Code sent via SMS to approve and e-sign Invoice No. 0233</p>

            <div className="otp-container">
              {[...Array(6)].map((_, index) => (
                <input
                  id={`${index}_otp`}
                  key={index}
                  ref={(input) => (inputs.current[index] = input)}
                  type="number"
                  value={otp[index]}
                  maxLength="1"
                  autoComplete="off"
                  min="0"
                  max="6"
                  onChange={(e) => handleInputChange(e, index)}
                  onKeyDown={(e) => handleBackspace(e, index)}
                />
              ))}
            </div>
            <div className="resendotp" onClick={handleResendOtp}>
              {canResend ? "Resend OTP" : `Resend OTP in ${timer}s`}
            </div>
            <div>
              <button className="generateinvoice" onClick={handleOtpApproval} style={{ width: 300 }}>
                Confirm
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="otpcenter" style={{ marginTop: 50 }}>
          <img style={{ marginBottom: 50 }} src='https://ambak.storage.googleapis.com/partner/4980/1731920484175.png' alt='approve' width={200} height={80} />
          <h4 style={{ marginTop: 10 }}>Debit/ Credit Note Approval Successful</h4>
          <p>Your credit/debit note has been approved and emailed to you on your registered E-mail ID.</p>
          <Link to={backTo}>
            <button className="generateinvoice" style={{ width: 300 }}>
              Go to My Earnings
            </button>
          </Link>
        </div>
      )}
    </>
  );
};

export default ApproveInvoiceOtp;
