import { API_URL } from "../../config/constant";
import MasterService from "../../service/MasterService";
import { getUserInfo } from "./user.utility";
import { FINEX_BACKEND_API_KEY } from "../../config/constant";

export const getBankList = async () => {
  const userInfo = getUserInfo();
  const { token } = userInfo;
  const query = `
query BankList($api_called_by: API_CALL_BY!, $is_partnership: String!) {
  masterdata(api_called_by: $api_called_by, is_partnership: $is_partnership) {
    bank_list {
      id
      banklang {
        lang_id
        bank_id
        bank_name
        __typename
      }
      __typename
    }
    __typename
  }
}
`;

  const variables = {
    api_called_by: "sfa",
    is_partnership: "0",
  };

  try {
    const response = await fetch(`${API_URL.GETWAY_API}finex/api/v1`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "apikey": FINEX_BACKEND_API_KEY || "d77bc90f-2311-47da-9980-2930de73-ambak-finex-beta",
        app_type: "SFA",
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const data = await response.json();
   // console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching bank list:", error);
  }
};


export const fetchProfessionList = async () => {
  try {
      const result = await MasterService.get('core/commonservice/master?master=professions_list');
      return result?.data?.data?.professions_list;
  } catch (error) {
      console.log("Failed to fetch profession list", error);
  }
}