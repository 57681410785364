import React, { useEffect, useRef, useState } from "react";
import { PanCardKyc } from "./Components/PanCardKyc.component";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { getUserInfo, postApiUtility } from "../Utility/user.utility";
import { toast } from "react-toastify";
import BankDetailsKyc from "./Components/BankDetailsKyc.component";
import { fetchProfessionList, getBankList } from "../Utility/partnerOnBordingUtility";
import UserInfoKyc from "./Components/UserCompanyInfo/UserInfo.component";
import BankVerification from "./Components/BankVerification";
import KycDocPicker from "./Components/KycDocPicker";
import { PartnerOnBordingContext } from "../PartnerContextApi/PartnerOnBordingContext";
import { Link } from "react-router-dom";
import { useCleverTap } from '../CleverTapContext';

const LoginKyc = () => {
  const history = useHistory();
  const [showNext, setShowNext] = useState(1);
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false)
  const steps = ['Complete KYC', 'Step 2'];
  const [bankList, setBankList] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const [professionList, setProfessionList] = useState([]);
  const [error, setError] = useState({});
  const [isBankVerified, setIsBankVerified] = useState(false);
  const [isCompanyInfo, setIsCompanyInfo] = useState(false);
  const [isTeamMember , setIsTeamMember] = useState(false)
  const [previousemail , setPreviousEmail] = useState("");
  const trackEvent = useCleverTap();
  const [kycData, setKycData] = useState({
    step: 1,
    pan_number: '',
    pan_card_file_name: null,
    pan_card_file: null,
    applicant_type: '',
    pan_card_url: null,
    is_pan_verified: 2,
    isPanKycCompleted: false,
    isPanVerified: false,
    docs: []
  });

  const kycDataRef = useRef(kycData);
  kycDataRef.current = kycData;
  const saveBankRef = useRef(null);
  const companyInfoRef = useRef();
  const userInfoRef = useRef();

  useEffect(async () => {
    const user_info = JSON.parse(localStorage.getItem("loginUserInfo"));
    if (user_info?.user_type === 61 || (user_info?.user_type === 60  && user_info?.user_data?.is_payout_applicable === 0 || user_info?.user_data?.name !== "") )
      {
          window.location.href = "/saathi-leads"; 
      
        return;
      }
    const bankList = await getBankList();
   
    setBankList(bankList.data.masterdata.bank_list);
    const profession = await fetchProfessionList();
    setProfessionList(profession)
  },[]);

  const ShowNextKyc = () => {    
    if(showNext === 1) {
      trackEvent("cta_clicked", "onboarding_pan_validation");
      if(!kycData?.pan_number?.length > 0) {
        toast.error('Please enter correct Pan no first to proceed');
        setError({...error, pan_number: true});
      } else {
        setShowNext(showNext + 1);
        saveUserData();
      }
    } else if (showNext === 2) {
      trackEvent("cta_clicked", "bank_details_step2_submit");
      if (companyInfoRef.current && isCompanyInfo) {
        const hasCompanyError = companyInfoRef?.current?.errorHandler(kycData);
        if(!hasCompanyError) {
          setKycData((prevState) => ({
            ...prevState,
            isPanKycCompleted : true,
            }));
          if(!error.email) {
            saveUserData(); // next step handled under save user data
          }
        }
      } else if(userInfoRef.current && !isCompanyInfo) {
        const hasUserError = userInfoRef?.current?.errorHandler(kycData);
        if(!hasUserError) {
          setKycData((prevState) => ({
            ...prevState,
            isPanKycCompleted : true,
            }));
          if(!error.email) {
            saveUserData(); // next step handled under save user data
          }
        }
      } else {
        toast.error('Something went wrong');
      }
    } else if (showNext === 3) {
      if(!kycData?.maskedAadhaar && error?.aadhar_no) {
        toast.error('Please enter correct Aadhar no first to proceed');
      } else if(error.aadharFront) {
        toast.error('Please upload Aadhar card front');
      } else if(error.aadharBack) {
        toast.error('Please upload Aadhar card back');
      } else if (!kycData?.pan_card_url?.length > 0) {
        toast.error('Please upload Pan card first to proceed');
        setError({...error, pan_card_file: true});
      } else {
        saveUserData();
        setCurrentStep(currentStep + 1);
        setShowNext(showNext + 1);
      }
    } else if (showNext === 4) {
      if (saveBankRef.current && typeof saveBankRef.current.saveBank === 'function') {
        const {bankDetails, isError} = saveBankRef.current.saveBank();
        if(bankDetails?.account_number && !isError) {
          setBankDetails(bankDetails);
          setIsLoading(false);
          setShowNext(showNext + 1);
        }
      }
    }

    // if(showNext !== 4) {
    //   setShowNext(showNext + 1);
    // }
  };


  const handleOnChange = (e) => {
   // console.log("onChange called", e.target);
    const {id='', value='', files=[]} = e?.target;
    if(id === 'pan_card') {
      setKycData( (prevState) => ({
         ...prevState,
         pan_number: value
        }))
    } else if (id === 'gst_number') {
      setKycData( (prevState) => ({
         ...prevState,
          is_gst: 1,
          gst_number: value
        }))
    } else if (id === 'msme_number') {
      setKycData( (prevState) => ({
         ...prevState,
          is_msme: 1,
          msme_number: value
        }))
    } else {
      setKycData((prevState) => ({
         ...prevState,
          [id]: value,
      }))
    }
  };

  const saveUserData = async () => {
    const isPanSkiped = localStorage.getItem('is_pan_skipped');
    let user_type ; // Default user_type to 1
   // console.log ("++++++++++++++++++++++++++++++++++++>>>>>>>>>>>>>>>>>>>>>>>>>>" ,  kycData)
    if (kycData?.applicant_of === "company") {
       user_type = "2";
    } else if (kycData?.applicant_of === "individual") {
       user_type = "1";
    } else if (kycData?.typeOfHolder !== 'Individual or Person') {
       user_type = "2";
    } else {
      user_type = "1";
    }
    
    setIsLoading(true);
    try {
      if (kycData?.email) {
        setPreviousEmail(kycData?.email)
      }
      const userInfo = getUserInfo();
      const { user_id, dealer_id, mobile, user_type  } = userInfo;
      const params = {
        // ...kycData,
        user_id: user_id,
        partner_id: dealer_id,
        firstName: kycData.name,
        name: kycData.name,
        email: kycData?.email || previousemail,
        mobile: mobile,
        // is_pan_verified: 2,
        is_pan_skipped: isPanSkiped ? '1' : '0',
        pan_number: kycData?.pan_number,
        birth_date: kycData?.dob,
        term_condition: true,
        profession_id: 1,
        source: "Sathi Web",
        step: 1,
        updated_by: 0,
        is_mobile_verified: 1,
        // sfa_referral_user_id : kycData?.sfa_referral_user_id ?? "",
        aadhar_no: kycData?.maskedAadhaar ?? kycData?.aadhar_no,
        is_pan_verified: kycData?.is_pan_verified ?? 2,
        term_condition: true,
        is_aadhar_pan_linked: kycData?.maskedAadhaar?.length > 0 ? 1 : 0,
        user_type: user_type + '' ?? '1',
        applicant_type_id:Number(kycData.applicant_of) ||  Number(kycData?.applicant_type) || "1",
        pincode:kycData.pincode,
        
        docs: [
          ...kycData.docs,
        ]
      }
      if (kycData.yodha_mobile) {
        params.referral_code = kycData.yodha_mobile
      }
      if(kycData.sfa_user_id) {
        params.sfa_user_id = Number(kycData?.sfa_user_id) 
      }
      if (kycData?.gst_number) {
        params.gst_number = kycData?.gst_number
      }
      if (kycData?.gst_required === true ){
        params.is_gst = kycData?.gst_required === true ? "1" : "0"
      }
      // Conditionally add properties in case of company""
      if (!kycData?.typeOfHolder === 'Individual or Person') {
        params.spoc_name = kycData?.spoc_name ?? '';
        params.spoc_mobile = kycData?.spoc_mobile ?? '';
        params.spoc_email = kycData?.spoc_email ?? '';
        params.spoc_designation = kycData?.spoc_designation ?? 0;
        params.company_type = kycData?.company_type ?? 0;
        params.is_msme = kycData?.is_msme ?? 0;
        params.msme_number = kycData?.msme_number ?? '';
        params.is_gst = kycData?.is_gst ?? 0;
        params.gst_number = kycData?.gst_number ?? '';
      }
      if ( kycData?.applicant_of === "company") {
        params.spoc_name = kycData?.spoc_name ?? '';
        params.spoc_mobile = kycData?.spoc_mobile ?? '';
        params.spoc_email = kycData?.spoc_email ?? '';
        params.spoc_designation = kycData?.spoc_designation ?? 0;
        params.company_type = kycData?.company_type ?? 0;
       
      }

      const response = await postApiUtility('partner/partner/save-basic-details', params);
      // console.log("resonce from save-basic-details---", response);
      if (response?.data?.status === 200) {
        setIsLoading(false);
         if (showNext === 2) {
            setCurrentStep(currentStep + 1);
            setShowNext(showNext + 1);
            modifyLocalUserData(kycData.name)
          }
          // setShowNext(showNext + 1);
      } else {
        handleErrorResponse(response);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  const modifyLocalUserData = (newName) => {
    try {
      let localUser = JSON.parse(localStorage.getItem('loginUserInfo'));
    localUser = {
      ...localUser,
      name: newName ?? '',
      user_data: {
        ...localUser?.user_data,
        name: newName ?? '',
        user_type: '1',
      }
    }
    localStorage.setItem('loginUserInfo', JSON.stringify(localUser));
    } catch (error) {
      console.log("error inside modifyLocalUserData");
    }
   }

  const handleErrorResponse = (response) => {
    const message = response?.data?.message;
    if (message && Array.isArray(message)) {
      message.forEach((msg) => {
        if(msg.includes('Email')) {
          setError({...error, email: true})
        }
        toast.error(msg);
      });
    }
  };

  const goBack = () => {
    // if (showNext === 2) {
      trackEvent("cta_clicked", "kyc_step1_back_button");
      setCurrentStep(currentStep - 1);
      setShowNext(showNext - 1);
    // }
  };

  const handleSkipPan = () => {    
    if(showNext ===1) 
      {
        trackEvent("cta_clicked", "kyc_step1_skip");
        setShowNext(2)
        return;
      }
      trackEvent("cta_clicked", "bank_details_step2_skip");
    localStorage.setItem('is_pan_skipped', true);
    saveUserData();
    setTimeout(() => {
      window.location.href = "/saathi-leads"; 
    }, 500);
  };

  const goBackToBankForm = () => {
    setIsBankVerified(false);
    setShowNext(showNext - 1);
  };

  const props = { 
    error,
    setError,
    userInfoRef,
    companyInfoRef,
    kycData, 
    showNext,
    setKycData, 
    isLoading, 
    setIsLoading, 
    handleOnChange, 
    bankList,
    professionList,
    isBankVerified,
    setIsBankVerified,
    goBackToBankForm,
    setIsCompanyInfo,
  }

  return (
    <>
    <PartnerOnBordingContext.Provider value={props}>
      <div>
        {showNext === 1 || showNext === 3 || showNext === 4 ? (  
            <>
              <div className="kycskip" onClick={handleSkipPan}>
                Skip
              </div>
            </>
          ) :null}

          {showNext === 2 || showNext === 3 ? (
          <div className="backshow" onClick={goBack}>
            <img
                src="https://ambak.storage.googleapis.com/partner/1886/1720781007726.svg"
                alt="doc"
                width={24}
                height={16}
              >
          </img>
          </div>
          ) : null}

        {showNext === 1 || showNext === 3 ? (
          <>
            <div className="progress-container">
              <div className="loginsteps">
                <div>Steps 1 : Complete KYC</div>
              </div>
              <div className="loginprogress-bar">
                <div
                  className="loginprogress"
                  style={{ width: `${(currentStep / 3) * 100}%` }}
                ></div>
              </div>
            </div>
          </>
        ) : null}
        {showNext === 4 ? (
          <>
            <div className="progress-container">
              <div className="loginsteps">
                <div>Steps 2 : Add Bank Details</div>
              </div>
              <div className="loginprogress-bar">
                <div
                  className="loginprogress"
                  style={{ width: `${(currentStep / 2) * 100}%` }}
                ></div>
              </div>
            </div>
          </>
        ) : null}
    
        
        {showNext === 2 && (
          <>
            {/* <MainInfoKyc /> */}
            <UserInfoKyc/>
          </>
        )}

        {showNext === 1 || showNext === 3 ? (
          <>
            <h5>Documents</h5>
            <div className="kycsubheading">
              Complete your KYC process to ensure timely payouts
            </div>
          </>
        ) : null}
        {showNext === 4 ? (
          <>
            <h5>Bank Details</h5>
            <div className="kycsubheading">
              Bank details are critical to ensure payouts are done to the
              correct account
            </div>
          </>
        ) : null}
        {showNext === 1 && (
          <PanCardKyc/>
        )}

        {showNext === 3 && (
          <>
            {/* <GstCertificate /> */}
            <KycDocPicker/>
          </>
        )}

        {showNext === 4 && (
          <>
            {/* <LoginBankDetails /> */}
            <BankDetailsKyc 
              ref={saveBankRef}
              onChange={handleOnChange} 
              setKycData={setKycData} 
              setIsLoading={setIsLoading}
              bankList={bankList}
            />
          </>
        )}

        {showNext === 5 && (
          <>
            {/* <LoginVerifiedBank /> */}
            <BankVerification 
              bankDetails={bankDetails}
            />
          </>
        )}
        {showNext !== 5 && (
          <>
            <div className="bottonfix">
              <button
                type="submit"
                className="btn btn-primary btn-lg"
                id="send-otp"
                onClick={ShowNextKyc}
                disabled={isLoading}
              >
                Next
              </button>
            </div>
          </>
        )}
      </div>
    </PartnerOnBordingContext.Provider>
    </>
  );
};

export default LoginKyc;
