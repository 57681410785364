import React, { useEffect, useState } from "react";
import LoanAllInfo from "./LoanAllInfo";
import { executeGraphQLQueryFinex } from "../../../../common/executeGraphQLQuery";
import { GET_MASTER_DATA } from "../../../../queries/sangam/masterdata.gql";
import { toast } from "react-toastify";
import LoanOfferHome from "./LoanOfferHome";
import { useCleverTap } from '../../../CleverTapContext';

const LoanOffer = ({ setHome, leadDetail = {} }) => {
  const [showNext, setShowNext] = useState(1);
  const [masterdata, setMasterData] = useState([]);
  const [offerVisibility, setOfferVisibility] = useState(false);
  const [breData, setBreData] = useState({});
  const trackEvent = useCleverTap();

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    if (Object.keys(leadDetail).length) {
      const { id, lead_details, customer, co_applicant } = leadDetail;
      const existingData = {
        lead_id: id || null,
        loan_amount: lead_details?.loan_amount,
        profession: lead_details?.profession,
        company_name: lead_details?.company_name,
        company_type: lead_details?.company_type,
        work_experience: lead_details?.work_experience,
        cibil_score: lead_details?.cibil_score,
        is_property_identified: lead_details?.is_property_identified,
        expected_property_value: lead_details?.expected_property_value,
        property_type: lead_details?.property_type,
        property_sub_type: lead_details?.property_sub_type,
        property_city: lead_details?.property_city,
        property_state: lead_details?.property_state,
        agreement_type: lead_details?.agreement_type,
        monthly_salary: lead_details?.monthly_salary,
        gross_monthly_salary: lead_details?.gross_monthly_salary,
        property_agreement_value: lead_details?.property_agreement_value,
        first_name: customer?.first_name,
        last_name: customer?.last_name,
        mobile: customer?.mobile,
        business_vintage: customer?.business_vintage_years,
        industry: customer?.industry,
        customer_id: customer?.customer_id,
        is_additional_income: customer?.is_additional_income,
        additional_income: customer?.additional_income,
        existing_emi_amount: customer?.existing_emi_amount,
        emi_ending_six_month: customer?.emi_ending_six_month,
        income_calculation_mode: customer?.offer_type,
        offer_itr_details: customer?.offer_itr_details,
        no_of_accounts: customer?.no_of_accounts,
        offer_bank_details: customer?.offer_bank_details,
        dob: customer?.dob,
        age: `${calculateAge(customer.dob)}`,
        co_applicant,
        is_co_applicant: co_applicant.length > 0 ? "yes" : "no",
      };
      setBreData(existingData);
    }
  }, [leadDetail]);

  const getMasterData = async () => {
    try {
      const response = await executeGraphQLQueryFinex(GET_MASTER_DATA());
      if (response && response.data && response.data.masterdata) {
        let result = { ...response.data.masterdata };
        let keyList = [
          "tenure",
          "agreement_type",
          "profession",
          "salary_credit_mode",
          "property_type",
          "usage_type",
          "loan_type",
          "loan_sub_type",
        ];

        for (let item of keyList) {
          if (result[item] && result[item].length) {
            result[item] = result[item].map((data) => ({
              ...data,
              value: +data.id,
              label: data.label,
            }));
          }
        }

        if (result.bank_list && result.bank_list.length) {
          result.bank_list = result.bank_list.map((data) => ({
            ...data,
            value: data.id,
            label: data?.banklang?.bank_name || "",
          }));
        }
        setMasterData(result);
      }
    } catch (error) {
      console.error("Error fetching master data:", error.message);
      toast.error("Something went wrong, please try again");
    }
  };

  const handleNext = () => {
    setShowNext((prev) => prev + 1);
  };

  const handlePre = () => {
    trackEvent("cta_clicked", "offer_page_back_button");
    if (offerVisibility) return setOfferVisibility(false);
    if (showNext !== 1) {
      setShowNext(showNext - 1);
    }
    if (showNext == 1) {
      setHome(false);
    }
  };

  useEffect(() => {
    getMasterData();
  }, []);

  useEffect(() => {
    if (!Object.keys(leadDetail).length && showNext === 1) {
      localStorage.setItem("showLeadCreatePopup", true);
    }
  }, [showNext]);

  const handleBankOfferVisibility = (visibility) => {
    setOfferVisibility(visibility);
  };

  const handleSetBreData = (key, value, obj) => {
    if (!(key === null) || !(obj === null)) {
      setBreData((current) => ({
        ...current,
        ...(obj ? obj : { [key]: value }),
      }));
    }
  };

  return (
    <div className="mytoolscard">
      <div className="myloanoffercard">
        <div onClick={handlePre} style={{ cursor: "pointer" }}>
          <img src={require("../../../images/left arrow.svg")} alt="not" />
          Loan Offers Calculator
        </div>

        {showNext === 1 && (
          <LoanOfferHome
            masterdata={masterdata}
            setNextActive={handleNext}
            breData={breData}
            handleBreDataChange={handleSetBreData}
          />
        )}

        {showNext === 2 && (
          <>
            <LoanAllInfo
              showNext={showNext}
              bankOfferVisibility={handleBankOfferVisibility}
              showBankOfferList={offerVisibility}
              leadDetail={leadDetail}
              breData={breData}
              handleBreDataChange={handleSetBreData}
              masterdata={masterdata}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default LoanOffer;
