import React, { useState } from "react";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import LoanDetails from "./LoanDetails";
import CustomerDetails from "./CustomerDetails";
import IncomeDetails from "./IncomeDetails";
import PropertyDetails from "./PropertyDetails";
import PartnerDocument from "../Document/PartnerDocument";
import { LEAD_DETAIL_QUERY,UPDATE_LEAD_STATUS } from "../../queries/sangam/leads.gql";
import { executeGraphQLQueryFinex,executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";

const EditLead = ({leadDetail,masterdata,masterStatusData}) => {
  const [currentStep, setCurrentStep] = useState(1);
 // const [leadDetail,setLeadData] = useState(leadDetail);
  const handleNextClick = () => {
    setCurrentStep((prevStep) => {
      if (prevStep < 5) {
        return prevStep + 1;
      }
      return prevStep;
    });
  };

  const handleStepClick = (step) => {
    setCurrentStep(step);
    leadDetails();
  };

  const leadDetails = async () => {   
   // console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjj");
    const result = await executeGraphQLQueryFinex(LEAD_DETAIL_QUERY(leadDetail.id));
    if(result?.data?.get_lead_detail[0]){
      leadDetail = result?.data?.get_lead_detail[0];
    }
    
  };

  return (
    <>
      <div className="editleadnew">
        <div className="lefteditleadcont">
          <section className="step-wizard">
            <ul className="step-wizard-list">
            {[1, 2, 3, 4, 5].map((stepNumber) => (
          <li
            key={stepNumber}
            className={`step-wizard-item ${currentStep === stepNumber ? 'current-item' : ''}`}
            onClick={() => handleStepClick(stepNumber)}
          >
            <span className="progress-count">{stepNumber}</span>
          </li>
        ))}
            </ul>
            <ul className="Editlead_flowlis">
              <li onClick={() => setCurrentStep(1)}>Details</li>
              <li onClick={() => setCurrentStep(2)}>Customer</li>
              <li onClick={() => setCurrentStep(3)}>Income</li>
              <li onClick={() => setCurrentStep(4)}>Property</li>
              <li onClick={() => setCurrentStep(5)}>Upload Docs</li>
            </ul>
          </section>
        </div>
        <div className="righteditleadcont">
          {currentStep === 1 && <LoanDetails leadDetail = {leadDetail} masterdata = {masterdata} masterStatusData = {masterStatusData} currentStep = {currentStep} handleStepClick = {handleStepClick}/>}
          {currentStep === 2 && <CustomerDetails leadDetail = {leadDetail} masterdata = {masterdata} masterStatusData = {masterStatusData} />}
          {currentStep === 3 && <IncomeDetails leadDetail = {leadDetail} masterdata = {masterdata} masterStatusData = {masterStatusData} />}
          {currentStep === 4 && <PropertyDetails leadDetail = {leadDetail} masterdata = {masterdata} masterStatusData = {masterStatusData} />}
          {currentStep === 5 && <PartnerDocument leadDetail = {leadDetail} masterdata = {masterdata} masterStatusData = {masterStatusData} />}
        </div>
      </div>
    </>
  );
};

export default EditLead;
