import React, { useContext } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { PartnerOnBordingContext } from "../../PartnerContextApi/PartnerOnBordingContext";

const BankVerificationPopup = (props) => {
  const {isBankVerified, setIsBankVerified, goBackToBankForm, error, setError, } = useContext(PartnerOnBordingContext);
  const history = useHistory();

  const handleNavigation = () => {
   window.location.href = "/saathi-leads"
  };

//console.log("isBankVerified=====", isBankVerified)
  return (
    <>
      {/* First Popup  */}
      {isBankVerified ? (
        <div className="bankverifiedflex">
          <img src="https://ambak.storage.googleapis.com/partner/1797/1720683481931.png"></img>
          <h5 className="bankheadingmain">
            Your Cheque <br></br> has been successfully uploaded
          </h5>
          <p>
            No worries, we will manually verify your details. Please keep calm,
            we will inform you once the verification is completed{" "}
          </p>
          <button className="dashbordbtn" onClick={() => handleNavigation()}>
            Go to Dashboard
          </button>
        </div>
      ) : (
        // {/* Secound Popup  */}
        <div className="bankverifiedflex">
          <img src="https://ambak.storage.googleapis.com/partner/1797/1720688232637.png"></img>
          <h5 className="bankheadingmain">Bank Account Verification Failed</h5>
          <p>Please check your bank details and you can re-verify it from My Account Section</p>

          <div>
            <button className="banklaterbtn" onClick={() => handleNavigation()}>Do it later</button>
            {/* <button className="dashbordbtn" onClick={() => goBackToBankForm()}>Update Bank Details</button> */}
          </div>
        </div>
      )}

      {/* Three Popup  */}

      {/* <div className="bankverifiedflex">
        <img src="https://ambak.storage.googleapis.com/partner/1797/1720688702005.png"></img>
        <h5 className="bankheadingmain">
          Bank Account Details Do Not Match <br></br> KYC Documents
        </h5>
        <div className="kycdocumentmatch">
          <p>. Check bank details and re-verify</p>
          <p>. Add cancelled cheque for verification</p>
        </div>
        <div>
          <button className="banklaterbtn">Do it later</button>
          <button className="dashbordbtn">Update Bank Details</button>
        </div>
      </div> */}

      {/* Four Popup  */}

      {/* <div className="bankverifiedflex">
        <img src="https://ambak.storage.googleapis.com/partner/1797/1720688702005.png"></img>
        <h5 className="bankheadingmain">
          Still facing issues in matching your account details to<br></br> KYC
          documents
        </h5>

        <p>
          No worries, we will manually verify your details. Please share a clear
          picture of your cancelled cheque
        </p>
        <div className="col-md-6">
          <div class="hideinputborder">
            <label
              for="cancel_cheque"
              className="custom-input"
              id="cancel_cheque_label"
            >
              <div>
                <img
                  src="https://ambak.storage.googleapis.com/partner/1797/1720691116166.png"
                  alt="doc"
                  width={16}
                  height={16}
                ></img>
                <span>Cancel Cheque</span>
              </div>
              <div>
                <img
                  src="https://ambak.storage.googleapis.com/partner/1797/1720691944687.svg"
                  alt="doc"
                  width={16}
                  height={16}
                ></img>
              </div>
            </label>
            <input
              type="file"
              name="cancel_cheque"
              id="cancel_cheque"
              className="custom-file-input"
              placeholder="Cancel Cheque"
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div>
          <button className="dashbordbtn">Submit</button>
        </div>
      </div> */}
    </>
  );
};

export default BankVerificationPopup;
