import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import SearchResult from './Manageresult';
import secureStorage from './../../../config/encrypt';
import ModalPopup from '../../elements/Popup';
import { connect } from 'react-redux';
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';
class AccountManagerList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            pageloading:true,
            AccountManagerListData: [],
            searchByList: [],
            cityAllList: [],
            dcSkusList: [],
            dealerCountStatusDetail: {},
            page: 0,
            filterData: {
                status:'1'
            },
            redirect: false
        };
        this.onScroll = this.onScroll.bind(this);
        this.getDealerList = this.getDealerList.bind(this);
        this.flag = true;

    }
    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            this.afterLoginSetState(loginResponse)
        } else {
            this.setState({ redirect: true })
        }
    }
    componentDidMount = async () => {
        await this.updateReduxData();
        this.onScroll('Mounter');
        window.addEventListener('scroll', this.onScroll);
    }
    afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        
    }
    handleOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.value) != null ? oValue.value : oValue.target.value;
        this.setState({
            filterData
        });
    }
    handleCityOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.id) != null ? oValue.id : oValue.target.id;
        this.setState({
            filterData
        });
    }
    handleSkuOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.sku_short_name) != null ? oValue.sku_short_name : oValue.target.sku_short_name;
        this.setState({
            filterData
        });
    }
    handleStatusClick = async (sname, ovalue) => {
        let filterData = this.state.filterData;
        if (sname == 'active') {
            filterData['status'] = '1';
        } else if (sname == 'inactive') {
            filterData['status'] = '2';
        }else if (sname == 'incomplete') {
            filterData['status'] = '3';
        }else if (sname == 'blacklist') {
            filterData['status'] = '5';
        }  else if (sname == 'all') {
            filterData['status'] = '';
        }
        this.setState({ filterData });
        this.state.page=1;
        this.getDealerList();
    }
    onScroll(event) {
        if ((window.innerHeight + window.scrollY > document.body.offsetHeight - 300)) {
            if (this.flag) {
                let current_page = this.state.page;
                let loading=false,pageloading=false;
                if(current_page==0) {
                    pageloading = true;
                }else{
                    loading = true;
                }
                this.setState({ page: current_page + 1,loading:loading,pageloading:pageloading}, () => {
                    this.getDealerList();
                });
            }
            this.flag = false;
        }
    }

    getDealerList() {
    //    console.log("testing");
        var thisObj = this;
        //this.setState({loading:true})
        MasterService.post('dealer/account-manager/list', { 'page_no': this.state.page })
            .then(function (response) {
                thisObj.flag = false;
                thisObj.setState({loading:false,pageloading:false})
                if (response.data.status == 200) {
                    if (response.data.data.length) {
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ AccountManagerListData: response.data.data ,loading:false,pageloading:false}, () => {
                                thisObj.flag = true;
                            });
                        }
                        else {
                            thisObj.setState({
                                AccountManagerListData: thisObj.state.AccountManagerListData.concat(response.data.data)
                            ,loading:false,pageloading:false}, () => {
                                thisObj.flag = true;
                            });
                        }
                    } else {
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ AccountManagerListData: response.data.data});
                        }
                    }
                }
            })
            .catch(function (response) {
                thisObj.setState({loading:false,pageloading:false})
            });
    }
    submitFilterForm = () => {
        this.state.page=1;
        this.setState({pageloading:true})
        this.getDealerList();
    }
   
  
    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/login'} />);
        }
        return (
            <div className={(this.state.pageloading) ? "container-fluid loading":"container-fluid"}>
                <div className="addStock">
                    <h1>{this.props.t('Listing.Account_Manager_Listing')}</h1>
                    <div className="clearfix">
                        <SearchResult pageloading={this.state.pageloading} loading={this.state.loading} AccountManagerListData={this.state.AccountManagerListData} statusClick={this.handleStatusClick} dealerCountStatusDetail={this.state.dealerCountStatusDetail} filterData={this.state.filterData}/>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('ac_manager')(connect(mapStateToProps, mapDispatchToProps)(AccountManagerList));

