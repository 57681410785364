import React, { useEffect, useState } from "react";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import { Link, useHistory } from "react-router-dom";
import Modal from "../../view/common/Modal";
import NewLeadCreate from "./NewLeadCreate";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../config/encrypt";
import MyLeadListFilter from "./MyLeadListFilter";
import { executeGraphQLQueryFinex } from "../../common/executeGraphQLQuery";
import { LEADS_QUERY, STATUS_QUERY } from "../../service/leads.gql";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { GET_MASTER_DATA } from "../../queries/sangam/masterdata.gql";
import { DASHBOARD_QUERY } from "../../queries/sangam/dashboard.gql";
import { GET_USER_LIST } from "../../service/role.gql";
import dateFormat from "dateformat";
import Pagination from "../../view/pagination/Pagination";
import Loader from "../../view/elements/PageLoader";
import ActionTypes from "../../store/action/action";
import crypto from "../../config/crypto";
import GeneralService from "../../service/generalService";
import { RoleAccess } from "../../config/constant";
import NoDataFound from "../../common/NoDataFound";
import { useCleverTap } from '../CleverTapContext';

const MyLeadList = (props) => {
  const sourced_by = RoleAccess("Sourced By Lead Listing");
  const assign_to = RoleAccess("Assigned to Lead Listing");
  const fullfilled_by = RoleAccess("Fullfiled By Lead Listing");
  const assigned_to_button = RoleAccess("Re Assign Lead Listing");
  const new_lead_button = RoleAccess("New Lead Button");
  const trackEvent = useCleverTap();

  const client = "";
  const [activeTab, setActiveTab] = useState("All Leads");
  const [newLead, setNewLead] = useState(false);
  const history = useHistory();
  const [filterParam, setFilterParam] = useState({
    page_no: 1,
    action_type: "lead_id",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [leads, setLeads] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [financers, setFinancers] = useState([]);
  const [fulfillments, setFulfillment] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);
  const [masterdata, setMasterData] = useState([]);
  const randomCardColorId = () => Math.floor(Math.random() * 5) + 1;
  const [dashboard, setDashboard] = useState([]);
  const [userList, setUserList] = useState([]);
  const [assignTo, setAssignTo] = useState([]);

  const updateReduxData = async () => {
    const loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse) {
      props.updateStateIntoRedux(ActionTypes.LOGIN_USER, loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return <Redirect to={"/login"} />;
    }
  };

  const afterLoginSetState = (loginResponse) => {
    const role = loginResponse?.data?.user_data?.role || null;
    if (role !== "dealercentral") {
      return <Redirect to={"/"} />;
    }
  };

  const getMasterData = async () => {
    try {
      const response = await executeGraphQLQueryFinex(GET_MASTER_DATA());

      if (response && response.data && response.data.masterdata) {
        let result = { ...response.data.masterdata }; // Create a copy to avoid mutating the original response

        // Define keys that need processing
        let keyList = [
          "tenure",
          "agreement_type",
          "profession",
          "salary_credit_mode",
          "property_type",
          "usage_type",
          "loan_type",
          "loan_sub_type",
        ];

        // Process each key in keyList
        for (let item of keyList) {
          if (result[item] && result[item].length) {
            result[item] = result[item].map((data) => ({
              ...data,
              value: +data.id, // Convert id to number
              label: data.label, // Maintain the label
            }));
          }
        }

        // Process bank_list if it exists
        if (result.bank_list && result.bank_list.length) {
          result.bank_list = result.bank_list.map((data) => ({
            ...data,
            value: data.id, // Use id as value
            label: data?.banklang?.bank_name || "", // Use bank name or empty string if not available
          }));
        }

        // Set the processed data in state
        setMasterData(result);
      }
    } catch (error) {
      // Handle any errors that occur during the query
      console.error("Error fetching master data:", error);
      toast.error("Something went wrong, please try again");
    }
  };

  let loan_type = masterdata?.loan_type;
  let loan_sub_type = masterdata?.loan_sub_type;

  const getstatusList = () => {
    executeGraphQLQueryFinex(STATUS_QUERY, client)
      .then((result) => {
        setStatuses(result?.data?.status_list);
        setSubStatusList(result?.data?.sub_status_list);
        setFinancers(result.data?.financier_list);
        setFulfillment(result.data?.fulfillment_type);
      })
      .catch((errors) => {
        toast.error("Somethig went wrong ,please try again");
      });
  };

  useEffect(() => {
    trackEvent("page_loaded", "my_leads");
    updateReduxData();
    getLeadList(filterParam);
    getstatusList();
    getMasterData();
    getDashboardCount();
  }, []);

  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));

  let name = loginUserInfo?.name ? loginUserInfo.name : "";
  let userId =
    loginUserInfo && loginUserInfo.user_data
      ? loginUserInfo.user_data.user_id
      : 1;

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    //console.log("tabName", tabName);
    switch (tabName) {
      case "All Leads":
        filterParam.status_id = "";
        break;
      case "New Leads":
        filterParam.status_id = [1];
        break;
      case "Logged In":
        filterParam.status_id = [2];
        break;
      case "Sanctioned":
        filterParam.status_id = [3];
        break;
      case "Disbursed":
        filterParam.status_id = [4];
        break;
      case "Lost":
        filterParam.status_id = [5];
        break;
    }
    getLeadList(filterParam);
  };

  const togglePopup = () => {
    trackEvent("cta_clicked", "my_leads_new_lead");
    setNewLead(!newLead);
    document.body.classList.toggle("overflow-hidden", !newLead);
  };

  const navigateLeadDetail = (lead_id, status_id) => {
    // history.push("/saathi-details-page");
    let url = `/saathi-details-page/${crypto.encode(lead_id)}`;
    // history.push(url);
    window.open(url, "_blank");
  };

  const handleClickOutside = (event) => {
    const moreFilterDiv = document.querySelector(".more-filter");
    if (moreFilterDiv && !moreFilterDiv.contains(event.target)) {
      const toggleCheckbox = document.getElementById("toggleCheckbox");
      if (toggleCheckbox) toggleCheckbox.checked = false;
    }
  };

  const onPaginate = (p) => {
    setFilterParam((prev) => ({ ...prev, ["page_no"]: p }));
    filterParam.page_no = p;
    getLeadList(filterParam);
  };

  const getLeadList = (filterData = {}) => {
    filterData.user_id = loginUserInfo.user_data.dealer_id;
    filterData.partner_id = loginUserInfo.user_data.dealer_id;
    setIsLoading(true);
    if (filterData.search_type) delete filterData.action_type;
    if (!filterData.column) filterData.column = "last_updated_date";
    if (!filterData.sort) filterData.sort = "desc";
    executeGraphQLQueryFinex(LEADS_QUERY(filterData))
      .then((result) => {
        setLeads(result?.data?.leads?.[0]?.lead_list);
        setTotalPage(result?.data?.leads?.[0]?.pagination?.totalpage);
        setIsLoading(false);
      })
      .catch((errors) => {
        toast.error("Somethig went wrong ,please try again");
      });
  };

  const handleSearch = (filterData) => {
    filterData.action_type = filterParam.action_type;
    setFilterParam((prev) => ({ ...prev, ...filterData }));
    filterData.page_no = 1;
    setFilterParam((prev) => ({ ...prev, ["page_no"]: 1 }));
    getLeadList(filterData);
  };

  const getDashboardCount = async () => {
    if (userId) {
      let partner_id = loginUserInfo.user_data.dealer_id;

      executeGraphQLQueryFinex(DASHBOARD_QUERY(userId, partner_id))
        .then((res) => {
          const response = res.data?.lead_dashboard?.map((data) => ({
            ...data,
            color_id: randomCardColorId(),
          }));
          setDashboard(response);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserList();
    };
    fetchData();
  }, []);

  let all_lead_counts = dashboard
    ?.filter((ob) => ob.name === "All Leads")
    ?.map((v) => v.lead_count);
  let new_lead_counts = dashboard
    ?.filter((ob) => ob.name === "New Leads")
    ?.map((v) => v.lead_count);
  let login_lead_counts = dashboard
    ?.filter((ob) => ob.name === "Logged In")
    ?.map((v) => v.lead_count);
  let san_lead_counts = dashboard
    ?.filter((ob) => ob.name === "Sanctioned")
    ?.map((v) => v.lead_count);
  let dis_lead_counts = dashboard
    ?.filter((ob) => ob.name === "Disbursed")
    ?.map((v) => v.lead_count);
  let lost_lead_counts = dashboard
    ?.filter((ob) => ob.name === "Lost")
    ?.map((v) => v.lead_count);

  const getUserList = async () => {
    let user_list = [],
      userlist = [];
    await executeGraphQLQueryFinex(GET_USER_LIST(5, 1))
      .then((response) => {
        if (response && response.data && response.data.get_user_list) {
          user_list = response.data.get_user_list?.filter(
            (obj) => obj.status === "1"
          );
          user_list &&
            user_list.map((v) => {
              userlist.push({
                value: v.user_id,
                label: v.name,
                role_id: v.role_assigned_ids,
              });
              return v;
            });
          setUserList(userlist);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatCurrency = (amount) => {
    if (amount == 0) {
      return "--";
    }
    const formattedAmount = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
    return formattedAmount.replace(/\.00$/, "");
  };

  useEffect(() => {
    const fetchAssignToList = async () => {
      try {
        const response = await GeneralService.subPartnerList(
          loginUserInfo.user_data.dealer_id,
          "team_members"
        );
        if (response?.data?.status === 200) {
          setAssignTo(response?.data?.data?.sub_partner_list || []);
          let retrievedUser = JSON.parse(localStorage.getItem("loginUserInfo"));
          console.log(retrievedUser.user_data.user_id);
        }
      } catch (error) {
        console.error("Error fetching assignTo list:", error);
      }
    };
    fetchAssignToList();
  }, [loginUserInfo.user_data.dealer_id]);

  return (
    <div className="LeadContainer" onClick={handleClickOutside}>
      <div className="myleadcontainer">
        <div className="leftmyleadcontainer">
          <div className="leadheading">My Leads</div>
        </div>
        <div className="rightmyleadcontainer">
          <div className="not_assigned_lead">
            <Link to="/saathi-re-assign-leads">
              {assigned_to_button === 1 && <button>Re-assign</button>}
            </Link>
          </div>
          <div className="newleadbutton">
            {new_lead_button === 1 && (
              <button onClick={togglePopup}>+ New Lead</button>
            )}
          </div>
        </div>
      </div>
      <MyLeadListFilter
        masterdata={masterdata}
        handleSearch={handleSearch}
        setFilterParam={setFilterParam}
        assignTo={assignTo}
      />
      {isLoading ? <Loader /> : null}
      <div className="tablecontainer">
        <div className="newtabsContainer">
          <ul className="newtabs">
            {[
              "All Leads",
              "New Leads",
              "Logged In",
              "Sanctioned",
              "Disbursed",
              "Lost",
            ].map((tabName) => (
              <li
                key={tabName}
                className={
                  activeTab === tabName ? "newtabsItem active" : "newtabsItem"
                }
              >
                <div
                  className="newtabContent"
                  onClick={() => handleTabClick(tabName)}
                >
                  {tabName}
                  <span className="newtabSpan">
                    {/* (
                    {
                      {
                        "All Leads":
                          Number(new_lead_counts) +
                          Number(login_lead_counts) +
                          Number(san_lead_counts) +
                          Number(dis_lead_counts) +
                          Number(lost_lead_counts),
                        "New Leads": new_lead_counts,
                        "Logged In": login_lead_counts,
                        Sanctioned: san_lead_counts,
                        Disbursed: dis_lead_counts,
                        Lost: lost_lead_counts,
                      }[tabName]
                    }
                    ) */}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="table-container">
          <table>
            <thead style={{ position: "sticky", top: "0",zIndex:10 }}>
              <tr>
                <th style={{ width: "7%" }}>Lead ID</th>
                <th>Name</th>
                <th>Loan Type</th>
                <th>Bank</th>
                {activeTab === "All Leads" && (
                  <th style={{ width: "10%" }}>Status</th>
                )}
                {sourced_by === 1 && <th>Sourced by</th>}
                {assign_to === 1 && (
                  <th>
                    {activeTab === "Last" ? "Last Assigned" : "Assigned to"}
                  </th>
                )}

                {fullfilled_by === 1 && <th>Fulfilled by</th>}
                <th>Amount</th>
                <th>Created</th>
                <th>{activeTab == "Sanctioned" ? "Sanctioned Date" : activeTab == "Disbursed" ? "Disbursed Date" : activeTab == "Lost" ? "Lost Date" : "Created Date"}</th>
                {activeTab === "Lost" && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {leads?.length > 0 ? (
                leads?.map((lead, index) => {
                  let leadStatus =
                    lead?.status_info?.statuslang?.status_name || "";
                  let databaseDate = new Date(lead?.updated_date);
                  let currentDate = new Date();
                  // Calculate the difference in milliseconds
                  let differenceInMilliseconds =
                    currentDate.getTime() - databaseDate.getTime();
                  // Convert the difference to seconds
                  let differenceInSeconds = differenceInMilliseconds / 1000;
                  // Convert the difference to minutes
                  let differenceInMinutes = differenceInSeconds / 60;
                  // Convert the difference to hours
                  let differenceInHours = differenceInMinutes / 60;

                  return (
                    <React.Fragment key={lead.id}>
                      <tr
                        key={index}
                        onClick={() =>
                          navigateLeadDetail(lead.id, lead.status_id)
                        }
                      >
                        <td style={{ display: "flex", alignItems: "center" }}>
                          {" "}
                          {lead?.id}
                          <div>
                            {lead.is_status_verified == 2 ? (
                              <img
                                style={{ marginLeft: 5 }}
                                src="https://ambak.storage.googleapis.com/partner/3079/1725263429582.svg"
                                alt="logo"
                                width={16}
                                height={16}
                              />
                            ) : null}
                          </div>
                        </td>
                        <td>
                          {" "}
                          {lead?.customer?.first_name}{" "}
                          {lead?.customer?.last_name}
                        </td>
                        <td>
                          {loan_type
                            ?.filter((data) => data.id == lead?.loan_type)
                            ?.map((v) => v.label)}{" "}
                          {lead?.loan_sub_type ? "-" : ""}
                          {loan_sub_type
                            ?.filter((data) =>
                              lead?.loan_sub_type?.split(",")?.includes(data.id)
                            )
                            ?.map((v) => `${v.label}\n`)}
                        </td>
                        <td>{lead?.lead_details?.bank?.banklang?.bank_name || '--'}</td>
                        {activeTab === "All Leads" && (
                          <td>
                            <span
                              className={`leadStatus ${leadStatus === "New Lead"
                                  ? "newleadsbg"
                                  : ["Contacted", "contact", "Contact"].includes(leadStatus)
                                    ? "disbursedbg"
                                    : ["LogIn", "Login","log in","logged in"].includes(leadStatus)
                                      ? "loggeninbg"
                                      : ["Sanctioned", "Sanction", "sanction"].includes(leadStatus)
                                        ? "sanctionedbg"
                                        : ["Disbursed", "Disburse", "disburse"].includes(leadStatus)
                                          ? "disbursedbg"
                                          : ["Lost", "lost"].includes(leadStatus)
                                            ? "lostleadbg"
                                            : "otherbg"
                                }`} 
                            >
                              {["LogIn", "Login","log in","logged in"].includes(leadStatus) ? "Logged In" : leadStatus}
                              {["Disbursed", "Disburse", "disburse"].includes(leadStatus) && (
                            <>
                            <div className="disburse-container"> 
                              <div style={{display:"flex",gap:"10px"}}>
                                <img  src="https://ambak.storage.googleapis.com/partner/3043/1733552594076.png" alt="icon" width={16} height={16}/>
                              <span><img src="https://ambak.storage.googleapis.com/partner/3043/1733552799331.png"  alt="icon" width={12} height={6}/></span>
                              </div>
                             
                            <div className="disburse-card">
                            <ul>
                                <li className="disbursecommon">
                                <div>PDD</div>
                                <div className="disbursepending">Pending</div>
                                </li>
                                <li className="disbursecommon">
                                <div>Bank MIS</div>
                                <div className="disbursepending">Pending</div>
                                </li>
                                <li className="disbursecommon">
                                <div>Collection</div>
                                <div className="disbursepending">Pending</div>
                                </li>
                                <li className="disbursecommon">
                                <div>Payout</div>
                                <div className="disbursepending">Pending</div>
                                </li>
                              </ul>
                              </div>
                            </div>
                            </>
                          )}
                            </span>
                          </td>
                        )}
                        {sourced_by === 1 && <td>{lead?.partner_name || '--'} </td>}
                        {assign_to === 1 && (
                          <td>
                            {/* {assignTo
                            ?.filter(
                              (data) => data.sub_partner_id == lead?.assigned_partner_name
                            )
                            ?.map((v) => v.sub_partner_name)} */}
                            {lead?.assigned_partner_name || '--'}
                          </td>
                        )}
                        {fullfilled_by === 1 && (
                          <td>{lead?.fulfillment_type.toUpperCase()}</td>
                        )}
                        <td>
                          {/* {lead?.status_id === 1
                            ? "Loan"
                            : lead?.status_id === 2
                            ? "Login"
                            : lead?.status_id === 3
                            ? "Sanctioned"
                            : lead?.status_id === 4
                            ? "Disbursed"
                            : "Loan"}{" "}
                          Amount :{" "} */}
                          {lead?.status_id === 1
                            ? formatCurrency(lead?.lead_details?.loan_amount)
                            : lead?.status_id === 2
                            ? formatCurrency(lead?.lead_details?.login_amount)
                            : lead?.status_id === 3
                            ? formatCurrency(
                                lead?.lead_details?.approved_amount
                              )
                            : lead?.status_id === 4
                            ? formatCurrency(
                                lead?.splitpayment[0]?.disbursed_amount
                              )
                            : formatCurrency(lead?.lead_details?.loan_amount)}
                        </td>
                        <td>
                          {dateFormat(
                            lead?.created_date,
                            "d mmm,yyyy | hh:MM TT"
                          )}
                        </td>
                        <td>
                          {dateFormat(
                            lead?.updated_date,
                            "d mmm,yyyy | hh:MM TT"
                          )}
                        </td>
                        {activeTab === "Lost" && (
                          <td
                            style={{ color: ["#E16B4D", "#473391"][index % 2] }}
                          >
                            {["Re-Open", "Re-Login"][index % 2]}
                          </td>
                        )}
                      </tr>
                    </React.Fragment>
                  );
                })
              ) : (
                <td colspan="11" style={{ textAlign: "center" }}>
                 <NoDataFound/>
                </td>
              )}
            </tbody>
          </table>

          <Pagination
            pageCount={totalPage}
            activePage={
              leads &&
              leads[0] &&
              leads[0].pagination &&
              leads[0].pagination.page_no &&
              1
            }
            onPaginate={onPaginate}
          />
        </div>
        <Modal isOpen={newLead} togglePopup={togglePopup} width={800} removeHeight={true}>
            <NewLeadCreate
              masterData={masterdata}
              loginUserInfo={loginUserInfo}
            />
          </Modal>

      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(MyLeadList)
);