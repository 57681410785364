import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../../config/encrypt";
import { Link, useLocation } from "react-router-dom";
import InputField from "../../../view/elements/Input";
import Modal from "../../../view/common/Modal";
import ApproveInvoiceOtp from "../ApproveInvoiceOtp";
import NoDataFound from "../../../../../backend/src/common/NoDataFound.js";
import login from "../../../view/login/component/login";
import { FINEX_BACKEND_API_KEY, API_URL } from "../../../config/constant";

const ApproveInvoice = (props) => {  
  const updateStateIntoRedux = props.updateStateIntoRedux;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [invoiceGenerated, setInvoiceGenerated] = useState(queryParams.get("url"));
  const [approveOtp, setApproveOtp] = useState(false);
  const [useForFuture, setUseForFuture] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({
    invoiceId: "",
    creditNo: "",
    creditDate: "",
    registeredAddress: "",
    invoiceApproval: "",
    invoiceAddress: "",
    invoiceDate: "",
    address: ""
  });
  const [pdfUrl, setPdfUrl] = useState("");
  const [readonly, setReadonly] = useState(false);
  const closeModal = () => setApproveOtp(false);
  
   const handleInputChange = (e) => {
      const { name, value } = e.target;
      
      if (name === "creditDate") {
        const { min, max } = getCurrentMonthRange();
        if (value < min || value > max) {
          alert("Please select a date within the current month.");
          return;
        }
      }

      setInvoiceDetails((prevDetails) => ({
        ...prevDetails,
        [name]: name === "creditDate" ? formatDate(value) : value,
      }));
    };    

  useEffect(() => {
    // Extract query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    const pdfUrlParam = queryParams.get("url");
    const invoiceIdParam = queryParams.get("invoice_id");
    const invoiceNoParam = queryParams.get("invoice_no");
    const createdAtParam = queryParams.get("created_at");
    const invoiceApprovalParam = queryParams.get("invoice_approval");
    const invoiceAddressParam = queryParams.get("invoice_address");
    const addressParam = queryParams.get("address");
    const invoiceDateParam = queryParams.get("invoice_date");
    const partnerAddressParam = queryParams.get("partner_address");

    if (invoiceApprovalParam === "1") {
      setReadonly(true);
    }
    console.log("pdfUrlParam==>", decodeURIComponent(pdfUrlParam));
    if (pdfUrlParam) {
      setPdfUrl(decodeURIComponent(pdfUrlParam));
    } else {
      setPdfUrl("");
    }
    
   setInvoiceDetails({
      invoiceId: invoiceIdParam || "N/A",
      creditNo: invoiceNoParam || "N/A",
      creditDate: formatDate(decodeURIComponent(invoiceDateParam.indexOf("/") > -1 ? invoiceDateParam : createdAtParam)),
      registeredAddress: addressParam.indexOf("null") > -1 ? partnerAddressParam : addressParam || "N/A",
      invoiceApproval: invoiceApprovalParam || "N/A",
      invoiceAddress: invoiceAddressParam || "N/A",
      address: addressParam || "N/A",
      invoiceDate: decodeURIComponent(invoiceDateParam) || "N/A"
    });
    // Update Redux state for logged-in user
    const loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse) {
      updateStateIntoRedux("LOGIN_USER", loginResponse);
    }
  }, [location.search, updateStateIntoRedux]);

  const handleGenerateInvoice = async () => {
    const query = `
      mutation update_invoice_status($UpdateInvoiceStatus: UpdateInvoiceStatus!) {
        update_invoice_status(UpdateInvoiceStatus: $UpdateInvoiceStatus) {
          status
          msg
        }
      }
    `;
  
    const variables = {
      UpdateInvoiceStatus: {
        id: Number(invoiceDetails.invoiceId),
        paid_status: "invoice_created"
      },
    };
    
    try {
      const response = await fetch(`${API_URL.GETWAY_API}payout/api/v1/graphpql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          apikey: `d77bc90f-2311-47da-9980-2930de73-ambak-finex-beta`
        },
        body: JSON.stringify({
          query,
          variables,
        }),
      });
  
      const result = await response.json();
      // console.log("result=====>>", result);
      if (result.data?.update_invoice_status?.status) {
          setInvoiceGenerated(true);
      } else {
        alert(`Failed to generate invoice: ${result.data?.update_invoice_status?.msg || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error generating invoice:", error);
      alert("An error occurred while generating the invoice.");
    }
  };

  const handleOpenOtp = async () => {
    const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));    
    const partnetId = loginUserInfo.user_data.dealer_id;
    const userMobile = loginUserInfo.user_data.mobile;
    try {
      const partnerId = partnetId;
      const invoiceId = invoiceDetails.invoiceId;
      const mobile = userMobile;
      const hash = "TSHS687363GS";
      
      const response = await fetch(`${API_URL.GETWAY_API}payout/api/v1/graphpql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "apiKey": FINEX_BACKEND_API_KEY,
        },
        body: JSON.stringify({
          query: `{
            send_invoice_otp(
              partner_id: ${partnerId}, 
              invoice_id: ${invoiceId}, 
              mobile: ${mobile}, 
              hash: "${hash}"
            ) {
              status
              msg
            }
          }`,
        }),
      });
  
      const result = await response.json();
  
      if (response.ok && result.data?.send_invoice_otp?.status) {
        alert(`OTP sent successfully! Message: ${result.data.send_invoice_otp.msg || "Please check your SMS"}`);
        setApproveOtp(true);
        document.body.classList.add("overflow-hidden");
      } else {
        alert(`Failed to send OTP: ${result.data?.send_invoice_otp?.msg || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("An error occurred while sending the OTP.");
    }
  };
 
  const handleCloseOtp = () => {
    setApproveOtp(false);
    document.body.classList.remove("overflow-hidden");
  };

  const formatDate = (dateString) => {console.log("datee", dateString)
    if (!dateString) return "";
    console.log("datee2", dateString)
    if (dateString.indexOf("/") > -1) { console.log("datee")
      const parts = dateString.split("/");
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    return dateString; // Assume already in `YYYY-MM-DD` format.
  };  

  const handleReGenerateInvoice = async () => {
    const query = `
      mutation update_credit_debit_pdf($UpdateCreditDebitPdf: UpdateCreditDebitPdf!) {
        update_credit_debit_pdf(UpdateCreditDebitPdf: $UpdateCreditDebitPdf) {
          status
          msg
        }
      }
    `;
  
    const variables = {
      UpdateCreditDebitPdf: {
        invoice_id: Number(invoiceDetails.invoiceId),
        invoice_no: invoiceDetails.creditNo,
        note_date: invoiceDetails.creditDate,
        address: invoiceDetails.registeredAddress,
      },
    };
    
    try {
      const response = await fetch(`${API_URL.GETWAY_API}payout/api/v1/graphpql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          apikey: `d77bc90f-2311-47da-9980-2930de73-ambak-finex-beta`
        },
        body: JSON.stringify({
          query,
          variables,
        }),
      });
  
      const result = await response.json();
      // console.log("result=====>>", result);
      if (result.data?.update_credit_debit_pdf?.status) {
        // alert(`Invoice re-generated successfully! Message: ${result.data.update_credit_debit_pdf.msg}`);
        setInvoiceGenerated(true);
      } else {
        alert(`Failed to re-generate invoice: ${result.data?.update_credit_debit_pdf?.msg || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error re-generating invoice:", error);
      alert("An error occurred while re-generating the invoice.");
    }
  };
  
  const getCurrentMonthRange = () => {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  
    return {
      min: firstDay.toISOString().split("T")[0], // Format as YYYY-MM-DD
      max: lastDay.toISOString().split("T")[0],
    };
  };
  
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  
  return (
    <>
      <div className="LeadContainer">
        <div className="approvecard">
          <Link to="/my-saathi-earnings">
            <div>
              <img
                src={require(`../../../../src/saathi-web/images/left arrow.svg`)}
                alt="Back"
              />
            </div>
          </Link>
          <div>
            Credit/Debit Note - <span>{invoiceDetails.invoiceNo}</span>
          </div>
        </div>
        <div className="approveinovicecard">
          <div className="approveleftcard">
            <div className="emptycontent"> {console.log("pdf==>", pdfUrl)}
              {pdfUrl && pdfUrl !== "#" ? (
                <iframe
                src={pdfUrl}
                title="Invoice PDF"
                width="100%"
                height="100%"
                style={{ border: "none" }}
              ></iframe>
              ) : (
                <div>
                  <img src="https://ambak.storage.googleapis.com/partner/3701/1727502314187.svg" alt="no data" />
                  </div>
              )}
            </div>
          </div>

          <div className="approverigthcard">
            <div style={{ padding: "25px" }}>
              <h5>Credit/Debit Note Details</h5>
              <div className="row">
                <div className="col-md-12">
                  <InputField
                    type="text"
                    name="creditNo"
                    className="form-input-new"
                    title="Credit/Debit No."
                    placeholder="Credit/Debit No."
                    label="Credit/Debit No."
                    value={invoiceDetails.creditNo}
                    onChange={handleInputChange}
                    readOnly={readonly}
                    min={getCurrentMonthRange().min}
                    max={getCurrentMonthRange().max}
                  />
                </div>

                <div className="col-md-12">
                  {/* {console.log(invoiceDetails.invoiceDate, "=====>>>>", invoiceDetails.creditDate)} */}
                  <InputField
                    type="date"
                    name="creditDate"
                    className="form-input-new"
                    title="Date"
                    placeholder=""
                    label="Date"
                    value={invoiceDetails.creditDate}
                    onChange={handleInputChange}
                    readOnly={readonly}
                  />
                </div>
                <div className="col-md-12">
                  <InputField
                    type="text"
                    name="registeredAddress"
                    className="form-input-new"
                    title="Registered Address"
                    placeholder="Registered Address"
                    label="Registered Address"
                    value={invoiceDetails.registeredAddress}
                    onChange={handleInputChange}
                    readOnly={readonly}
                  />
                </div>
              </div>

              {!readonly && (
                <>
              {!pdfUrl || pdfUrl == "#" ? (
                <div style={{ marginBottom: 50 }}>
                  <button className="generateinvoice" onClick={handleGenerateInvoice}>
                    Generate Invoice
                  </button>
                </div>
              ) : (
                <div className="generateinvoiceafter">
                  <button className="regenerateinvoice" onClick={handleReGenerateInvoice}>
                      Re-Generate Invoice 
                  </button>
                  <button className="generateinvoice" onClick={handleOpenOtp}>
                    Approve Invoice
                  </button>
                </div>
                )}
                </>
              )}
            </div>

            <div className="queryraise" style={{ display: 'none' }}>
              <img
                src="https://ambak.storage.googleapis.com/partner/4968/1731911524225.png"
                alt="query"
                width={20}
                height={20}
              />
              <div>Raise Query</div>
            </div>
          </div>

          <div>
            <Modal isOpen={approveOtp} togglePopup={handleCloseOtp} width={600}>
              <ApproveInvoiceOtp invoice_id={invoiceDetails.invoiceId} closeModal={closeModal}/>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth_token: state.authToken,
  user_loggedin: state.userLoggedIn,
  dealer_id: state.dealer_id,
});

const mapDispatchToProps = (dispatch) => ({
  updateStateIntoRedux: (actionType, stateData) =>
    dispatch({ type: actionType, componentState: stateData }),
});

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(ApproveInvoice)
);
