import React, { useEffect, useMemo, useState } from "react";
import InputField from "../../../../view/elements/Input";
import Select from "react-select";
import { handlePriceInputIndian } from "../../../MyLeads/helpers/helpers";
import LoanOfferAdditionalIncome from "./LoanOfferAdditionalIncome";
import LoanOfferITR from "./LoanOfferITR";
import LoanOfferBanking from "./LoanOfferBanking";
import { toast } from "react-toastify";
import { SAVE_CUST_DETAILS } from "../../../../queries/sangam/customer.gql";
import {
  SAVE_OFFER_BANK_DETAILS,
  SAVE_OFFER_ITR_DETAILS,
} from "../../../../queries/sangam/bre.gql";
import { executeGraphQLMutationFinex } from "../../../../common/executeGraphQLQuery";
import { useCleverTap } from '../../../CleverTapContext';

const AMOUNT_TYPE_OPTIONS = [
  { id: "account", label: "Account", value: "account" },
  { id: "cash", label: "Cash", value: "cash" },
];

const SEP_ID = 2;

const LoanOfferCoApplicant = ({
  breData,
  handleBreDataChange,
  masterdata,
  setNextActive,
}) => {
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [mapCoApplicant, setMapCoApplicant] = useState([]);
  const [activeTab, setActiveTab] = useState("coApplicant_0");
  const [errors, setErrors] = useState({});
  const [additionalIncomeCount, setAdditionalIncomeCount] = useState(1);
  const [additionalIncomeFormFields, setAdditionalIncomeFormFields] = useState(
    []
  );
  const [removedCoApplicants, setRemovedCoApplicants] = useState([]); 
  const trackEvent = useCleverTap();

  const ALL_OPTIONS = useMemo(() => {
    const options = masterdata ? { ...masterdata } : {};
    const LIQUID_INCOME_ID = 6;
    options["company_type"] = options["company_type"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    options["ca_amount_type"] = AMOUNT_TYPE_OPTIONS;
    options["additional_income_type"] =
      options["additional_income_type"]
        ?.map((obj) => ({
          ...obj,
          value: obj.id,
        }))
        ?.filter((obj) => obj.value != LIQUID_INCOME_ID) || [];
    options["ca_filtered_additional_income_type_0"] = options[
      "additional_income_type"
    ]?.map((ob) => ({
      ...ob,
      value: +ob.id,
    }));
    options["profession"] = options["profession"]?.filter(
      (obj) => obj.value !== SEP_ID
    );

    return options;
  }, [masterdata]);

  useEffect(() => {
    let co_applicatndata = breData?.co_applicant || [];
    const activeIndex = activeTab.split("_")[1];
    const newCoApplicants = co_applicatndata.map((data, index) => {
      const newAppObject = {};
      newAppObject.id = data.id;
      newAppObject.ca_profession = +data.ca_profession;
      newAppObject.ca_annual_income = data.ca_annual_income;
      newAppObject.ca_gross_monthly_salary = data.ca_gross_monthly_salary;
      newAppObject.ca_monthly_salary = data.ca_monthly_salary;
      newAppObject.ca_first_name = data.ca_first_name;
      newAppObject.ca_is_additional_income = data.ca_is_additional_income;
      newAppObject.ca_offer_bank_details = data.ca_offer_bank_details;
      newAppObject.ca_offer_itr_details = data.ca_offer_itr_details;
      newAppObject.no_of_accounts = data.ca_no_of_accounts;
      newAppObject.ca_income_calculation_mode = data.ca_offer_type;
      newAppObject.ca_existing_emi_amount = data.ca_existing_emi_amount;
      newAppObject.ca_emi_ending_six_month = data.ca_emi_ending_six_month;
      newAppObject.ca_is_obligation = data.ca_is_obligation;
      newAppObject.status = data.status;
      let ca_additionalIncome = [];
      if (data.ca_is_additional_income === "yes") {
        ca_additionalIncome = data.ca_additional_income?.map((obj) => {
          return {
            id: obj.id,
            lead_id: obj.lead_id,
            customer_id: obj.customer_id,
            customer_type: obj.customer_type,
            ca_income_type_id: obj.income_type_id,
            ca_amount_type: obj.amount_type,
            ca_amount: obj.amount,
            status: obj.status,
          };
        });
      }
      newAppObject.ca_additional_income = ca_additionalIncome;
      return newAppObject;
    });
    if (newCoApplicants[activeIndex]?.["ca_is_additional_income"] === "yes") {
      const co_app = newCoApplicants[activeIndex].ca_additional_income;
      for (let i = 0; i < co_app.length; i++) {
        handleAddAdditionalIncome(i);
        ALL_OPTIONS[`ca_filtered_additional_income_type_${i + 1}`] =
          ALL_OPTIONS[`ca_filtered_additional_income_type_${i}`]?.filter(
            (obj) => obj.value !== co_app[i].ca_income_type_id
          );
      }
    }
    setMapCoApplicant(newCoApplicants);
  }, []);

  const handleAddCoApplicant = () => {
    if (mapCoApplicant.length < 3) {
      const existingCo = [...mapCoApplicant];
      const newCoApplicant = {
        ca_first_name: "",
        ca_profession: "",
        ca_monthly_salary: "",
        ca_gross_monthly_salary: "",
        status:"1"
      };
      existingCo.push(newCoApplicant);
      setActiveTab(`coApplicant_${mapCoApplicant.length}`);
      setMapCoApplicant((current) => [...current, newCoApplicant]);
      handleBreDataChange("co_applicant", existingCo);
    }
  };

  const handleAddAdditionalIncome = (index) => {
    if (index < 5) {
      const isExisting = additionalIncomeFormFields.filter(
        (obj) => obj.id === `ca_additional_income_type_${index}`
      );
      if (
        isExisting.length === 0 ||
        additionalIncomeFormFields[additionalIncomeFormFields.length - 1]
          .canAddMore
      ) {
        const dropDownObj = { id: `ca_additional_income_type_${index}` };
        setAdditionalIncomeCount(index + 1);
        setAdditionalIncomeFormFields((current) => [...current, dropDownObj]);
      }
    }
  };

  const handleInputChange = (event, isValueFormated = false) => {
    if (event.target) {
      const { id, value } = event.target;
      const activeIndex = activeTab.split("_")[1];
      const existingCo = [...mapCoApplicant];
      const existingErrors = {
        ...(errors[activeTab] ? errors[activeTab] : {}),
      };
      existingCo[activeIndex][id] = isValueFormated
        ? value.replace(/[^0-9]/g, "")
        : value;
      existingErrors[id] = "";
      setErrors((current) => ({
        ...current,
        [activeTab]: existingErrors,
      }));
      setMapCoApplicant(existingCo);
      handleBreDataChange("co_applicant", existingCo);
    }
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      const activeIndex = activeTab.split("_")[1];
      const existingCo = [...mapCoApplicant];
      const existingErrors = {
        ...(errors[activeTab] ? errors[activeTab] : {}),
      };
      existingCo[activeIndex][field_name] = data.value;
      existingErrors[field_name] = "";
      setErrors((current) => ({
        ...current,
        [activeTab]: existingErrors,
      }));
      setMapCoApplicant(existingCo);
      handleBreDataChange("co_applicant", existingCo);
    }
  };

  const handleAdditionalIncomeType = (data, index) => {
    if (data) {
      ALL_OPTIONS[`ca_filtered_additional_income_type_${index + 1}`] =
        ALL_OPTIONS[`ca_filtered_additional_income_type_${index}`].filter(
          (obj) => obj.value !== data.value
        );
    }
  };

  const handleRemoveAdditionalIncome = (index) => {
    const activeIndex = activeTab.split("_")[1];
    const existingCo = [...mapCoApplicant];
    if (!existingCo[activeIndex]["ca_additional_income"]) {
        existingCo[activeIndex]["ca_additional_income"] = [];
    }
    const existingAddIncomeField = [...additionalIncomeFormFields];
    existingAddIncomeField.splice(index, 1);
    if (existingAddIncomeField.length > 0) {
        existingAddIncomeField[existingAddIncomeField.length - 1].canAddMore = true;
    }
    let existingAddIncome = [
        ...existingCo[activeIndex]["ca_additional_income"],
    ];
    if (existingAddIncome[index]) {
        const removedEle = {
            ...existingAddIncome[index],
            status: "0",
            income_type_id: null,
            amount_type: "",
            amount: "",
        };
        existingAddIncome.splice(index, 1);
        existingAddIncome.push(removedEle);
    } else {
        existingAddIncomeField.splice(index, 1);
    }
    existingCo[activeIndex]["ca_additional_income"] = existingAddIncome;
    if (existingAddIncomeField.length === 0) {
        existingCo[activeIndex]["ca_additional_income"] = [];
    }
    setAdditionalIncomeCount(additionalIncomeCount - 1);
    setAdditionalIncomeFormFields(existingAddIncomeField);
    setMapCoApplicant(existingCo);
    handleBreDataChange("co_applicant", existingCo);
};
const handleRemoveCoApplicant = (index = null) => {
  const activeIndex = activeTab.split("_")[1];
  const existingCoApplicants = [...mapCoApplicant];

  if (index !== null && existingCoApplicants[index]) {
    const coApp = existingCoApplicants[index];
    const removedCoApplicant = {
      ...coApp,
      status: "0",
      ca_first_name: "",
      ca_monthly_salary: "",
      ca_gross_monthly_salary: "",
      ca_profession: ""
    };

    const isModified = 
      coApp.ca_first_name || 
      coApp.ca_monthly_salary || 
      coApp.ca_gross_monthly_salary || 
      coApp.ca_profession;

    existingCoApplicants.splice(index, 1);

    setMapCoApplicant(existingCoApplicants);
    handleBreDataChange("co_applicant", existingCoApplicants);

    if (isModified) {
      setRemovedCoApplicants((prevRemoved) => [...prevRemoved, removedCoApplicant]);
    }


    if (activeTab === `coApplicant_${index}`) {
      const newIndex = Math.max(0, index - 1);
      setActiveTab(`coApplicant_${newIndex}`);
    }
    } 
};

  const handleSetAdditionalIncomeData = (data) => {
    const activeIndex = activeTab.split("_")[1];
    const existingCo = [...mapCoApplicant];
    existingCo[activeIndex]["ca_additional_income"] = data;
    setMapCoApplicant(existingCo);
    handleBreDataChange("co_applicant", existingCo);
  };

  const handleTabChange = (name, value) => {
    const activeIndex = activeTab.split("_")[1];
    const existingCo = [...mapCoApplicant];
    if (name === "ca_is_additional_income" && value === "yes") {
      handleAddAdditionalIncome(0);
    }
    if (name === "ca_is_additional_income" && value === "no") {
      setAdditionalIncomeCount(0);
      setAdditionalIncomeFormFields([]);
    }
    if (name === "is_co_applicant") {
      return handleBreDataChange(name, value);
    }
    existingCo[activeIndex][name] = value;
    setMapCoApplicant(existingCo);
    handleBreDataChange("co_applicant", existingCo);
  };

  const handleItrDataChange = (key, value, obj) => {
    if (!(key === null) || !(obj === null)) {
      const activeIndex = activeTab.split("_")[1];
      const existingCo = [...mapCoApplicant];

      if (obj) {
        existingCo[activeIndex] = { ...existingCo[activeIndex], ...obj };
      } else {
        existingCo[activeIndex][key] = {
          ...existingCo[activeIndex][key],
          ...value,
        };
      }
      setMapCoApplicant(existingCo);
      handleBreDataChange("co_applicant", existingCo);
    }
  };

  const handleBankDataChange = (key, value, obj) => {
    if (!(key === null) || !(obj === null)) {
      const activeIndex = activeTab.split("_")[1];
      const existingCo = [...mapCoApplicant];

      if (obj) {
        existingCo[activeIndex] = { ...existingCo[activeIndex], ...obj };
      } else {
        existingCo[activeIndex][key] = value;
      }
      setMapCoApplicant(existingCo);
      handleBreDataChange("co_applicant", existingCo);
    }
  };

  const handleNext = () => {
    trackEvent("cta_clicked", "offer_engine_form_page_3");
    if (breData.is_co_applicant === "yes") {
      const activeIndex = activeTab.split("_")[1];
      if (
        mapCoApplicant[activeIndex]?.ca_first_name === "" ||
        !mapCoApplicant[activeIndex]?.ca_profession
      ) {
        const existingErrors = {
          ...(errors[activeTab] ? errors[activeTab] : {}),
        };
        if (mapCoApplicant[activeIndex]?.ca_first_name === "") {
          existingErrors["ca_first_name"] = "First name is Required";
        } else {
          existingErrors["ca_profession"] = "Employment Type is Required";
        }

        return setErrors((current) => ({
          ...current,
          [activeTab]: existingErrors,
        }));
      }
    }
    handleSubmit();
  };

  const handleSubmit = async () => {
    try {
      const { lead_id, co_applicant, is_co_applicant } = breData;
      if (lead_id) {
        let coApplicant = [],
          ca_income_calculation_mode = "";
        const isCoApplicant = is_co_applicant || "no";
        const itrDetails = [],
          bankDetails = [];
        if (isCoApplicant === "yes") {
          let updatedCoApplicants = [...co_applicant, ...removedCoApplicants];
          coApplicant = updatedCoApplicants?.map((coApp) => {
            let ca_additionalIncome = [];
            if (coApp["ca_is_additional_income"] === "yes") {
              ca_additionalIncome = coApp.ca_additional_income?.map(
                (income) => {
                  return {
                    ...(income.id ? { id: income?.id } : {}),
                    lead_id,
                    customer_id: coApp.id,
                    customer_type: "co_applicant",
                    income_type_id: income.ca_income_type_id,
                    amount_type: income.ca_amount_type,
                    amount: income.ca_amount,
                    status: income.status,
                  };
                }
              );
            }

            if (+coApp.ca_profession === 3) {
              if (coApp.ca_income_calculation_mode === "itr") {
                ca_income_calculation_mode = "itr";
                if (coApp?.year_1) {
                  itrDetails.push({
                    ...(coApp?.year_1?.id ? { id: coApp.year_1?.id } : {}),
                    lead_id,
                    is_itr_filled: "yes",
                    npat: coApp?.year_1.npat || "",
                    depreciation: coApp?.year_1.depreciation || "",
                    interest: coApp?.year_1.interest || "",
                    tax_paid: coApp?.year_1.tax_paid || "",
                    itr_year: "1",
                    customer_id: coApp?.id,
                    customer_type: "co_applicant",
                  });
                }
                if (coApp?.year_2) {
                  itrDetails.push({
                    ...(coApp?.year_2?.id ? { id: coApp.year_2?.id } : {}),
                    lead_id,
                    is_itr_filled: "yes",
                    npat: coApp?.year_2.npat || "",
                    depreciation: coApp?.year_2.depreciation || "",
                    interest: coApp?.year_2.interest || "",
                    tax_paid: coApp?.year_2.tax_paid || "",
                    itr_year: "2",
                    customer_id: coApp?.id,
                    customer_type: "co_applicant",
                  });
                }
              }
              if (coApp.ca_income_calculation_mode === "bank") {
                ca_income_calculation_mode = "bank";
                for (let i = 0; i < coApp.no_of_accounts; i++) {
                  bankDetails.push({
                    ...(coApp[`bank_details_id_${i}`]
                      ? { id: coApp[`bank_details_id_${i}`] }
                      : {}),
                    lead_id,
                    account_type: coApp[`bank_account_type_${i}`] || "",
                    amount: coApp[`bank_account_avg_amount_${i}`] || "",
                    customer_id: coApp?.id,
                    customer_type: "co_applicant",
                  });
                }
              }
            }

            return {
              id: coApp.id,
              ca_profession: +coApp.ca_profession,
              ca_annual_income: coApp.ca_annual_income,
              ca_gross_monthly_salary: coApp.ca_gross_monthly_salary,
              ca_monthly_salary: coApp.ca_monthly_salary,
              ca_first_name: coApp.ca_first_name,
              ca_is_additional_income: coApp?.ca_is_additional_income || "no",
              ca_additional_income: ca_additionalIncome,
              ca_offer_type: ca_income_calculation_mode || "",
              ca_existing_emi_amount: coApp?.ca_existing_emi_amount || null,
              ca_emi_ending_six_month: coApp?.ca_emi_ending_six_month || null,
              ca_is_obligation: coApp?.ca_is_obligation || "",
              ca_no_of_accounts: coApp?.no_of_accounts || null,
              is_deleted:coApp?.status==="0"?1:0
            };
          });
        }
        const mutation = SAVE_CUST_DETAILS;
        const variables = {
          api_called_by: "saathi_web",
          partner_id: loginUserInfo?.dealer_id,
          LeadAdditionalInput: {
            lead_id,
            co_applicant: coApplicant,
          },
        };
        const resp = await executeGraphQLMutationFinex(mutation, variables);
        let response = resp?.data?.save_additional_info || [];
        let finalItr = [],
          finalBankInc = [];

        for (let i = 0; i < response[0]?.co_applicant?.length; i++) {
          const coApp = response[0].co_applicant[i];
          finalItr = itrDetails?.map((obj) => {
            if (obj.customer_type === "co_applicant") {
              return {
                ...obj,
                ...(obj.customer_id ? {} : { customer_id: coApp?.id }),
              };
            } else {
              return obj;
            }
          });
          finalBankInc = bankDetails?.map((obj) => {
            if (obj.customer_type === "co_applicant") {
              return {
                ...obj,
                ...(obj.customer_id ? {} : { customer_id: coApp?.id }),
              };
            } else {
              return obj;
            }
          });
        }

        if (itrDetails.length > 0) {
          const itrVariable = {
            offerItrDetails: { offer_itr_details: finalItr },
          };
          await executeGraphQLMutationFinex(
            SAVE_OFFER_ITR_DETAILS,
            itrVariable
          );
        }

        if (bankDetails.length > 0) {
          const bankVariable = {
            offerBankDetails: { offer_bank_details: finalBankInc },
          };
          await executeGraphQLMutationFinex(
            SAVE_OFFER_BANK_DETAILS,
            bankVariable
          );
        }
        toast.success("Success");
      }
      setNextActive();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="commonloancard">
        <div className="mainheadingall">Co-Applicant Details</div>
        <div className="loanofferallbox">
          <div className="allheadingincome">Any Co-applicants?</div>
          <div className="cibilloanflex">
            <div
              className={`cibilhometab ${
                breData.is_co_applicant === "yes" ? "active" : ""
              }`}
              onClick={() => handleTabChange("is_co_applicant", "yes")}
            >
              <span>Yes</span>
            </div>

            <div
              className={`cibilhometab ${
                breData.is_co_applicant === "no" ? "active" : ""
              }`}
              onClick={() => handleTabChange("is_co_applicant", "no")}
            >
              <span>No</span>
            </div>
            {breData.is_co_applicant === "yes" && mapCoApplicant.length < 3 && (
              <div className="loanadditional" onClick={handleAddCoApplicant}>
                + Co-applicant
              </div>
            )}
          </div>

          {breData.is_co_applicant === "yes" && (
            <>
              {/* Render Tabs for Each Co-Applicant */}
              <div className="tab-list">
                {mapCoApplicant
                  ?.filter(coApp => coApp.status === "1") 
                  .map((coApp, index) => (
                    <div
                      key={index}
                      className={`commontabsItem ${activeTab === `coApplicant_${index}` ? "active" : ""
                        }`}
                      onClick={() => setActiveTab(`coApplicant_${index}`)}
                    >
                      {!coApp?.ca_first_name ? `Co-applicant - ${index + 1}` : coApp?.ca_first_name}
                      
                    </div>
                  ))}
              </div>

              {/* Render Content Based on Active Tab */}
              <div className="tab-content">
                {mapCoApplicant?.filter(coApp => coApp.status === "1")?.map(
                  
                  (entry, index) =>
                    activeTab === `coApplicant_${index}` && (
                      <>
                        <div className="row" key={index}>
                          <div className="col-sm-4">
                            <InputField
                              required={true}
                              type="text"
                              name="ca_first_name"
                              id="ca_first_name"
                              placeholder="Enter First Name"
                              label="First Name"
                              onChange={(e) => handleInputChange(e)}
                              value={
                                mapCoApplicant[activeTab.split("_")[1]]
                                  ?.ca_first_name
                              }
                            />
                            {errors?.[activeTab]?.["ca_first_name"] && (
                              <div className="formerrorinput">
                                {errors?.[activeTab]?.["ca_first_name"]}
                              </div>
                            )}
                          </div>
                          <div className="col-sm-4">
                            <label>
                              Employment Type{" "}
                              <span className="form-required">*</span>
                            </label>
                            <Select
                              id={"ca_profession"}
                              name={"ca_profession"}
                              onChange={(data) =>
                                handleSelectChange(data, "ca_profession")
                              }
                              options={ALL_OPTIONS["profession"]}
                              placeholder="Select Employment Type"
                              value={
                                ALL_OPTIONS["profession"].find(
                                  (option) =>
                                    option.value ===
                                    mapCoApplicant[activeTab.split("_")[1]]
                                      ?.ca_profession
                                ) || ""
                              }
                            />
                            {errors?.[activeTab]?.["ca_profession"] && (
                              <div className="formerror">
                                {errors?.[activeTab]?.["ca_profession"]}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          {mapCoApplicant[activeTab.split("_")[1]]
                            ?.ca_profession == 3 ? (
                            <>
                              <div className="cibilloanflex">
                                <div className="allheadingincome paddingLeftHeading">
                                  What income do you want to consider?
                                </div>
                                <div
                                  className={`cibilhometab ${
                                    mapCoApplicant[activeTab.split("_")[1]]
                                      ?.ca_income_calculation_mode === "itr"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleTabChange(
                                      "ca_income_calculation_mode",
                                      "itr"
                                    )
                                  }
                                >
                                  <span>ITR Income</span>
                                </div>

                                <div
                                  className={`cibilhometab ${
                                    mapCoApplicant[activeTab.split("_")[1]]
                                      ?.ca_income_calculation_mode === "bank"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleTabChange(
                                      "ca_income_calculation_mode",
                                      "bank"
                                    )
                                  }
                                >
                                  <span>Banking Income</span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-sm-4">
                                <InputField
                                  type="text"
                                  name="ca_monthly_salary"
                                  id="ca_monthly_salary"
                                  required={true}
                                  maxLength={12}
                                  placeholder="Enter Take Home Monthly Salary"
                                  label="Take Home Monthly Salary"
                                  onChange={(e) => handleInputChange(e, true)}
                                  value={handlePriceInputIndian(
                                    mapCoApplicant[activeTab.split("_")[1]]
                                      ?.ca_monthly_salary
                                  )}
                                />
                                {errors?.[activeTab]?.["ca_monthly_salary"] && (
                                  <div className="formerrorinput">
                                    {errors?.[activeTab]?.["ca_monthly_salary"]}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-4">
                                <InputField
                                  type="text"
                                  name="ca_gross_monthly_salary"
                                  id="ca_gross_monthly_salary"
                                  maxLength={12}
                                  required={true}
                                  placeholder="Enter Gross Salary"
                                  label="Gross Salary"
                                  onChange={(e) => handleInputChange(e, true)}
                                  value={handlePriceInputIndian(
                                    mapCoApplicant[activeTab.split("_")[1]]
                                      ?.ca_gross_monthly_salary
                                  )}
                                />
                                {errors?.[activeTab]?.["ca_gross_monthly_salary"] && (
                                  <div className="formerrorinput">
                                    {errors?.[activeTab]?.["ca_gross_monthly_salary"]}
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                          {mapCoApplicant[activeTab.split("_")[1]]
                            ?.ca_profession == 3 &&
                            mapCoApplicant[activeTab.split("_")[1]]
                              ?.ca_income_calculation_mode === "itr" && (
                              <LoanOfferITR
                                breData={breData}
                                handleBreDataChange={handleItrDataChange}
                                customerType="co_applicant"
                                coAppIndex={activeTab.split("_")[1]}
                                coApplicantOfferDetails={
                                  mapCoApplicant[activeTab.split("_")[1]]?.[
                                    "ca_offer_itr_details"
                                  ] || []
                                }
                              />
                            )}

                          {mapCoApplicant[activeTab.split("_")[1]]
                            ?.ca_profession == 3 &&
                            mapCoApplicant[activeTab.split("_")[1]]
                              ?.ca_income_calculation_mode === "bank" && (
                              <>
                                <LoanOfferBanking
                                  breData={breData}
                                  handleBreDataChange={handleBankDataChange}
                                  customerType="co_applicant"
                                  coAppIndex={activeTab.split("_")[1]}
                                  coApplicantOfferDetails={
                                    mapCoApplicant[activeTab.split("_")[1]]?.[
                                      "ca_offer_bank_details"
                                    ] || []
                                  }
                                  coApplicantNoOfAccounts={
                                    mapCoApplicant[activeTab.split("_")[1]]?.[
                                      "no_of_accounts"
                                    ] || 0
                                  }
                                />
                              </>
                            )}
                          
                        </div>
                        <button
                          className="loanremoveoffer"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            right:"14%", 
                            position:"absolute",
                            border:"none",
                            background:"#fff",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveCoApplicant(index);
                          }}
                        >
                          Remove
                        </button>


                        {(mapCoApplicant[activeTab.split("_")[1]]
                          ?.ca_income_calculation_mode === "itr" ||
                          mapCoApplicant[activeTab.split("_")[1]]
                            ?.ca_profession !== 3) && (
                          <>
                            <div className="allheadingincome">
                              Any Additional Income?
                            </div>
                            <div className="cibilloanflex">
                              <div
                                className={`cibilhometab ${
                                  mapCoApplicant[activeTab.split("_")[1]]
                                    ?.ca_is_additional_income === "yes"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleTabChange(
                                    "ca_is_additional_income",
                                    "yes"
                                  )
                                }
                              >
                                <span>Yes</span>
                              </div>

                              <div
                                className={`cibilhometab ${
                                  mapCoApplicant[activeTab.split("_")[1]]
                                    ?.ca_is_additional_income === "no"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleTabChange(
                                    "ca_is_additional_income",
                                    "no"
                                  )
                                }
                              >
                                <span>No</span>
                              </div>
                            </div>

                            {mapCoApplicant[activeTab.split("_")[1]]
                              ?.ca_is_additional_income === "yes" && (
                              <>
                                {additionalIncomeFormFields.map(
                                  (data, index) => (
                                    <LoanOfferAdditionalIncome
                                      index={index}
                                      ALL_OPTIONS={ALL_OPTIONS}
                                      setAdditionalIncomeType={
                                        handleAdditionalIncomeType
                                      }
                                      removeAdditionalIncome={
                                        handleRemoveAdditionalIncome
                                      }
                                      setAdditionalIncomeData={
                                        handleSetAdditionalIncomeData
                                      }
                                      additionalIncomeData={
                                        mapCoApplicant[
                                          activeTab.split("_")[1]
                                        ]?.["ca_additional_income"]
                                      }
                                      key={index}
                                      isCoApp={true}
                                    />
                                  )
                                )}
                                {additionalIncomeCount < 5 && (
                                  <button
                                  style={{
                                 
                                    border:"none",
                                    background:"#fff",
                                  }}
                                    className="loanadditional"
                                    onClick={() =>
                                      handleAddAdditionalIncome(
                                        additionalIncomeCount
                                      )
                                    }
                                  >
                                    + Add Additional Income
                                  </button>
                                )}
                              </>
                            )}
                          </>
                        )}

                        <div className="allheadingincome">Obligation</div>
                        <br />
                        <div className="row">
                          <div className="col-sm-4">
                            <InputField
                              type="text"
                              name="ca_existing_emi_amount"
                              id="ca_existing_emi_amount"
                              maxLength={12}
                              placeholder="Current EMIs"
                              label="Current EMIs"
                              onChange={(e) => handleInputChange(e, true)}
                              value={handlePriceInputIndian(
                                mapCoApplicant[activeTab.split("_")[1]]
                                  ?.ca_existing_emi_amount
                              )}
                            />
                          </div>
                          <div className="col-sm-4">
                            <InputField
                              type="text"
                              name="ca_emi_ending_six_month"
                              id="ca_emi_ending_six_month"
                              maxLength={12}
                              placeholder="EMIs Ending in 6 Months"
                              label="EMIs Ending in 6 Months"
                              onChange={(e) => handleInputChange(e, true)}
                              value={handlePriceInputIndian(
                                mapCoApplicant[activeTab.split("_")[1]]
                                  ?.ca_emi_ending_six_month
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div style={{ textAlign: "end" }}>
        <button className="loanbtnnext" onClick={handleNext}>
          Next
        </button>
      </div>
    </>
  );
};

export default LoanOfferCoApplicant;
