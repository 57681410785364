import React, { createContext, useContext, useEffect } from 'react';
import * as CleverTap from "clevertap-web-sdk";

const CleverTapContext = createContext();

export const CleverTapProvider = ({ children, user }) => {
  // Initialize CleverTap once when the provider is created
  useEffect(() => {
    try {
      CleverTap.init('RZ5-485-ZK7Z');
      CleverTap.setLogLevel(3);
      CleverTap.enablePersonalization = true;
      // CleverTap.debug = true; // For verbose debugging
      // CleverTap.event.push("Test11122");
    } catch (error) {
      console.error("Error initializing CleverTap:", error);
    }
  }, []);

  useEffect(() => {
    if (user && user.id && user.mobile) {
      try {
        console.log("Setting user profile in CleverTap:", user);
        CleverTap.init('RZ5-485-ZK7Z');
        // Set user profile
        const profileData = {
          "Site": {
            Identity: user.id,
            Mobile: user.mobile,
            ...(user.name && { Name: user.name }),  
            ...(user.email && { Email: user.email }), 
          },
        };
        CleverTap.profile.push(profileData);
        console.log("Profile pushed successfully");
      } catch (error) {
        console.error("Error pushing profile to CleverTap:", error);
      }

      try {
        console.log("Calling onUserLogin for CleverTap:", user);
        CleverTap.init('RZ5-485-ZK7Z');
        // Call onUserLogin
        CleverTap.onUserLogin.push({
          "Site": {
            identity: user.id,
            mobile: user.mobile,
            ...(user.name && { name: user.name }),  
            ...(user.email && { email: user.email }), 
          }
        });
        console.log("onUserLogin pushed successfully");
      } catch (error) {
        console.error("Error in onUserLogin for CleverTap:", error);
      }
    }
  }, [user]);

  const trackEvent = (eventName, elementId, page = window.location.pathname) => {
    console.log("Tracking event==>:", eventName, elementId);
    
    const eventPayload = {
      Element: elementId,
      Page: page,
      timestamp: new Date().toISOString(),
    };
  
    // Add UserId to event payload only if user is logged in
    if (user && user.id) {
      eventPayload.UserId = user.id;
    }
  
    try { console.log("eventName ===>> ", eventName);
      CleverTap.init('RZ5-485-ZK7Z');
      CleverTap.event.push(eventName, eventPayload);
      console.log("Event tracked successfully:", eventPayload);
    } catch (error) {
      console.error("Error tracking event in CleverTap:", error);
    }
  };
  

  return (
    <CleverTapContext.Provider value={trackEvent}>
      {children}
    </CleverTapContext.Provider>
  );
};

export const useCleverTap = () => {
  return useContext(CleverTapContext);
};
