import React, { useState } from 'react';

const SideBar = ({ children, isOpen, toggleSideBar, width, title }) => {

    return (
        <>
            {isOpen && (
                <div className="rightsidebar-overlay" onClick={toggleSideBar}></div>
            )}
            <div className={`rightsidebar ${isOpen ? 'open' : ''}`} style={{ width: width || '30%' }}>
                <div className="rightsidebar-header">
                    <span className="rightsidebar-title">{title}</span>
                    <span className="close" onClick={toggleSideBar}>&times;</span>
                </div>
                <div >
                    {children}
                </div>
            </div>
        </>
    );
};

export default SideBar;
