import React, { Component } from 'react';
import SearchDealer from './search-dealer';
import { Redirect } from 'react-router-dom';
import PartnerResults from './Partnerresult';
import secureStorage from '../../../config/encrypt';
import ModalPopup from '../../elements/Popup';
import DateFormate from 'dateformat';
import { connect } from 'react-redux';
import MasterService from '../../../service/MasterService';
import { withTranslation } from 'react-i18next';
import { CSVLink } from "react-csv";
import { RoleId } from '../../../config/constant';
const search_by_kyc = [
    { id: 'pending', name: 'Pending' },
    { id: 'uploaded', name: 'Uploaded' },
    { id: 'verified', name: 'Verified' },
  ];
class ManagePartnerList extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            pageloading:true,
            dealerListData: [],
            searchByList: [],
            cityAllList: [],
            dcSkusList: [],
            dealerCountStatusDetail: {},
            page: 0,
            filterData: {
                status:'1'
            },
            redirect: false,
            mappingCsvData:[],
            dealerPremiumTypeList:[],
           
        };
        this.onScroll = this.onScroll.bind(this);
        this.getDealerList = this.getDealerList.bind(this);
        this.flag = true;
        this.csvLink = React.createRef();


    }
    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            this.afterLoginSetState(loginResponse)
        } else {
            this.setState({ redirect: true })
        }
    }
    componentDidMount = async () => {
        await this.updateReduxData();
        this.onScroll('Mounter');
        window.addEventListener('scroll', this.onScroll);
        await this.getSearchByList();
        await this.getCityList();
     //   await this.getDealerSkus();
        await this.getDealerCountStatusDetail();
        await this.getLanguageList()
     

    }

    getLanguageList() {
        let self = this
        MasterService.getMasters(['dealer_premium_type'],
            { 'headers': { 'Authorization': self.props.auth_token } })
            .then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    self.setState({
                        dealerPremiumTypeList: response.data.data.dealer_premium_type,
                    }, () => {
                    })
                } else if (response.status == 401 && response.data.status == 401) {
                    self.setState({ redirect: true })
                }
            }).catch((error) => {
            });
    }

    afterLoginSetState = (loginResponse) => {
       // alert(role)
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        if (role != "dealercentral") {
            this.setState({ redirect: '/' })
        }
    }
    handleOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.value) != null ? oValue.value : oValue.target.value;
        this.setState({
            filterData
        });
    }
    handleCityOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.id) != null ? oValue.id : oValue.target.id;
        this.setState({
            filterData
        });
    }
    handleSkuOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.sku_short_name) != null ? oValue.sku_short_name : oValue.target.sku_short_name;
        this.setState({
            filterData
        });
    }
    handlePremiumChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.code) != null ? oValue.code : oValue.target.code;
        this.setState({
            filterData
        })
    }

    handleStatusClick = async (sname, ovalue) => {
        let filterData = this.state.filterData;
        if (sname == 'active') {
            filterData['status'] = '1';
        } else if (sname == 'inactive') {
            filterData['status'] = '2';
        }else if (sname == 'incomplete') {
            filterData['status'] = '3';
        }else if (sname == 'pending') {
            filterData['status'] = '4';
        }else if (sname == 'blacklist') {
            filterData['status'] = '5';
        }else if (sname == 'rejected') {
            filterData['status'] = '6';
            filterData['list_type'] = 'rejected';
        }  else if (sname == 'all') {
            filterData['status'] = '';
        }
        this.setState({ filterData });
        this.state.page=1;
        this.getDealerList();
        this.getDealerCountStatusDetail();
    }
    onScroll(event) {
        if ((window.innerHeight + window.scrollY > document.body.offsetHeight - 300)) {
            if (this.flag) {
                let current_page = this.state.page;
                let loading=false,pageloading=false;
                if(current_page==0) {
                    pageloading = true;
                }else{
                    loading = true;
                }
                this.setState({ page: current_page + 1,loading:loading,pageloading:pageloading}, () => {
                    this.getDealerList();
                });
            }
            this.flag = false;
        }
    }

    getDealerList() {
       const loginData =  secureStorage.getItem("loginUserInfo");
       const dealer_hash_id =loginData.data && loginData.data.dealer_data && loginData.data.dealer_data.length>0 ? loginData.data.dealer_data[0].dealer_id_hash : "";
       if(!dealer_hash_id || dealer_hash_id=="")  return (<Redirect to={'/login'} />);
        var thisObj = this;
        const postData = { ...this.state.filterData, parent_partner_hash:dealer_hash_id,'page_no': this.state.page}
        MasterService.post('dealer/dealer/dealer-list', postData )
            .then(function (response) {
                thisObj.flag = false;
                thisObj.setState({loading:false,pageloading:false})
                if (response.data.status == 200) {
                    if (response.data.data.length) {
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ dealerListData: response.data.data ,loading:false,pageloading:false}, () => {
                                thisObj.flag = true;
                            });
                        }
                        else {
                            thisObj.setState({
                                dealerListData: thisObj.state.dealerListData.concat(response.data.data)
                            ,loading:false,pageloading:false}, () => {
                                thisObj.flag = true;
                            });
                        }
                    } else {
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ dealerListData: response.data.data});
                        }
                    }
                }
               // console.log("data" , postData) 
            })
            .catch(function (response) {
                thisObj.setState({loading:false,pageloading:false})
            });
    }

    submitFilterForm = () => {
        this.state.page=1;
        this.setState({pageloading:true})
        this.getDealerList();
        this.getDealerCountStatusDetail();
    }

    submitExportData = () => {
      //  console.log("submitExportData")
        this.setState({pageloading:true})
        this.getExportDataList();
    }

    getExportDataList() {

        var thisObj = this;
        //this.setState({loading:true})
        MasterService.post('dealer/dealer/get_export_data', { 'page_no': this.state.page, ...this.state.filterData })
            .then(function (response) {
                thisObj.flag = false;
                thisObj.setState({ loading: false, pageloading: false })
                if (response.data.status == 200) {
                    let csv_object = response.data.data.map(elm => ({
                        "Partner Status": elm.status ? elm.status == 1 ? 'Active' : elm.status == 2 ? 'Inactive' : elm.status == 3 ? 'Incomplete' : elm.status == 4 ? 'Pending' : elm.status == 5 ? 'Blacklist' : 'Unknown' : "",
                        "Ambak Code": elm.gcd_code ? elm.gcd_code : "",
                        "Partner Name": elm.organization ? elm.organization : "",
                        "Partner Email": elm.dealership_email ? elm.dealership_email : "",
                        "Owner Name": elm.owner_name ? elm.owner_name : "",
                        "Date of Joining": elm.date_of_joining ? DateFormate(new Date(elm.date_of_joining),"yyyy-mm-dd") : "",
                        "Created Date": elm.created_date ? DateFormate(new Date(elm.created_date),"yyyy-mm-dd") : "",
                        "State": elm.statename ? elm.statename : "",
                        "City": elm.cityname ? elm.cityname : "",
                        "Location": elm.localityname ? elm.localityname : "",
                        "Onboarded By": elm.onboarded_by ? elm.onboarded_by : "Admin",
                        "UserRole": elm.user_role ? RoleId[elm.user_role] : "Admin",
                        "Partner Type": elm.premium_code && thisObj.state.dealerPremiumTypeList.findIndex(({ code }) => code === elm.premium_code) > -1 ? thisObj.state.dealerPremiumTypeList.filter(({ code }) => code === elm.premium_code)[0].title : "",
                    }));
                    thisObj.setState({ mappingCsvData: csv_object }, () => {
                        if (thisObj.csvLink.current && thisObj.csvLink.current.link) thisObj.csvLink.current.link.click();
                    })
                }
            })
            .catch(function (error) {
                console.log("error", error)
                thisObj.setState({ loading: false, pageloading: false })
            });
    }

    getSearchByList() {
        // var parentObj = this;
        // var thisObj = this;
        MasterService.get('dealer/dealer/search-by-list')
            .then((searchResult) => {
                this.flag = false;
                if (searchResult && searchResult.data && searchResult.data.status == 200 && searchResult.data.data) {
                    this.setState({
                        searchByList: searchResult.data.data
                    })
                }
            })
            .catch(function (searchError) {
                console.error("searchError", searchError)
            });
    }
    getCityList() {
        var parentObj = this;
        
        var thisObj = this;
        MasterService.get('core/commonservice/state_city_all')
            .then(function (response) {
                // console.log(response);return false;
                thisObj.flag = false;
                if (response.data.status == 200) {
                    parentObj.setState({
                        cityAllList: response.data.data.city
                    })
                }
            })
            .catch(function (response) {
            });
    }
    // getDealerSkus() {
    //     var parentObj = this;
    //     const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': this.props.auth_token,
    //         'Accept-Language': 'en'
    //     }
    //     var thisObj = this;
    //     MasterService.get('core/commonservice/master/?master[]=dealer_sku')
    //         .then(function (response) {
    //             // console.log(response);return false;
    //             thisObj.flag = false;
    //             if (response.status == 200 && response.data.status == 200) {
    //                 parentObj.setState({
    //                     dcSkusList: response.data.data.dealer_sku || []
    //                 })
    //             }
    //         })
    //         .catch(function (response) {
    //         });
    // }
    // getDealerCountStatusDetail() {
    //     var parentObj = this;
    //     const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': this.props.auth_token,
    //         'Accept-Language': 'en'
    //     }
    //     var thisObj = this;
    //     const loginData =  secureStorage.getItem("loginUserInfo");
    //     const dealer_hash_id =loginData.data && loginData.data.dealer_data && loginData.data.dealer_data.length>0 ? loginData.data.dealer_data[0].dealer_id_hash : "";
    //     const filterData = { 'page_no': this.state.page, parent_partner_hash:dealer_hash_id, ...this.state.filterData }
    //     MasterService.post('dealer/dealer/count_dealer_status',filterData)
    //         .then(function (response) {
    //             if (response.status == 200 && response.data.status == 200) {
    //                 parentObj.setState({
    //                     dealerCountStatusDetail: response.data.data || {}
    //                 },()=>{
    //                 })
    //             }
    //         })
    //         .catch(function (response) {
    //         });
    // }
  
    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/login'} />);
        }
        return (
            <div className={(this.state.pageloading) ? "container-fluid loading":"container-fluid"}>
                <div className="addStock">
                    <h1>{this.props.t('Listing.Dealer_Listing')}</h1>
                
                    <SearchDealer dcSkusList={this.state.dcSkusList} cityAllList={this.state.cityAllList} searchByList={this.state.searchByList} dealerPremiumTypeList={this.state.dealerPremiumTypeList} optionChange={this.handleOptionChange} optionCityChange={this.handleCityOptionChange} optionSkuChange={this.handleSkuOptionChange} optionPremiumChange={this.handlePremiumChange} onSubmitFilter={this.submitFilterForm}/>
                    <div className="clearfix">
                        <PartnerResults pageloading={this.state.pageloading} loading={this.state.loading} dealerListData={this.state.dealerListData} statusClick={this.handleStatusClick} dealerCountStatusDetail={this.state.dealerCountStatusDetail} filterData={this.state.filterData} onExportData={this.submitExportData}/>
                        <CSVLink
                            asyncOnClick={true}
                            className="hidden"
                            ref={this.csvLink}
                            data={this.state.mappingCsvData ? this.state.mappingCsvData : []}
                            filename={`dealer-list-${Math.round(+new Date() / 1000)}.csv`}
                            data-interception='off'
                        >
                            {this.props.t('Listing.Search_Result.Export_Data')}
                        </CSVLink>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(ManagePartnerList));
//export default connect(mapStateToProps, mapDispatchToProps)(ManageDealerList);
//export default ManageDealerList;
