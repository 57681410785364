import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from "react";
import Select from "react-select";
import InputField from "../../../view/elements/Input";
import { fileUploader } from "../../Utility/user.utility";
import { toast } from "react-toastify";
import FileInput from "./Input/FileInput";
import { PartnerOnBordingContext } from "../../PartnerContextApi/PartnerOnBordingContext";
import { isFileTypeAllowed } from "../../Utility/file.utility";
import { useCleverTap } from '../../CleverTapContext';

const BankDetailsKyc = forwardRef((props, ref) => {
  const { isLoading, setIsLoading, error, setError } = useContext(PartnerOnBordingContext);
  const [cancelCheque, setCancelCheque] = useState({});
  const trackEvent = useCleverTap();
  const [bankDetails, setBankDetails] = useState({
    account_type: "",
    beneficiary_name: "",
    account_number: "",
    bank_name: "",
    ifsc_code: "",
    user_id: "",
    partner_id: "",
    upi_id: "",
    docs: [
      {
        kyc_master_id: 4,
        file_name: "",
        file_url: "",
      },
    ],
    is_name_matched: 2,
  });

  const errorHandler = (bankDetails) => {
    const {
      account_number,
      beneficiary_name,
      ifsc_code,
      cancel_cheque,
      bank_name,
    } = bankDetails;
  
    let hasError = false;
    let newErrorState = {
      account_number : false,
      beneficiary_name : false,
      ifsc_code : false,
      is_name_matched : false,
      cancel_cheque : false,
      bank_name: false,
    };
  
    if (!account_number) {
      toast.error('Account number is required.\n');
      newErrorState.account_number = true;
      hasError = true;
    } else if (!/^\d+$/.test(account_number)) {
      toast.error('Account number should be numbers only.');
      newErrorState.account_number = true;
      hasError = true;
    }
    if (!beneficiary_name) {
      toast.error('Beneficiary name is required.\n');
      newErrorState.beneficiary_name = true;
      hasError = true;
    }
    if (!ifsc_code) {
      toast.error('IFSC code is required.\n');
      newErrorState.ifsc_code = true;
      hasError = true;
    }
    if (!cancel_cheque) {
      toast.error('Cancel cheque is required.\n');
      newErrorState.cancel_cheque = true;
      hasError = true;
    }
    if (!bank_name) {
      toast.error('Bank name is required.\n');
      newErrorState.bank_name = true;
      hasError = true;
    }
  
    if (hasError) {
      setError((prevError) => ({
        ...prevError,
        ...newErrorState,
      }));
      return hasError;
    }
  
    return false;
  };


  const saveBank = useCallback(() => {
    try {
      setIsLoading(true);
      const isError = errorHandler(bankDetails);

      if(isError) {
        setIsLoading(false);
        return isError;
      } else {
        return {bankDetails, isError };
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  },[bankDetails]);


  useImperativeHandle(ref, () => ({
    saveBank,
  }));

  const handleOnChange = async (event) => {
    if (event.bank_name) {
      setBankDetails((previousState,) => ({
        ...previousState,
        'bank_name': event.bank_id,
      }))
    } else if(event.account_type) {
      setBankDetails((previousState,) => ({
        ...previousState,
        'account_type': event.account_type,
      }))
    } else {
      const {id='', value='', files=[]} = event.target;
      if (files && !isFileTypeAllowed(files[0])) {
        return;
      }
      if(id === 'cancel_cheque') {
        trackEvent("image_selected", "bank_details_step2_cc");
        await handleFileUpload(files[0]);
      }
      setBankDetails((previousState,) => ({
        ...previousState,
        [id]: value,
      }))
    }
  };

  const handleFileUpload = async (file) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      if (!bankDetails.account_number.length > 0) {
        toast.error('Please enter account first.');
        setError({...error, account_number: true});
        setIsLoading(false);
      } else {
        const response = await fileUploader(file)
        if(response.file_url) {
          setCancelCheque((previousState) => ({
            ...previousState,
            file_name: response.file_name,
            file_url: response.file_url,
          }))
          setBankDetails((previousState) => {

            // Ensure previousState and docs exist
            if (!previousState || !previousState.docs) {
              return previousState; // Or return an initial state if necessary
            }
            return {
              ...previousState,
              docs: previousState.docs.map((doc, index) => {
                if (index === 0) {
                  return {
                    ...doc,
                    file_name: response.file_name,
                    file_url: response.file_url,
                  };
                }
                setError((prevError) => ({
                  ...prevError,
                  cancel_cheque: true,
                }));
                setError({...error, account_number: false});
                return doc;
              }),
            };
          });
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsLoading(false);
    }
  };

// Select options logic moved here from jsx to improve readability
  const bankOptions = props?.bankList.map(({ banklang }) => ({
    bank_id: banklang.bank_id,
    bank_name: banklang.bank_name,
  }));

  const accoutTypeOptions = [
    { id: 1, account_type: "Saving" },
    { id: 2, account_type: "Current" },
  ];

  const selectedBankValue = bankOptions.find(({ bank_id }) => bank_id === Number(bankDetails.bank_name)) || null;
  const selectedAccountType = accoutTypeOptions.find(({ account_type }) => account_type === bankDetails.account_type) || null;

  return (
    <>
      <div>
        <InputField
          type="text"
          name="beneficiary_name"
          id="beneficiary_name"
          label="Beneficiary Name"
          placeholder="Enter Beneficiary Name"
          onChange={handleOnChange}
          value={bankDetails?.beneficiary_name}
          required={true}
          disabled={isLoading}
          error={error?.beneficiary_name}
        />
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputField
            type="text"
            name="account_number"
            id="account_number"
            label="Account Number"
            placeholder="Enter Account Number"
            onChange={handleOnChange}
            value={bankDetails?.account_number}
            required={true}
            disabled={isLoading}
            error={error?.account_number}
          />
        </div>
        <div className="col-md-6">
          <InputField
            type="text"
            name="ifsc_code"
            id="ifsc_code"
            label="IFSC Code"
            placeholder="Enter IFSC Code"
            onChange={handleOnChange}
            value={bankDetails?.ifsc_code}
            required={true}
            disabled={isLoading}
            error={error?.ifsc_code}
          />
        </div>
      </div>
      <div style={{marginBottom: 20}} className="row">
         <div className="col-md-6">
          <label>Bank Name <a style={{color:'red'}}>*</a></label>
          <Select
            name="bank_name"
            id="bank_name"
            options={bankOptions}
            placeholder="Select Bank name"
            value={selectedBankValue}
            getOptionLabel={({ bank_name }) => bank_name}
            getOptionValue={({ bank_id }) => bank_id}
            onChange={handleOnChange}
            styles={{
              control: (base) => ({
                ...base,
                borderColor: error.bank_name ? 'red' : base.borderColor,
              }),
            }}
          />
        </div>
        <div className="col-md-6">
          <label>A/C Type</label>
          <Select
            id="accout_type"
            name="accout_type"
            options={accoutTypeOptions}
            onChange={handleOnChange}
            value={selectedAccountType}
            getOptionLabel={({ account_type }) => account_type}
            getOptionValue={({ id }) => id}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <InputField
            type="text"
            name="upi_id"
            id="upi_id"
            label="UPI ID"
            placeholder="Enter UPI ID"
            onChange={handleOnChange}
            value={bankDetails?.upi_id}
            // required={true}
          />
        </div>
        <div className="col-md-6">
          <label>Cancel Cheque</label>
          <FileInput
            name="cancel_cheque *"
            id="cancel_cheque"
            placeholder={"Cancel Cheque"}
            onChange={handleOnChange}
            required={true}
            fileUrl={cancelCheque?.file_url}
            disabled={isLoading}
            error={error?.cancel_cheque}
          />
        </div>
      </div>
    </>
  );
});

export default BankDetailsKyc;
