import React , {useState}from "react";
import InputField from '../../view/elements/Input';
import { useCleverTap } from '../CleverTapContext';

const VerificationCibil = (props) => {
  const [mobile_number , setMobile_number] = useState("")
  const {pan_details , lead_id  , handleDataFromVerificationCibil , mobile}  = props;
  const formattedDob = pan_details?.dob?.split("/")?.reverse()?.join("-");
  const trackEvent = useCleverTap(); 
  const handleChange =(e) => {
    trackEvent("input_field_filled", "credit_report_mobile");
      setMobile_number(e.target.value)
      handleDataFromVerificationCibil(e.target.value)

  }
  return (
    <>
      <div className="mytoolverification">
        <h2 className="verificationheadingmain">Verification</h2>
        <p>
          {pan_details.firstName || pan_details.lastName} will receive a consent link via SMS. Please ask them to share their consent with you to generate the
          CIBIL report.
        </p>
        <div className="row">
          <div className="col-sm-6">
            <InputField
              type="tel"
              name="mobile_no"
              id="mobile_no"
              placeholder="Mobile No"
              label="Mobile No"
              value={mobile}
              onChange = {(e) => handleChange(e)}
              maxLength = {10}
            />
          </div>
          <div className="col-sm-6">
            <InputField
              type="date"
              name="dob"
              id="dob"
              placeholder="DOB"
              label="DOB"
              value={formattedDob} 
              onChange = {trackEvent("input_field_filled", "credit_report_dob")}
            />
          </div>
          <div className="col-sm-6">
            <InputField
              type="text"
              name="full_name"
              id="full_name"
              placeholder="First Name"
              label="First Name"
              value={pan_details.firstName || pan_details.lastName}
              onChange = {trackEvent("input_field_filled", "credit_report_first_name")}
            />
          </div>
          <div className="col-sm-6">
            <InputField
              type="text"
              name="last_name"
              id="last_name"
              placeholder="Last Name"
              label="Last Name"
              value={pan_details.lastName ||pan_details.firstName}
              onChange = {trackEvent("input_field_filled", "credit_report_last_name")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationCibil;
