import moment from 'moment'
import React from 'react'
import { convertToWords, formatCurrency } from '../../MyLeads/helpers/helpers'

const NotesViewDetailStrip = ({bankList,togglePopup,leadsData,data}) => {
  return (
    <>
    <div className='flex flex-row justify-space-between notes-inner-detail-box'>
                <div className='flex gap-10px'>
                <div className='bank-icon'><img src={bankList.find(item=>item.id==leadsData.financer_id)?.logo} width={"20px"} height={"20px"} /></div>
                    <div className='flex flex-col'>
                        <div className='font-13px font-weight-500' >{leadsData.customer_name}</div>
                        <div className='font-11px font-weight-400'>{leadsData.product_type=="2"?"HL":"LAP"}.ID {leadsData.lead_id}</div>
                        <div className='font-11px font-weight-400'>Disbursed:{convertToWords(leadsData.disbursal_amount)}.{moment(leadsData.disbursal_date).format("DD/MM/YYYY")}</div>
                    </div>
                </div>
                <div style={{cursor:"pointer"}}>
                    <div className='font-13px font-weight-500' onClick={()=>togglePopup(leadsData.lead_id,data.id)}>₹ {formatCurrency(leadsData.disbursal_amount)} <img className='right-arrow' src={require('../../images/rightarrownew.svg')}  /></div>
                </div>
            </div>
    </>
  )
}

export default NotesViewDetailStrip