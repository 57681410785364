import React, { useContext, useEffect, useState } from "react";
import Modal from "../../../view/common/Modal";
import { getUserInfo, postApiUtility } from "../../Utility/user.utility";
import { toast } from "react-toastify";
import BankVerificationPopup from "./BankVerificationPopup";
import { PartnerOnBordingContext } from "../../PartnerContextApi/PartnerOnBordingContext";

const BankVerification = (props) => {
  const { error, setError, isBankVerified, setIsBankVerified } = useContext(PartnerOnBordingContext);
  const bankDetails = props.bankDetails;
  const [openModal, setOpenModal] = useState(false);



  useEffect(async () => {
    await verifyBankDetails();
  },[])


  const verifyBankDetails = async () => {
     const userInfo = getUserInfo();
        const { user_id, dealer_id } = userInfo;
        let params = {
          ...bankDetails,
          account_number: `${bankDetails?.account_number}`,
          user_id: user_id,
          account_type: bankDetails?.Saving === 'Saving' ? 2 : 1,
          partner_id: dealer_id,
        }
      const bankAcParams = {
          "account_no": bankDetails.account_number,
          "ifsc_code": bankDetails.ifsc_code,
      };
      const bankData = await postApiUtility('central-service/pennyless/validateAccount', bankAcParams);
    //  console.log ("response" , bankData)
      if(bankData?.data?.status === 200) {
        params = {
          ...params,
          is_name_matched: checkBankName(bankDetails?.beneficiary_name, bankData?.data?.data?.response?.data?.details?.registered_name) ? 1 : 2,
        }
        const response = await postApiUtility('partner/partner/save-bank-details', params);
        if(response.status === 200) {
          toast.success('Bank details saved successfully.');
          setIsBankVerified(true);
          setOpenModal(true);
          ShowPopup();
        }
        console.log("Calling saveBank API...", );
      } else {
        toast.error('Failed to verify bank details');
        setOpenModal(true);
        ShowPopup();
        setIsBankVerified(false);
      }
  }

  const checkBankName = (name, bankName) => {
    // console.log("name + bankName checker=====", name, bankName);
    let isNameMatch = false;
    if(name === bankName) {
      isNameMatch = true;
    } else {
      setError((prevError) => ({
        ...prevError, 
        is_name_matched: true,
      }))
    }
    return isNameMatch;
  };


  const ShowPopup = () => {
    setOpenModal(!openModal);
    if (!openModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  return (
    <>
      <div className="verifiedbank">
        <h5>Bank Account</h5>
        <h5>Verification Initiated</h5>
        <div>
          <img src={require("../../../saathi-web/images/pingball.gif")} alt="" />
        </div>
        <div className="gifscreen" onClick={ShowPopup}>
          Keep calm and do not exit this screen
        </div>
      </div>
      <Modal isOpen={openModal} togglePopup={ShowPopup} width={800}>
        <BankVerificationPopup 
         isBankVerified={isBankVerified}
         isNameMissMatch={error?.is_name_matched}
        />
      </Modal>
    </>
  );
};

export default BankVerification;
