import React, { Component } from "react";
import Button from '../../elements/Button';
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import InputField from '../../elements/InputField';

class AddToStock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_classified: 0,
            carId: props.carId,
            currentIndex: props.currentIndex
        }
    }
    saveHandler = () => {
        var thisObj = this;
        var postObj = { stock_used_car_id: this.state.carId, is_classified: this.state.is_classified };

        MasterService.post('inventory/inventory/addtostock', postObj)
            .then(function (response) {
                thisObj.flag = false;
                if (response.data.status == 200) {
                    if (typeof thisObj.props.handleAddToStock === 'function') {
                        thisObj.props.handleAddToStock(thisObj.state.currentIndex);
                    }
                    toast.success(response.data.message);
                    thisObj.props.modalClose();
                }
                else{
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }
    handleOnChange = (event) => {
       // console.log("event", event.target);
        this.setState({ is_classified: event.target.checked ? 1 : 0 });
    }
    render() {
        return (
            <div>
                <p>{this.props.t('listInventory.add_to_stock_text', {stock_type:this.props.stockType})}</p>
                {this.props.stockCategory == 1 && (<div className="row">
                <div className="col-sm-12">
                    <InputField
                        inputProps={{
                            id: "classifiedStock",
                            type: "checkbox",
                            name: "classifiedStock",
                            value: 1,
                            label: this.props.t('addInventory.make_classified'),
                            checked: this.state.is_classified
                        }}
                        onChange={this.handleOnChange}
                    />
                </div> 
                </div>)}
                <div className="col-sm-6 col-md-12 form-group text-right">
                    <Button type="button" onClick={this.saveHandler} colclass="col-sm-12" btnClass="btn btn-primary" name="addtostockbtn" id="addtostockbtn" title={this.props.t('listInventory.ok')} />
                </div>
            </div>
        );
    }
}

export default withTranslation('inventory')(AddToStock);