import React, { useEffect, useState } from "react";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import MyEarningsPopup from "./MyEarningsPopup";
import Modal from "../../view/common/Modal";
import MyEarningsListFilter from "./MyEarningLIstFilter";
import MyEarningListTable from "./MyEarningListTable";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import secureStorage from "../../config/encrypt";
import ActionTypes from "../../store/action/action";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../common/executeGraphQLQuery";
import {
  GET_PAID_INVOICE_DATA,
  GET_PENDING_INVOICE_DATA,
} from "../../queries/payout.gql";
import { useCleverTap } from '../CleverTapContext';

const MyEarningsList = (props) => {
  const [activeTab, setActiveTab] = useState("Projected");
  const [expandedRow, setExpandedRow] = useState(null);
  const [showView, setShowView] = useState(false);
  const [paidInvoiceData, setPaidInvoiceData] = useState([]);
  const [payoutType, setPayoutType] = useState("pending");
  const [payoutTypeKey, setPayoutTypeKey] = useState("paid_status");
  const [partnerID, setPartnerID] = useState("");
  const [filterData, setFilterData] = useState({});
  const trackEvent = useCleverTap();
  
  useEffect(() => {
    trackEvent("page_loaded", "my_earnings");
    updateReduxData();
    const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
    const dealerId = loginUserInfo.user_data.dealer_id;
    setPartnerID(dealerId);
    // getPaidInvoiceData();
  }, []);

  useEffect(() => {
    const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));

    const dealerId = loginUserInfo.user_data.dealer_id;
    setPartnerID(dealerId);
    getPaidInvoiceData(dealerId);
  }, [payoutType, filterData]);

  const updateReduxData = async () => {
    const loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse) {
      props.updateStateIntoRedux(ActionTypes.LOGIN_USER, loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return <Redirect to={"/login"} />;
    }
  };

  const afterLoginSetState = (loginResponse) => {
    const role = loginResponse?.data?.user_data?.role || null;
    if (role !== "dealercentral") {
      return <Redirect to={"/"} />;
    }
  };

  const togglePopup = () => {
    setShowView(!showView);
    if (!showView) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setExpandedRow(null)
    if (tabName === "Projected") {
      setPayoutType("pending");
      setPayoutTypeKey("paid_status");
    } else if (tabName === "Earned") {
      setPayoutType(["invoice_created", "payment_pending"]);
      setPayoutTypeKey("invoice_status");
    } else if (tabName === "Paid") {
      setPayoutType("paid");
      setPayoutTypeKey("invoice_status");
    }
  };

  

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleClickOutside = (event) => {
    const moreFilterDiv = document.querySelector(".more-filter");
    if (!moreFilterDiv?.contains(event.target)) {
      const toggleCheckbox = document.getElementById("toggleCheckbox");
      if (toggleCheckbox) toggleCheckbox.checked = false;
    }
  };

  const getPaidInvoiceData = async (dealerId) => {



    const variables = {
        filter_params: {
            [payoutTypeKey]: payoutType,
            partner_id: dealerId,
            ...filterData

        },
    };

    if (filterData.product_type) {
        variables.filter_params.product_type = filterData.product_type;
    }


    try {
        if (activeTab === "Projected") {
            const data = await executeGraphQLMutation(
                GET_PAID_INVOICE_DATA,
                variables
            );
            setPaidInvoiceData(data?.data?.partner_eligible_leads?.leads);
        } else {
            const data = await executeGraphQLMutation(
                GET_PENDING_INVOICE_DATA,
                variables
            );
            setPaidInvoiceData(data?.data?.invoice_list[0].invoices);
        }
    } catch (error) {
        console.error("Error fetching invoice data:", error);
    }
};


  const handleFilterChange = (newFilterData) => {
    setFilterData(newFilterData);
  };

  return (
    <>
      <div className="LeadContainer" onClick={handleClickOutside}>
        <MyEarningsListFilter onFilterChange={handleFilterChange} />
        <MyEarningListTable
          activeTab={activeTab}
          expandedRow={expandedRow}
          handleTabClick={handleTabClick}
          handleRowClick={handleRowClick}
          paidInvoiceData={paidInvoiceData}
        />
      </div>
      <Modal isOpen={showView} togglePopup={togglePopup} width={800}>
        <MyEarningsPopup paidInvoiceData={paidInvoiceData} paidInvoiceData1 = {paidInvoiceData}/>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth_token: state.authToken,
  user_loggedin: state.userLoggedIn,
  dealer_id: state.dealer_id,
});

const mapDispatchToProps = (dispatch) => ({
  updateStateIntoRedux: (actionType, stateData) =>
    dispatch({ type: actionType, componentState: stateData }),
  });

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(MyEarningsList)
);