import React, { Component } from 'react';
import './webroot/css/main.css';
import InternationalRoute from './routes/route';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CONFIG_FILE_CHANGE } from './config/constant';
import { CleverTapProvider } from './saathi-web/CleverTapContext'; // Import CleverTapProvider

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null, // Store logged-in user data here
    };
  }

  componentDidMount = () => {
    const favicon = document.getElementById('favicon');
    favicon.href = CONFIG_FILE_CHANGE.FAV_ICON;

    // Fetch user data and set to state if available
    const loggedInUser = this.getUserData();
    if (loggedInUser) {
      this.setState({ user: loggedInUser });
    }
  };

  getUserData = () => {    
    const retrievedUser = JSON.parse(localStorage.getItem("loginUserInfo"));
  
    const isDemoEnv = window.location.href.includes("localhost") || window.location.href.includes("stage");
  
    if (retrievedUser && retrievedUser.user_data && !isDemoEnv) {
      return {
        id: retrievedUser.user_data.user_id || null,
        name: retrievedUser.user_data.name || '',
        mobile: retrievedUser.user_data.mobile || '',
      };
    }
  
    return {
      id: "demo_user_id",
      name: "Demo User",
      mobile: "1234567890",
    };
  };
  

  render() {
    const { user } = this.state;

    return (
      <CleverTapProvider user={user}>
        <div className="App">
          <ToastContainer position={toast.POSITION.TOP_RIGHT} pauseOnFocusLoss={false} />
          <InternationalRoute />
        </div>
      </CleverTapProvider>
    );
  }
}

export default App;
