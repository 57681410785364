import React from "react";
import { toast } from "react-toastify";


export  const isFileTypeAllowed = (file, allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf']) => {
  //console.log("ffffffffffffffff", file);
  if (!file) {
    toast.error('No file selected.');
    return false;
  }
    if (!allowedFileTypes.includes(file.type)) {
      toast.error('Invalid file type. Only JPG, PNG, and PDF files are allowed.');
      return false;
    }
    return true;
  };