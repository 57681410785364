import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import { toast } from 'react-toastify';
import { PARTNER_DISBURSAL_LIST } from "../../queries/payout.gql";
import secureStorage from './../../config/encrypt';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import SearchFilter from "./component/SearchFIlter.js";




const PayoutListing = (props) => {
    const [filterData, setFilterData] = useState({ "status": "pending", "source": "web" });
    const [page_no, setPageNo] = useState(1);
    const [partnerListing, setPartnerData] = useState([]);
    const [payoutListing, setPayoutListing] = useState([]);
    const [paginationdata, setPaginations] = useState({});
    const [pageloading, setpageloading] = useState(false)

    const updateReduxData = async () => {
        setpageloading(false)
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            afterLoginSetState(loginResponse)
        } else {
            return (<Redirect to={"/login"} />);
        }
    }

    const afterLoginSetState = (loginResponse) => {
        setpageloading(false)
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        if (role != "dealercentral") {
            return (<Redirect to={"/"} />);
            

        }
    }
  
    const getPartnersDisbursalList = async (status = "pending", current_page = 1) => {
        setpageloading(true)
        const payout_filters = { ...filterData };
        payout_filters.status = !payout_filters.status ? "pending" : payout_filters.status;
        const pageNumber = current_page ? current_page : page_no;
        if (payout_filters.product_type_ids && payout_filters.product_type_ids.length > 0) {
            payout_filters.product_type = payout_filters.product_type_ids[0]
        }
        if (status && status != "") {
            payout_filters.status = status
        }
        if (payout_filters.financier_id && payout_filters.financier_id.length > 0) {
            payout_filters.financersids = payout_filters.financier_id
        }
        delete payout_filters.schemefinancers
        delete payout_filters.product_type_ids
        delete payout_filters.financier_id
        const variables = {
            "filter_params": payout_filters,
            "page_no": pageNumber
        }
        //  console.log("pageNumber",pageNumber)
        try {
            const result = await executeGraphQLMutation(PARTNER_DISBURSAL_LIST, variables, pageNumber);
          //  console.log("result xg dg df gfd gfd gdf ", result)
            if (result.data.partner_disbursals.length == 0) {
                setPartnerData([]);
                setPayoutListing([]);
                setPaginations({});
                setpageloading(false)

            }
            if (result) {
                if (result.data.partner_disbursals && result.data.partner_disbursals.length > 0) {
                    const disbursals = result.data.partner_disbursals[0].disbursals;
                    setPartnerData(disbursals);
                    setPaginations(result.data.partner_disbursals[0].pagination);
                    setpageloading(false)
                }
            } else {
                setPartnerData([]);
                setPaginations({});
                setpageloading(false);
            }
        } catch (error) {
            toast.error("Failed");
            console.error("error dxf df dsf sd fs fds", error);
        }
    }


    useEffect(() => {
       
    }, []);


    let role;
    if (secureStorage.getItem('loginUserInfo')) {
        let loginResponse = secureStorage.getItem('loginUserInfo');
        if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
            role = loginResponse.data.user_data.role;
        }
    }


    

    return (
        <>
            <div className={(pageloading) ? "container-fluid loading" : "container-fluid"}>
                {/* {JSON.stringify(filterData)} */}
                <h1>Payout Listing</h1>
                <SearchFilter/>
               
            </div>
        </>
    )
};

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('dealer')(connect(mapStateToProps, mapDispatchToProps)(PayoutListing));
