import React, { useEffect, useState, useRef } from "react";
import { toast } from 'react-toastify';
import MasterService from "../../service/MasterService";
import { Add_SCHEME, Add_Scheme_Range, GET_SCHEMA_LIST_BYID, Update_SCHEME, GET_SCHEMA_LIST_BY_CODE } from "../../queries/scheme.gql";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { Redirect, useParams } from "react-router-dom/cjs/react-router-dom.min";
import DateFormate from 'dateformat';
import Schemedetails from "./Schemedetails";
import PayoutRanges from "./PayoutRanges";
import secureStorage from './../../config/encrypt';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PartnerListing from "./PartnerListing";

const AddScheme = (props) => {

    const param = useParams()
    // console.log(props.match.params)
    const scheme_code = param.scheme_id_hash ? param.scheme_id_hash : "";
    const currentTab = param.tab ? param.tab : "details";
    const [formData, setFormData] = useState({
        city_id: [],
        scheme_type: "base",
        scheme_name: "",
        start_date: "",
        end_date: "",
        scheme_for: ""
    });

    const [selectedBuilders, setSelectedBuilders] = useState([]);
    const [selectstatus, setselectstatus] = useState("");
    const [hide, sethide] = useState(false);
    const [cityAllList, setcityAllList] = useState([]);
    const [errors, setErrors] = useState("");
    const [activeTab, setActiveTab] = useState(param.tab ? param.tab : "details");
    const [formValues, setFormValues] = useState([
        { "start_range": null, "end_range": null, "payout": null }
    ]);
    const childRef = useRef(null);
    const handleInput = (e, key = "") => {
        const name = e.target ? e.target.name : key;
        const val = e.target ? e.target.value : e.value;
        setFormData({ ...formData, [name]: val })
        let newErrors = { ...errors };
        newErrors[name] = ('');
        setErrors(newErrors);

    }

    const handleInputarray = (i, e) => {
        const name = e.target.name;
        const val = e.target.value;
        let newFormValues = [...formValues];
        newFormValues[i][name] = val;
        setFormValues(newFormValues);
    };

    const addMoreFields = () => {
        setFormValues([...formValues,
        { "start_range": '', "end_range": '', "payout": '' }
        ])
    }

    const removeFieled = (i) => {
        let newformValues = [...formValues]
        newformValues.splice(i, 1)
        setFormValues(newformValues)
    }

    useEffect(() => {
        getCityList()
        if (scheme_code && scheme_code != "") {
            getSchemeListBYCode(scheme_code)
        }
        setActiveTab(currentTab)
        updateReduxData()
       
    }, []);

    const getCityList = () => {
        MasterService.get('core/commonservice/state_city_all')
            .then(function (response) {
                //console.log("hhhhhhhhhhhhhhh",response);
                if (response.data.status == 200) {
                    setcityAllList(response.data.data.city)
                }
            })
            .catch(function (response) {
            });
    }




    const AddSchemeSave = async (e) => {
        e.preventDefault();
        if (param.id) {
            updateScheme();
        } else {
            addScheme();
        }
    }

    const handelCheckbox = (value) => {
        let newFormData = { ...formData };
        newFormData['scheme_type'] = value;
        setFormData(newFormData);
    }

    const handleCheckboxall = (e) => {
        const checked = e.target.checked
        if (checked) {
            sethide(true)
        }
        else {
            sethide(false)
        }
    }

    const addScheme = async () => {
        const formDataNew = { ...formData };
        formDataNew.status = selectstatus?.value;
        const data = {};
        const variables = {
            addSchemeArgs: {
                scheme_type: formData.scheme_type || null,
                scheme_name: formData.scheme_name,
                start_date: formData.start_date,
                end_date: formData.end_date,
                scheme_for: formData.scheme_for ? formData.scheme_for : null,
                status: selectstatus ? parseInt(selectstatus.value) : null,
            },
        };
        data.addSchemeArgs = variables;
        if (validateForm()) {
            try {
                const result = await executeGraphQLMutation(Add_SCHEME, variables);
                if (result) {
                    toast.success(result?.data?.save_role?.message || "Success");
                    setFormData({ ...formData });
                }
            } catch (error) {
                toast.error("Failed");
                console.error(error);
            }
        }
    }

    const updateScheme = async () => {
        const formDataNew = { ...formData };
        formDataNew.status = selectstatus?.value;
        formDataNew.scheme_for = formData?.scheme_for;
        const data = {};
        const variables = {
            updateSchemeArgs: {
                scheme_id: param.id,
                scheme_type: formData.scheme_type || null,
                scheme_name: formData.scheme_name,
                start_date: formData.start_date,
                end_date: formData.end_date,
                scheme_for: formData.scheme_for ? formData.scheme_for : null,
                status: selectstatus ? parseInt(selectstatus.value) : null,
            },
        };
        data.updateSchemeArgs = variables;
        if (validateForm()) {
            try {
                const result = await executeGraphQLMutation(Update_SCHEME, variables);
                if (result) {
                    toast.success(result?.data?.update_scheme?.message || "Success");
                }
            } catch (error) {
                toast.error("Failed");
                console.error(error);
            }
        }
    }

    const submitSchemeRange = async () => {
        const formDataNew = { ...formValues };
        const data = {};
        const variables = {
            schemeBuilderInputArgs: {
                start_range: formValues.start_range,
                end_range: formValues.end_range,
                payout: formValues.payout,
                scheme_id: 1,
                builder_id: 2,
                status: 1,
            },
        };
        data.schemeBuilderInputArgs = variables;
        try {
            const result = await executeGraphQLMutation(Add_Scheme_Range, variables, formDataNew);
            if (result) {
                toast.success(result?.data?.save_range?.message || "Success");
                setFormValues([]);
            }
        } catch (error) {
            toast.error("Failed");
            console.error(error);
        }
    }


    const getSchemeListBYCode = async (scheme_code) => {
        const schemeData = await executeGraphQLQuery(GET_SCHEMA_LIST_BY_CODE(scheme_code));
        if (schemeData && schemeData.data && schemeData.data.schemeByCode) {
            setFormData(schemeData.data.schemeByCode);
        }
    }




    const validateForm = () => {
        let validForm = true;
        let newErrors = { ...errors };
        let newFormData = { ...formData };

        if (!newFormData['scheme_name'] || newFormData['scheme_name'] === '') {
            newErrors['scheme_name'] = ('Please enter scheme name');
            validForm = false;
        }
        if (!newFormData['start_date'] || newFormData['start_date'] === '') {
            newErrors['start_date'] = ('Please enter start date');
            validForm = false;
        }
        if (!newFormData['end_date'] || newFormData['end_date'] === '') {
            newErrors['end_date'] = ('Please enter end date');
            validForm = false;
        }
        setErrors(newErrors);
        return validForm;
    };

    const status = [
        { value: 1, label: "Active" },
        { value: 2, label: "Inactive" }
    ]

    const calculation = [
        { value: "count", label: "Count" },
        { value: "amount", label: "Amount" }
    ]

    const builders = [
        { value: 'hdfc', label: 'HDFC' },
        { value: 'sbi', label: 'SBI' },
        { value: 'icici', label: 'ICICI' },
        { value: 'pnb', label: 'PNB' },
        { value: 'kotak', label: 'KOTAK' },
    ];


    const handleCityOptionChange = (name, selectedOptions) => {
        setFormData({ ...formData, [name]: selectedOptions.map(option => option.id) });
    };

    const handleStatusSelect = (selectedOptions) => {
        setselectstatus(selectedOptions);
    };


    const handleBuilders = (selectedBuilders) => {
        setSelectedBuilders(selectedBuilders)
    }

   const updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
          var loginResponse = secureStorage.getItem('loginUserInfo');
          props.updateStateIntoRedux('LOGIN_USER', loginResponse);
          afterLoginSetState(loginResponse)
        } else {
            return (<Redirect to={"/login"} />);
        }
      }

      const   afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        if (role != "dealercentral") {
            return (<Redirect to={"/"} />);
        }
      }

    const handleTabClick = (tabId) => {
       setActiveTab(tabId);
        let url ="";
        if(scheme_code){
         url = "/scheme/"+scheme_code+"/"+tabId;
        }else{
            url = "/scheme/"+tabId; 
        }
     //   console.log("url",url);
       return (<Redirect to={url} />);
     //   window.location.href = url;
    };

    return (

        <>

            <div className="container-fluid">
                
                <div>
                     {/* {JSON.stringify(formData)} */}
                    <ul className="tabs">
                        
                        <li
                            className={activeTab === 'details' ? 'active' : ''}
                            onClick={() => handleTabClick('details')}
                        >
                            Scheme Details
                        </li>
                        {formData && formData.id>0 ? 
                        <li
                            className={activeTab === 'range' ? 'active' : ''}
                            onClick={() => handleTabClick('range')}
                        >
                            Payout Ranges
                        </li> : ""}
                        {formData && formData.id>0 && formData.is_default=='0' ? 
                        <li
                            className={activeTab === 'partners' ? 'active' : ''}
                            onClick={() => handleTabClick('partners')}
                        > 
                           Partners
                        </li> : ""}
                    </ul>
                    <div className="tab-content">
                        {activeTab === 'details' && <div>
                            <Schemedetails ref={childRef} schemedetailsData={formData} handleTabClick={handleTabClick}></Schemedetails>
                        </div>
                        }
                        {activeTab === 'range' && scheme_code ? <div>
                            <PayoutRanges ref={childRef} schemedetailsData={formData} handleTabClick={handleTabClick}></PayoutRanges>
                        </div>  : ""}
                       
                        {activeTab === 'partners' && <div>
                           <PartnerListing schemedetailsData={formData}></PartnerListing>
                        </div>
                        }
                    </div>
                </div>

            </div>

        </>
    )
};

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('ac_manager')(connect(mapStateToProps, mapDispatchToProps)(AddScheme));
//export default AddScheme;