import React, { useEffect, useState, useRef } from "react";
import MasterService from "../../../service/MasterService";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../../common/executeGraphQLQuery";
import { EXPORT_CSV_PENDING_PAYMENT, FILE_URL_UPLOAD } from "../../../queries/payout.gql";
import { toast } from "react-toastify";
import {
  PAYOUT_LEADS_COUNTS,
  PAYOUT_CALCULATION_CURRENT_MONTH,
  PAYOUT_CALCULATION_LAST_MONTH,
  PAYOUT_LEADS_EXPORT,
} from "../../../queries/payout.gql";
import secureStorage from ".././../../config/encrypt";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  SCHEME_LIST,
  FINEX_MASTER_LIST,
  FINEX_BANK_LIST,
} from "../../../queries/scheme.gql";
import TableContent from "./TableContent.js";
import { InventoryService } from "../../../service";

const moment = require("moment");

const SearchContent = (props) => {
  const filterVariables = props.state;
  const buttonClick = props.buttonClick;
  const filterOptions = props.filterOptions;
  const filter_params = props.filter_params;
  const DataFIlter = props.filterData;

  const fileInputRef = useRef();
  const [url, setUrl] = useState();
  const [filterData, setFilterData] = useState({
    status: "pending",
    source: "web",
  });
  const [pageloading, setpageloading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selected_partner_id, setSelectedPartnerID] = useState(0);
  const [schemeList, setschemeList] = useState([]);
  const [leads_counts, setLeadsCounts] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [productType, setProductType] = useState([]);
  const [financers, setFinancers] = useState([]);
  const [fulfillmenttypes, setFulfillmentTypes] = useState([]);
  const [user_list, setUserList] = useState([]);
  const [financersBanks, setFinancersPersonal] = useState([]);
  const [counter, setCounter] = useState(0);
  const [csvFile, SetCsvFile] = useState();

  const updateReduxData = async () => {
    if (secureStorage.getItem("loginUserInfo")) {
      var loginResponse = secureStorage.getItem("loginUserInfo");
      props.updateStateIntoRedux("LOGIN_USER", loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return <Redirect to={"/login"} />;
    }
  };

  const afterLoginSetState = (loginResponse) => {
    setpageloading(false);
    setSelectedPartnerID(loginResponse.data.user_data.user_id);
    let role =
      (loginResponse &&
        loginResponse.data &&
        loginResponse.data.user_data &&
        loginResponse.data.user_data.role) ||
      null;

    if (role != "dealercentral") {
      return <Redirect to={"/"} />;
    }
  };

  const handleStatusClick = (sname) => {
    const filterDatanew = { ...filterData };

    if (sname == "all") {
      delete filterDatanew.status;
    } else {
      filterDatanew.status = sname;
    }
    setCounter((prevCounter) => prevCounter + 1);

    setFilterData(filterDatanew);

    // getleadsCounts();
    setSelectedRow(null);
  };

  useEffect(() => {
    getDealerList();
    getSchemeList();
    updateReduxData();
    // getleadsCounts();
    getFinexMasterData();
    getFinexPersonalBankData();
  }, []);

  const getSchemeList = async () => {
    const partnerArray = [];
    const schemeData = await executeGraphQLQuery(SCHEME_LIST);
    const data = schemeData.data.getAllSchemsList;
    data &&
      data.map((list) => {
        const partnerData = {
          label: list.id,
          value: list.scheme_name,
        };
        partnerArray.push(partnerData);
      });
    setschemeList(partnerArray);
  };

  // const getleadsCounts = async () => {
  //   const leadscounts = await executeGraphQLQuery(PAYOUT_LEADS_COUNTS);
  //   const data = leadscounts.data.leads_count;
  //   console.log("data", data);
  //   let total = 0;
  //   data &&
  //     data.map((list) => {
  //       console.log("list", list);
  //       total = total + parseInt(list.counts);
  //     });
  //   if (total > 0) {
  //     setTotalLeads(total);
  //   }
  //   setLeadsCounts(data);
  // };

  const getFinexMasterData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.masterdata) {
      const bankData = [];
      const producttypedata = [];
      const fulfillmenttypeData = [];
      const data = masterData.data.masterdata;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      const product_type =
        data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
        });
        setFinancers(bankData);
      }
      if (product_type && product_type.length > 0) {
        product_type.map((ptype) => {
          producttypedata.push({ id: ptype.id, name: ptype.label });
        });
        setProductType(producttypedata);
      }
      const fulfillmenttype =
        data.fulfillment_type && data.fulfillment_type.length > 0
          ? data.fulfillment_type
          : [];
      if (fulfillmenttype && fulfillmenttype.length > 0) {
        fulfillmenttype.map((ptype) => {
          fulfillmenttypeData.push({ id: ptype.id, name: ptype.label });
        });
        setFulfillmentTypes(fulfillmenttypeData);
      }
    }
  };

  const getFinexPersonalBankData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_BANK_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.bank_pa) {
      const bankData = [];
      const data = masterData.data.bank_pa;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      const product_type =
        data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
        });
        setFinancersPersonal(bankData);
      }
    }
  };

  const getDealerList = () => {
    MasterService.get("account/user/user_list")
      .then(function (response) {
        if (response.data.status == 200) {
          if (response.data.data.length) {
            setUserList(response.data.data);
          } else {
            setUserList([]);
          }
        }
      })
      .catch(function (response) {});
  };

  let role;
  if (secureStorage.getItem("loginUserInfo")) {
    let loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
      role = loginResponse.data.user_data.role;
    }
  }

  const handleChange = async (e) => {
    try {
      SetCsvFile(e.target.files[0]);
      let formData = new FormData();

      formData.append("dealer_id", selected_partner_id);
      formData.append("upload_type", "dealer_docs");
      formData.append("images", e.target.files[0]);
      const response = await InventoryService.uploadFilesToS3(formData, {
        "Content-Type": "multipart/form-data",
      });
      if (response.data.status === 200) {
      //  console.log("response.data.data",response.data.data)
        const file_url = response?.data?.data[0]?.file_url;
        sendFileUrl(file_url);
    
      } else if (response.data.status === 400) {
        toast.error("Failed");
      }
    } catch {
      toast.error("Failed");
    }
  };

  const sendFileUrl = (url) => {
    const variables = {
      uploadPaymentArgs: {
        csv_file: url,
        update_for: "payment",
      },
    };
    try {
     executeGraphQLMutation(FILE_URL_UPLOAD, variables).then(result=>{
      toast.success(result?.data?.upload_payment ? result?.data?.upload_payment : "file uploaded succesfully");
   //   console.log("result?.data?.upload_payment",result);
     });
    } catch (error) {
      toast.error("Something went wrong!");
     
    }
  };

  const downloadCsv = () => {
    
    const resultData = executeGraphQLQuery(EXPORT_CSV_PENDING_PAYMENT).then(resultData=>{
      const csvData = resultData?.data?.export_payment_pending_invoice
      if (!csvData) return;
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'payment_pending_data.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

    });
  };

  return (
    <>
      <div className="pad-15">
        <div className="row">
          <div className="col-sm-8">
            <ul className="list-inline nav-tabs">
              <li
                className={
                  filterData.status == "pending"
                    ? "nav-item active"
                    : "nav-item"
                }
                onClick={() => handleStatusClick("pending")}
              >
                <div>{"Eligible"}</div>
              </li>
              <li
                className={
                  filterData.status == "invoice_created"
                    ? "nav-item active"
                    : "nav-item"
                }
                onClick={() => handleStatusClick("invoice_created")}
              >
                <div>{"Approved for Finance"}</div>
              </li>
              <li
                className={
                  filterData.status == "payment_pending"
                    ? "nav-item active"
                    : "nav-item"
                }
                onClick={() => handleStatusClick("payment_pending")}
              >
                <div>{"Payment Pending"}</div>
              </li>

              <li
                className={
                  filterData.status == "paid" ? "nav-item active" : "nav-item"
                }
                onClick={() => handleStatusClick("paid")}
              >
                <div>{"Paid"}</div>
              </li>
            </ul>
          </div>

          {
            filterData.status === "payment_pending" && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                  paddingRight: "100px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <button
                    style={{
                      height: "50px",
                      width: "100px",
                      backgroundColor: "#57B68D",
                      color: "white",
                      border: "1px solid #57B68D",
                      borderRadius: "10px",
                      marginRight: "30px",
                      cursor: "pointer",
                      float: "right",
                    }}
                    onClick={() => downloadCsv()}
                  >
                    Export List
                  </button>
                  <input
                    onChange={handleChange}
                    multiple={false}
                    ref={fileInputRef}
                    type="file"
                    hidden
                    style={{ display: "none" }}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <button
                    style={{
                      height: "50px",
                      width: "100px",
                      backgroundColor: "#57B68D",
                      color: "white",
                      border: "1px solid #57B68D",
                      borderRadius: "10px",
                      marginRight: "30px",
                      cursor: "pointer",
                      float: "right",
                      margin: "0",
                    }}
                    onClick={() => fileInputRef.current.click()}
                  >
                    Upload UTR
                  </button>
                  <input
                    onChange={handleChange}
                    style={{ display: "none" }}
                    multiple={false}
                    ref={fileInputRef}
                    type="file"
                    hidden
                  />
                </div>{" "}
              </div>
            </>
          )}

          {/* )} */}

          {filterData.status === "paid" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
                paddingRight: "100px",
              }}
            >
              <label htmlFor="contained-button-file">
                <button
                  style={{
                    height: "50px",
                    width: "100px",
                    backgroundColor: "#57B68D",
                    color: "white",
                    border: "1px solid #57B68D",
                    borderRadius: "10px",
                    marginRight: "30px",
                    cursor: "pointer",
                    float: "right",
                    margin: "0",
                  }}
                  onClick={() => fileInputRef.current.click()}
                >
                  Upload GST JSON
                </button>
                <input
                  onChange={handleChange}
                  style={{ display: "none" }}
                  multiple={false}
                  ref={fileInputRef}
                  type="file"
                  hidden
                />{" "}
              </label>
            </div>
          )}
        </div>
      </div>
      <TableContent
        filterOptions={filterOptions}
        state={filterData}
        filterVariables={filterVariables}
        handleStatusClick={handleStatusClick}
        counter={counter}
        buttonClick={buttonClick}
        filter_params={filter_params}
        DataFIlter={DataFIlter}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(SearchContent)
);
